import axios from 'axios';
import logger from 'Lib/logger';
import TraceModel from 'Models/TraceModel';
import AircraftModel from 'Models/AircraftModel';


export const searchAircraftTraces = (registationNumber, options, authHeaders) => {
  logger("searchAircraftTraces", registationNumber, options, authHeaders);

  let url = `/traces/aircraft/${registationNumber}/search.json?limit=${options.limit}&offset=${options.offset}`;

  return axios.get(url, authHeaders || {})
    .then(response => {
      console.log('response.data', response.data);
      const { count } = response.data;

      const traces = response.data.traces.map(t => new TraceModel(t));

      return {
        traces,
        count,
      };
    });
}


export const searchAirportTraces = (airportCode, options, authHeaders) => {
  logger("searchAirportTraces", airportCode, options, authHeaders);

  let url = `/traces/airports/${airportCode}/search.json?limit=${options.limit}&offset=${options.offset}`;

  return axios.get(url, authHeaders || {})
    .then(response => {
      logger('searchAirportTraces response.data', response.data);

      const { count, offset } = response.data;

      const traces = response.data.traces.map(t => new TraceModel(t));

      const aircrafts = (response.data.aircrafts || []).map(t => new AircraftModel(t));

      traces.forEach(trace => {
        const aircraft = aircrafts.find(a => a.registrationNumber === trace.registrationNumber);
        trace.aircraft = aircraft;

      });

      return {
        traces,
        count,
        offset
      };
    });
}