import Layout from 'Layout/Layout';
import PostForm from 'Posts/PostForm';

function PostNew() {
  return (
    <Layout>
      <PostForm />
    </Layout>
  );
}

export default PostNew;