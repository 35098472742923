import { useMemo, useState } from 'react';
import { Box, Typography, TextField, MenuItem, LinearProgress } from '@mui/material';
import logger from 'Lib/logger';
import VisualGallery from 'Lib/VisualGallery';
import LoadingButton from '@mui/lab/LoadingButton';
import { createPost } from './PostsApi';
import { useAuth } from 'Auth/AuthProvider';
import { useProfile } from 'Profile/MyProfileProvider';
import ProfileAvatar from 'Profile/ProfileAvatar';
import PostVisualUpload from 'Posts/PostVisualUpload';
import { useSnackbar } from 'Lib/Snackbar';
import { useNavigate } from 'react-router-dom';

import { AUDIENCES, AUDIENCE_LABELS, AUDIENCES_PRIVATE } from 'Models/Audiences';
import { uploadFileToStorage, filterFilesForSize, notifyServer } from 'Lib/uploadHelpers';
import TaggedTextField from 'TaggedText/TaggedTextField';



export default function PostForm() {

  const { authHeaders } = useAuth();
  const { profile, isLoadingProfile } = useProfile();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [caption, setCaption] = useState('');
  const [tags, setTags] = useState([]);
  const [profiles, setProfiles] = useState([]);

  const [validFiles, setValidFiles] = useState([]);
  const [progressArray, setProgressArray] = useState([]);
  const [selectedVisuals, setSelectedVisuals] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [audience, setAudience] = useState(null);
  const [loading, setLoading] = useState(false);


  const handleFilesSelected = (files) => {
    console.log('handleFilesSelected', files);
    setValidFiles(filterFilesForSize(files));
    // Store the files for later upload when creating the post

    setSelectedVisuals(files);
  };

  const memoizedGallery = useMemo(() => (
    selectedVisuals.length === 0 ? (
      <Box variant="rectangular" sx={{ width: '100%', flex: 1, bgcolor: 'whitesmoke', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
        <Typography variant="caption">Upload photos or videos</Typography>
      </Box>
    ) : (
      <VisualGallery
        visuals={selectedVisuals}
        initialIndex={currentIndex}
        sx={{ flex: 1 }}
        goToIndex={true}
      />
    )
  ), [selectedVisuals, currentIndex]);


  const handlePost = async (event) => {
    setLoading(true);

    logger("validFiles", validFiles);

    // Start uploading
    let newSignedIds = await Promise.all(
      validFiles.map((file, index) => uploadFileToStorage(
        file,
        authHeaders,
        index,
        setProgressArray,
        // onError
        () => showSnackbar('Failed to upload media')
      ))
    );

    newSignedIds = newSignedIds.filter(id => id !== null);

    if (newSignedIds.length === 0) {
      setLoading(false);
      return showSnackbar('Error creating post.');
    }

    createPost({
      caption: caption.trim(),
      audience,
      mentions: { tags, profiles },
      data: { visuals: newSignedIds }
    }, authHeaders)
      .then(post => {
        notifyServer(
          `/media/visuals/post/${post.id}/upload`,
          newSignedIds,
          authHeaders,
          // onComplete
          () => {
            showSnackbar('Your post will be live shortly!');
            navigate(`/profile`);
          },
          // onError
          () => {
            showSnackbar('Error completing upload process');
          });

      })

  };

  if (isLoadingProfile) {
    return <LinearProgress />;
  }

  return (

    <Box sx={style}>
      <Box sx={headerStyle}>
        <ProfileAvatar profile={profile} sx={{ width: 32, height: 32 }} />
        <Typography variant="subtitle1">
          {profile?.getFullName()}
        </Typography>
      </Box>

      <TaggedTextField onChange={data => {
        setCaption(data.value);
        setTags(data.tags);
        setProfiles(data.profiles);
      }} />

      {memoizedGallery}


      <PostVisualUpload onFilesSelected={handleFilesSelected} />

      <Box sx={buttonContainerStyle}>
        <TextField
          variant="standard"
          size="small"
          select
          fullWidth
          label="Audience"
          sx={{ flex: 2 }}
          value={audience}
          onChange={(e) => setAudience(e.target.value)}
        >
          {
            AUDIENCES.filter(a => a !== AUDIENCES_PRIVATE).map(a => {
              return (
                <MenuItem key={a} value={a} selected={audience === a}>
                  {AUDIENCE_LABELS[a].label} ({AUDIENCE_LABELS[a].detail})
                </MenuItem>
              )
            })
          }
        </TextField>

        <LoadingButton
          size="small"
          variant="contained"
          onClick={handlePost}
          loading={loading}
          disabled={selectedVisuals.length === 0 || !audience}
          sx={{ flex: 1, height: 30, mt: 2 }}
        >
          Post
        </LoadingButton>
      </Box>
    </Box>
  );
}


const style = {
  margin: '0px auto',
  width: '100%',
  height: '100%',
  maxWidth: 600,
  bgcolor: 'background.paper',
  p: 2,
  display: 'flex',
  flexDirection: 'column',
};


const headerStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: 2,
  mb: 1,
  flex: 0
};

const buttonContainerStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 1,
  mt: 1,
  flex: 0
};
