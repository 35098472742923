import axios from 'axios';
import FlightModel from '../Models/FlightModel';
import logger from 'Lib/logger';
import TraceModel from 'Models/TraceModel';
import VisualModel from 'Models/VisualModel';
import AudioModel from 'Models/AudioModel';
import AirportModel from 'Models/AirportModel';
import AircraftModel from 'Models/AircraftModel';
import BaseModel from 'Lib/BaseModel';

export const getUserFlights = ({ userId = null, limit = 5, offset = 0 }, authHeaders) => {
  logger('getUserFlights', userId);
  let url = userId ? `/flights/users/${userId}.json` : '/flights.json';
  url += `?limit=${limit}&offset=${offset}`;

  logger('getFlights', url);

  return axios.get(url, authHeaders)
    .then(response => {
      const { data } = response;
      const flights = data.flights.map(f => (
        new FlightModel(f)
      ));

      logger('getFlights', flights);

      return { flights, count: data.count };
    });
}


export const getUserPairFlights = ({ limit = 5, offset = 0 }, authHeaders) => {
  logger('getUserPairFlights');
  let url = `/flights/user_pairs.json`;
  url += `?limit=${limit}&offset=${offset}`;

  logger('getFlights', url);

  return axios.get(url, authHeaders)
    .then(response => {
      const { data } = response;
      const flights = data.flights.map(f => (
        new FlightModel(f)
      ));

      logger('getFlights', flights);

      return { flights, count: data.count };
    });
}




export const getFlight = (id, authHeaders) => {
  logger("getFlight", id);

  let url = `/flights/${id}.json`;

  return axios.get(url, authHeaders || {})
    .then(response => {
      const flight = new FlightModel(response.data.flight);
      const airports = response.data.airports.map(a => new AirportModel(a));
      const aircraft = response.data.aircraft ? new AircraftModel(response.data.aircraft) : null;

      flight.airports = airports;
      flight.aircraft = aircraft;
      logger('getFlight', flight);

      return flight;
    });
}


export const getVisualsByFlight = (flightId, authHeaders) => {
  logger("getVisualsByFlight", flightId);

  let url = `/flights/${flightId}/visuals.json`;

  return axios.get(url, authHeaders || {})
    .then(response => {
      const visuals = response.data.visuals.map(t => new VisualModel(t));
      logger('visuals', visuals);
      return visuals;
    });
}


export const deleteVisual = (flightId, visualId, authHeaders) => {
  logger("deleteVisual", visualId);
  return axios.delete(`/flights/${flightId}/visuals/${visualId}`, authHeaders);
}


export const getAudiosByFlight = (flightId, authHeaders) => {
  logger("getAudiosByFlight", flightId);

  let url = `/flights/${flightId}/audios.json`;

  return axios.get(url, authHeaders || {})
    .then(response => {
      const audios = response.data.audios.map(t => new AudioModel(t));
      logger('audios', audios);
      return audios;
    });
}


export const updateAudioMetadata = (flightId, audioId, newMetadata, authHeaders) => {
  logger("updateAudioMetadata", flightId, audioId, newMetadata);
  return axios.post(
    `/flights/${flightId}/audios/${audioId}/update_metadata.json`,
    { metadata: BaseModel.asJSON(newMetadata) } ,
    authHeaders
  )
  .then(response => {
    const audio = new AudioModel(response.data.audio);
    logger('audio', audio);
    return audio;
  }) ;
}


export const deleteAudio = (flightId, audioId, authHeaders) => {
  logger("deleteAudio", audioId);
  return axios.delete(`/flights/${flightId}/audios/${audioId}`, authHeaders);
}



export const getTraceByFlight = (flightId, authHeaders) => {
  logger("getTraceByFlight", flightId);

  let url = `/traces/${flightId}/find_by_flight.json`;

  return axios.get(url, authHeaders || {})
    .then(response => {
      console.log('response.data', response.data);

      const traces = response.data.traces.map(t => new TraceModel(t));
      logger('traces', traces);
      return traces;
    });
}


export const getFlightByTraceUid = (traceUid, authHeaders) => {
  logger("getFlightByTraceUid", traceUid);

  let url = `/flights/${traceUid}.json`;

  return axios.get(url, authHeaders || {})
    .then(response => response.data)
    .then(data => {
      const flight = new FlightModel(data.flight);
      logger('flight', flight);
      return flight;
    });
}

export const createOrUpdateFlightAndLogbook = (data, authHeaders) => {
  if(!data.flight || !data.logbookEntry) {
    throw new Error('Invalid data. Both flight and logbookEntry are required');
  }

  logger("createOrUpdateFlightAndLogbook", data);

  let url = `/flights/log.json`;

  return axios.post(
    url, {
      flight: new FlightModel(data.flight).asJSON(),
      logbook_entry: new FlightModel(data.logbookEntry).asJSON()
    },
    authHeaders
  )
    .then(response => response.data)
    .then(data => {
      const flight = new FlightModel(data.flight);
      logger('flight', flight);
      return flight;
    });
}




export const updateFlightMetadata = (flightModel, authHeaders) => {
  logger("updateFlightMetadata", flightModel);
  return axios.post(
    `/flights/${flightModel.id}.json`,
    flightModel.asJSON(),
    authHeaders
  )
   .then(response => response.data.flight);
}


export const deleteFlight = (flightId, authHeaders) => {
  return axios.delete(`/flights/${flightId}`, authHeaders)

}


export const getFlightMapImageData = (flightId) => {
  return axios.get(`/flights/${flightId}/map_image_data.json`)
    .then(response => {
      logger('getFlightMapImageData', response.data);

      const flight = new FlightModel(response.data.flight);
      const airports = response.data.airports.map(a => new AirportModel(a));


      flight.airports = airports;

      logger('getFlightMapImageData flight', flight);
      return flight;
    });
}
