import { useEffect, useRef, useState, useCallback } from "react";
import AirplaneIcon from "@mui/icons-material/AirplanemodeActive";
import * as d3 from "d3";



export default function Hsi({ heading = 360, style }) {
  const svgRef = useRef(null);
  const [width, setWidth] = useState(0);

  const correctedDirectionDegrees = 360 - heading;


  useEffect(() => {
    draw();
    rotate(correctedDirectionDegrees);
    setWidth(svgRef.current.clientWidth);
  }, [svgRef.current]);



  useEffect(() => {
    // Only draw once when the component mounts
    if (!svgRef.current.querySelector('line')) {
      draw();
    }
    rotate(correctedDirectionDegrees);
    window.rotate = rotate;
  }, [heading]);

  const radius = 90;
  const textInsetPx = 25;


  const rX = (d, progress) => {
    const rotatedRadians = (d.degrees - 90 + progress) * (Math.PI / 180);
    return (radius - textInsetPx) * Math.cos(rotatedRadians);
  }

  const rY = (d, progress) => {
    const rotatedRadians = (d.degrees - 90 + progress) * (Math.PI / 180);
    return (radius - textInsetPx) * Math.sin(rotatedRadians);
  }

  const rotate = (degrees) => {  // Add degrees parameter
    const gRotate = d3.select(svgRef.current).select("g.rotate");
    const gNoRotate = d3.select(svgRef.current).select("g.no-rotate");

    const currentRotation = parseFloat(gRotate.style("transform").match(/-?\d+/)?.[0] || 0);

    gRotate
      .transition()
      .style("transform", `rotate(${degrees}deg)`);

    gNoRotate.selectAll(".label")
      .transition()
      .duration(300)
      .ease(d3.easeQuadInOut)
      .attrTween("x", function (d) {
        return t => rX(d, d3.interpolate(currentRotation, degrees)(t));
      })
      .attrTween("y", function (d) {
        return t => rY(d, d3.interpolate(currentRotation, degrees)(t));
      });

  }

  const draw = () => {

    const svg = d3.select(svgRef.current);
    const gRotate = svg.select("g.rotate");
    const gNoRotate = svg.select("g.no-rotate");

    // Draw cardinal and degree markings
    const cardinalDirections = ["N", "E", "S", "W"];
    const cardinalOffset = [0, 90, 180, 270];

    const linePropsLookup = {
      isCardinal: { length: 10, strokeWidth: 3, opacity: 1 },
      is30: { length: 7, strokeWidth: 1, opacity: 1 },
      is10: { length: 7, strokeWidth: 1, opacity: 0.5 },
      is5: { length: 2, strokeWidth: 0.75, opacity: 0.5 },
    };


    let angles = Array.from({ length: 72 }, (_, i) => i * 5).map(i => {
      const isCardinal = cardinalOffset.includes(i);

      let lineProps;
      if (isCardinal) {
        lineProps = linePropsLookup.isCardinal;
      } else if (i % 30 === 0) {
        lineProps = linePropsLookup.is30;
      } else if (i % 10 === 0) {
        lineProps = linePropsLookup.is10;
      } else {
        lineProps = linePropsLookup.is5;
      }

      return {
        degrees: i,
        radians: (i - 90) * (Math.PI / 180),
        label: (
          isCardinal ?
            cardinalDirections[cardinalOffset.indexOf(i)]
            :
            (i / 10).toString()
        ),
        lineStart: radius + 5 - lineProps.length,
        lineEnd: radius + 10,
        lineProps,
        isCardinal,
      };
    });

    const lines = gRotate.selectAll("line.tick")
      .data(angles, d => d.degrees);

    lines.enter()
      .append("line")
      .attr("class", "tick")
      .merge(lines)
      .attr("x1", d => d.lineStart * Math.cos(d.radians))
      .attr("y1", d => d.lineStart * Math.sin(d.radians))
      .attr("x2", d => d.lineEnd * Math.cos(d.radians))
      .attr("y2", d => d.lineEnd * Math.sin(d.radians))
      .attr("stroke", "white")
      .attr("stroke-width", d => d.lineProps.strokeWidth)
      .attr("stroke-opacity", d => d.lineProps.opacity);




    const labels = gNoRotate.selectAll("text.label")
      .data(angles.filter(d => d.isCardinal || d.degrees % 30 === 0), d => d.degrees);

    labels.enter()
      .append("text")
      .attr("class", "label")
      .merge(labels)
      .attr("x", rX)
      .attr("y", rY)
      .attr("text-anchor", "middle")
      .attr("alignment-baseline", "middle")
      .attr("font-size", 20)
      .attr("font-weight", d => d.isCardinal ? "bold" : "normal")
      .attr("fill", "white")
      .text(d => d.label === '0' ? '360' : d.label);

  }


  return (
    <div style={{
      position: "relative",
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}
    >
      <span
        style={{
          backgroundColor: 'rgba(0,0,0,0.25)',
          color: 'white',
          padding: `5px 8px`,
          borderRadius: 5,
          fontSize: 18,
          fontWeight: 'bold', ...(style || {})
          }}
        >
        { heading == 0 ? '360' : heading.toString().padStart(3, '0') }°
      </span>
      <div style={{ position: 'relative', paddingTop: width / 10 }}>
        <svg ref={svgRef} viewBox={`0 0 200 200`} width="100%" height="100%">
          <g className="root" transform={`translate(100, 100)`}>
            <g className="rotate">
              <circle
                cx={0}
                cy={0}
                r={100}
                fill="black"
                fillOpacity={0.25}
              />
            </g>
            <g className="no-rotate" />
          </g>
        </svg>
        <AirplaneIcon
          className="airplane"
          color="white"
          style={{
            color: "white",
            position: "absolute",
            top: "50%",
            left: "50%",
            fontSize: width / 5,
            transform: `translate(-50%, -50%)`
          }}
        />
      </div>
    </div>
  );


}

