import { Box, TextField, Button, Typography, Divider } from "@mui/material";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { useSnackbar } from "Lib/Snackbar";
import { useState } from "react";
import AirportsAutocomplete from "./AirportsAutocomplete";
import dayjs from "dayjs";
import logger from "Lib/logger";


export const requiredFields = [
  { field: 'departureDate', message: 'Please enter the departure date.' },
  { field: 'registrationNumber', message: 'Please enter a tail number.' },
  { field: 'departureAndArrivalAirports', message: 'Please enter airports.' },
  { field: 'totalHours', message: 'Please enter total hours' },
];

export default function FlightForm(props) {
  const { showSnackbar } = useSnackbar();

  // Forms start with an Model, but transform it to a simple Object
  const [flight, setFlight] = useState(props.flight || {});
  const [logbookEntry, setLogbookEntry] = useState(props.flight?.logbookEntry || {});

  const onSubmit = (e) => {
    e.preventDefault();

    for (var i = 0; i < requiredFields.length; i++) {
      console.log('flight', flight);
      console.log('logbookEntry', logbookEntry);
      const combined = { ...flight, ...logbookEntry };
      const r = requiredFields[i];
      if (!combined[r.field]) {
        showSnackbar(r.message, { severity: 'error' });
        return;
      }

      console.log('combined.departureAndArrivalAirports', combined.departureAndArrivalAirports);

      if(combined.departureAndArrivalAirports.length < 2) {
        showSnackbar('Please enter airports.', { severity: 'error' });
        return;
      }
    }


    logger("onSubmit", { flight, logbookEntry });
    props.onSubmit({ flight, logbookEntry })
  };



  const updateLogbookEntry = (key, eventOrValue) => {
    const value = eventOrValue.target ? eventOrValue.target.value : eventOrValue;
    setLogbookEntry({ ...logbookEntry, [key]: value instanceof String ? value.trim() : value });
  }


  return (
    <form onSubmit={onSubmit}>

      <Section sx={{ mt: 2, mb: 2 }}>
        <TextField
          sx={{ mt: 1, mb: 1 }}
          value={flight.departureDate || ""}
          onChange={e => {
            const date = e.target.value;
            setFlight({ ...flight, departureDate: date });
          }}
          label="Date*"
          fullWidth
          size="small"
          inputProps={{ type: 'date' }}
          InputLabelProps={{ shrink: true }}
        />

        <TextField
          value={flight.departureTime || ''}
          onChange={e => {
            const time = e.target.value;
            setFlight({ ...flight, departureTime: time });
          }}
          label="Time of Departure (Local)"
          fullWidth
          sx={{ mt: 2 }}
          size="small"
          inputProps={{ type: 'time' }}
          InputLabelProps={{ shrink: true }}
        />
      </Section>


      <Section shaded label="Aircraft">
        <TextField
          label="Tail Number*"
          size="small"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          value={flight.registrationNumber || ""}
          onChange={e => setFlight({ ...flight, registrationNumber: e.target.value.trim().toUpperCase() })}
          sx={{ mb: 1 }}
          fullWidth
          InputLabelProps={{ shrink: true }}
        />

        <TextField
          size="small"
          label="Make and Model"
          value={logbookEntry.aircraftMakeAndModel || ""}
          onChange={e => updateLogbookEntry('aircraftMakeAndModel', e)}
          fullWidth
          sx={{ mb: 1 }}
          InputLabelProps={{ shrink: true }}
        />
      </Section>


      <Section label="Airports*">
        <AirportsAutocomplete
          value={flight.departureAndArrivalAirports || []}
          onChange={e => {
            console.log(e);
            setFlight({ ...flight, departureAndArrivalAirports: e })
          }}
        />

      </Section>


      <Section shaded label="Aircraft Category and Classification">
        <HoursInput
          label="Single Engine Land Hours"
          value={logbookEntry.airplaneSingleEngineLandHours || ""}
          onChange={e => updateLogbookEntry('airplaneSingleEngineLandHours', e)}
        />
        <HoursInput
          label="Multi Engine Land Hours"
          value={logbookEntry.airplaneMultiEngineLandHours || ""}
          onChange={e => updateLogbookEntry('airplaneMultiEngineLandHours', e)}
        />
      </Section>


      <Section label="Type of Piloting Time">
        <HoursInput
          label="Dual Received"
          value={logbookEntry.dualReceivedHours || ""}
          onChange={e => updateLogbookEntry('dualReceivedHours', e)}
        />
        <HoursInput
          label="Pilot in Command"
          value={logbookEntry.pilotInCommandHours || ""}
          onChange={e => updateLogbookEntry('pilotInCommandHours', e)}
        />
        <HoursInput
          label="Second in Command"
          value={logbookEntry.secondInCommandHours || ""}
          onChange={e => updateLogbookEntry('secondInCommandHours', e)}
        />
        <HoursInput
          label="As Flight Instructor"
          value={logbookEntry.flightInstructorHours || ""}
          onChange={e => updateLogbookEntry('flightInstructorHours', e)}
        />
        <HoursInput
          label="Ground Trainer"
          value={logbookEntry.groundTrainerHours || ""}
          onChange={e => updateLogbookEntry('groundTrainerHours', e)}
        />
      </Section>


      <Section shaded label="Conditions of Flight">
        <Box sx={{ display: 'flex' }}>
          <HoursInput
            label="Day"
            sx={{ mr: 1 }}
            value={logbookEntry.dayHours || ""}
            onChange={e => updateLogbookEntry('dayHours', e)}
          />
          <HoursInput
            label="Night"
            sx={{ ml: 1 }}
            value={logbookEntry.nightHours || ""}
            onChange={e => updateLogbookEntry('nightHours', e)}
          />
        </Box>
        <HoursInput
          label="Cross Country"
          value={logbookEntry.crossCountryHours || ""}
          onChange={e => updateLogbookEntry('crossCountryHours', e)}
        />
        <HoursInput
          label="Actual Instrument"
          value={logbookEntry.actualInstrumentHours || ""}
          onChange={e => updateLogbookEntry('actualInstrumentHours', e)}
        />
        <HoursInput
          label="Simulated Instrument"
          value={logbookEntry.simulatedInstrumentHours || ""}
          onChange={e => updateLogbookEntry('simulatedInstrumentHours', e)}
        />
      </Section>


      <Section label="Approaches and Landings">
        <CountInput
          label="Instrument Approaches"
          value={logbookEntry.instrumentApproachesCount || ""}
          onChange={e => updateLogbookEntry('instrumentApproachesCount', e)}
        />

        <Box sx={{ display: 'flex' }}>
          <CountInput
            label="Day Landings"
            sx={{ mr: 1 }}
            value={logbookEntry.dayLandingsCount || ""}
            onChange={e => updateLogbookEntry('dayLandingsCount', e)}
          />
          <CountInput
            label="Night Landings"
            sx={{ ml: 1 }}
            value={logbookEntry.nightLandingsCount || ""}
            onChange={e => updateLogbookEntry('nightLandingsCount', e)}
          />
        </Box>
      </Section>


      <Section shaded>
        <HoursInput
          sx={{ mb: 3 }}
          label="Total Duration of Flight*"
          value={logbookEntry.totalHours || ""}
          onChange={e => updateLogbookEntry('totalHours', e)}
        />
      </Section>


      <Section>
        <TextField
          multiline
          label="Remarks"
          placeholder="Procedures, Maneuvers, Endorsements"
          value={logbookEntry.remarks || ""}
          onChange={e => updateLogbookEntry('remarks', e)}
          sx={{ mt: 1, mb: 1, ...(props.sx || {}) }}
          fullWidth
          size="small"
          variant="outlined"
          autoComplete="off"
          InputLabelProps={{ shrink: true }}
          minRows={2}
        />
      </Section>


      <Section>
        <Button sx={{ mb: 1, boxShadow: 'none' }} onClick={onSubmit} variant="contained" color="primary" fullWidth>
          Save
        </Button>

        <Button onClick={props.onCancel} variant="outlined" color="primary" fullWidth>
          Cancel
        </Button>
      </Section>

    </form>
  );
}


export function Section(props) {
  return (
    <Box>
      <Box
        sx={{
          p: 1,
          // backgroundColor: props.shaded ? 'whitesmoke' : 'inherit',
          borderBottom: props.shaded ? '1px solid whitesmoke' : 'none',
          borderTop: props.shaded ? '1px solid whitesmoke' : 'none',
          ...(props.boxSx || {})
        }}
      >
        {
          props.label &&
          <SectionLabel sx={{ ...(props.labelSx || {}) }}>
            {props.label}
          </SectionLabel>
        }

        {props.children}
      </Box>
    </Box>
  )


}

export function SectionLabel(props) {
  return (
    <Typography
      variant="caption"
      color="GrayText"
      component="div"
      sx={{ m: 1, fontSize: '0.6rem', ...(props.sx || {}) }}
    >
      {props.children}
    </Typography>
  )
}


export function HoursInput(props) {
  return (
    <TextField
      sx={{ mt: 1, mb: 1, ...(props.sx || {}) }}
      fullWidth
      label={props.label}
      size="small"
      variant="outlined"
      autoComplete="off"
      value={props.value}
      onChange={props.onChange}
      InputLabelProps={{ shrink: true }}
      inputProps={{
        type: "number",
        step: 0.1,
        min: 0,
        inputMode: 'decimal',
        autoCorrect: 'off',
      }}
    />
  );
}


export function CountInput(props) {
  return (
    <TextField
      sx={{ mt: 1, mb: 1, ...(props.sx || {}) }}
      fullWidth
      label={props.label}
      size="small"
      variant="outlined"
      value={props.value}
      onChange={props.onChange}
      InputLabelProps={{ shrink: true }}
      inputProps={{
        type: "number",
        step: 1,
        min: 0,
        inputMode: 'numeric'
      }}
    />
  );

}