import { useState } from 'react';
import { IconButton, Typography, Box } from '@mui/material';
import { Send, Close } from '@mui/icons-material';
import { useAuth } from 'Auth/AuthProvider';
import { createOrUpdateComment } from './CommentsApi';
import { useComments } from './CommentProvider';
import TaggedTextField from 'TaggedText/TaggedTextField';
import logger from 'Lib/logger';
const MAX_COMMENT_LENGTH = 300;


export default function CommentForm({ replyingToComment, setReplyingToComment }) {
  const { authHeaders } = useAuth();
  const { commentableTypeAndId, executeUpdateCommentsCallback } = useComments();
  const [content, setContent] = useState('');
  const [tags, setTags] = useState([]);
  const [profiles, setProfiles] = useState([]);

  const handleSubmit = (event) => {
    event.preventDefault();
    createOrUpdateComment({
      commentableType: commentableTypeAndId.type,
      commentableId: commentableTypeAndId.id,
      content,
      mentions: { profiles, tags }
    }, authHeaders)
      .then(data => {
        logger('CommentForm handleSubmit callback', data);
        executeUpdateCommentsCallback(data);
        setContent('');
        setTags([]);
        setProfiles([]);
        setReplyingToComment(null);
      });
  }

  return (
    <Box sx={{ width: '100%' }} component="form" onSubmit={handleSubmit}>
      {
        replyingToComment && (
          <Box display="flex" flexDirection="row">
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ p: 0.5, display: 'block', flex: 1 }}
            >
              Replying to {replyingToComment.profile?.getFullName()}
            </Typography>
            <IconButton size="small" onClick={() => setReplyingToComment(null)}>
              <Close />
            </IconButton>
          </Box>
        )
      }

      <Box display="flex" alignItems="center">
        <TaggedTextField
          value={content}
          onChange={
            data => {
              setContent(data.value);
              setTags(data.tags);
              setProfiles(data.profiles);
            }
          }
          forceSuggestionsAboveCursor={true}
          placeholder="Add a comment"
          maxLength={MAX_COMMENT_LENGTH}
          style={{ flex: 1 }}
        />
        <IconButton
          color="primary"
          size="small"
          type="submit"
          disabled={content.length === 0}
          onClick={handleSubmit}
          flex={0}
          sx={{ alignSelf: 'flex-end' }}
        >
          <Send />
        </IconButton>
      </Box>

    </Box>

  );
}
