import { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { BorderColor, Delete, Edit, Share } from '@mui/icons-material';
import { useAuth } from "Auth/AuthProvider";
import axios from 'axios';
import logger from 'Lib/logger';
import { useSnackbar } from 'Lib/Snackbar';
import { useFlight } from 'Flights/FlightProvider';
import { deleteVisual } from 'Flights/FlightsApi';
import FlightVisualThumbnails from './FlightVisualThumbnails';

export default function FlightVisuals(props) {

  const { visuals, fetchVisuals } = useFlight();
  const [isEditing, setIsEditing] = useState(false);
  const [selections, setSelections] = useState([]);

  const { authHeaders } = useAuth();
  const { showSnackbar } = useSnackbar();

  const handleVisualSelect = ({ visual, index }) => {
    console.log('handleVisualSelect', { visual, index });
    if (!isEditing) {
      return props.onClick({ index });
    }

    console.log('selections', selections);

    if (selections.includes(visual)) {
      setSelections(selections.filter(v => v !== visual));
    } else {
      setSelections([...selections, visual]);
    }
  }

  const handleDelete = () => {
    const items = selections.length === 1 ? 'this item' : `these ${selections.length} items`;
    if (!window.confirm(`Are you sure you want to delete ${items}?`)) return;

    axios.all(selections.map(s => deleteVisual(props.flightId, s.id, authHeaders)))
      .then(responses => {
        logger('delete media responses', responses);

        const successes = responses.filter(r => r.status === 200);

        if (successes.length === selections.length) {
          showSnackbar('Media deleted');
          fetchVisuals();
          setSelections([]);
        }
        else {
          showSnackbar('Could not delete media.', { severity: 'error' });
        }
      })
      .catch(error => {
        logger('Error deleting media:', error);
        console.error('Error deleting media:', error);
        showSnackbar('Could not delete media.', { severity: 'error' });
      });
  }

  return (
    <Box className="FlightVisuals" sx={{ p: 0.5 }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="body2" sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
          Photos
          {props.visibilityTip}
        </Typography>
        <div style={{ flex: 1, textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}>

          {
            visuals.length > 0 && props.isCurrentUser &&
            <>
              {
                isEditing &&
                <>
                  <Button
                    disabled={selections.length === 0}
                    variant="text"
                    size="small"
                    onClick={handleDelete}
                    color="secondary"
                  >
                    <Delete />
                  </Button>
                </>
              }

              <Button
                size="small"
                color="secondary"
                onClick={() => {
                  setIsEditing(!isEditing);
                  if (isEditing) {
                    setSelections([]);
                  }
                }}
              >
                {isEditing ? 'Done' : 'Edit'}
              </Button>

              {!isEditing && props.toolbarButtons}
            </>
          }
        </div>
      </div>

      <FlightVisualThumbnails
        visuals={visuals}
        onClick={handleVisualSelect}
        selections={selections}
      />
    </Box>

  );
}
