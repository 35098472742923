import React from "react";
import { Box, Typography } from "@mui/material";

const StepBlock = ({ stepNumber, title, subtitle, active }) => (
    <Box sx={{ display: "flex", alignItems: "left" }}>
        <Box
            sx={{
                width: active ? "64px !important" : "50px !important",
                height: active ? "64px !important" : "50px !important",
                border: active ? "7px solid #50B1FF !important" : "0",
                borderRadius: active ? "14px" : "7px",
                backgroundColor: active ? "#0061E6" : "#2D5B7E",
                marginLeft: active ? "0" : "7px",
                color: "#FFFFFF",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "21px",
                fontWeight: "bold",
                mr: 2,
            }}
        >
            {stepNumber}
        </Box>

        <Box sx={{ textAlign: "left", paddingTop: active ? "5px" : "0" }}>
            <Typography variant="h6" sx={{ fontWeight: "bold", color: active ? "#FFFFFF" : "#B0BEC5" }}>
                {title}
            </Typography>
            <Typography variant="body2" sx={{ color: active ? "#E3F2FD" : "#B0BEC5" }}>
                {subtitle}
            </Typography>
        </Box>
    </Box>
);

const StepsSidebar = ({ activeStep }) => {
    const steps = [
        { stepNumber: "01", title: "The Pilot", subtitle: "Ident and say destination" },
        { stepNumber: "02", title: "The Plane", subtitle: "Say type aircraft" },
    ];

    return (
        <Box
            sx={{
                display: { xs: "none", md: "flex" },
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                backgroundColor: "#002E51",
                color: "white",
                padding: 3,
                width: "300px",
            }}
        >
            <Box>
                <Box sx={{ display: "flex", alignItems: "left", marginBottom: 10 }}>
                    <img
                        src="/auth/wing_jockey.png"
                        alt="WingJockey Logo"
                        style={{ width: "70px", height: "64px", marginRight: "12px" }}
                    />
                    <Typography
                        variant="h6"
                        sx={{
                            color: "#FAFAFA",
                            fontWeight: "500",
                            fontSize: "1.8rem",
                            marginTop: 1,
                        }}
                    >
                        WingJockey
                    </Typography>
                </Box>

                {steps.map((step, index) => (
                    <React.Fragment key={step.stepNumber}>
                        <StepBlock
                            stepNumber={step.stepNumber}
                            title={step.title}
                            subtitle={step.subtitle}
                            active={index + 1 === activeStep}
                        />
                        {index < steps.length - 1 && (
                            <Box
                                sx={{
                                    height: "45px",
                                    width: "60px",
                                    borderLeft: "1px solid #FFFFFF",
                                    marginLeft: "32px",
                                }}
                            ></Box>
                        )}
                    </React.Fragment>
                ))}
            </Box>
        </Box>
    );
};

export default StepsSidebar;
