import axios from 'axios';
import logger from 'Lib/logger';
import AircraftModel from 'Models/AircraftModel';
import AirportModel from 'Models/AirportModel';
import ProfileModel from 'Models/ProfileModel';
import TagModel from 'Models/TagModel';
import PostModel from 'Models/PostModel';

export const searchRegistrations = (q, authHeaders) => {
  return axios.get(`/aircrafts/search.json?q=${q}`, authHeaders)
    .then(response => {
      logger(response.data);
      return response.data.aircrafts.map(r => new AircraftModel(r));
    });
};

export const searchAirports = (q, authHeaders) => {
  return axios.get(`/airports/search.json?q=${q}`, authHeaders)
    .then(response => {
      logger(response.data);
      return response.data.airports.map(a => new AirportModel(a));
    });
};


export const searchProfiles = (q, authHeaders) => {
  return axios.get(`/profiles/search.json?q=${q}`, authHeaders)
    .then(response => {
      logger(response.data);
      return response.data.profiles.map(a => new ProfileModel(a));
    });
};


export const searchTags = ({ q, has_count = false }, authHeaders) => {
  let url = `/tags/search.json?q=${q}`;
  if (has_count) url += `&has_count=true`;

  return axios.get(url, authHeaders)
  .then(response => {
    logger(response.data);
    return response.data.tags.map(t => new TagModel(t));
  });
};
