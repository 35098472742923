import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export default function ProfileName({ profile, sx = {}, link = true }) {

  return (
    <Box display="flex" flexDirection="column">
      <Typography component={Link}
        to={`/profiles/${profile?.userId}`}
        variant="subtitle2"
        sx={{ fontWeight: 'bold', lineHeight: 'inherit', mt: 0, display: 'block', color: 'black' }}
      >
        {profile?.getFullName()}
      </Typography>
      <Typography variant="caption" color="text.secondary">
        {profile?.pilotType} / {profile?.homeAirportId}
      </Typography>
    </Box>
  )

}