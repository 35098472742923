import axios from 'axios';
import NotificationModel from 'Models/NotificationModel';
import logger from 'Lib/logger';

export const getNotifications = ({limit = 5, offset = 0}, authHeaders) => {

  let url = `/notifications.json?limit=${limit}&offset=${offset}`;

  return axios.get(url, authHeaders)
    .then(response => {
      const { data } = response;

      const notifications = data.notifications.map(n => new NotificationModel(n));
      logger("getNotifications response.data", response.data);

      return { notifications, count: data.count, unreadCount: data.unread_count };
    });
}


export const markAsRead = (notificationIds, authHeaders) => {
  return axios.post(`/notifications/mark_as_read.json`, { notification_ids: notificationIds }, authHeaders)
    .then(response => {
      console.log("markAsRead response.data", response.data);
    });
}