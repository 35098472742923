import BaseModel from "Lib/BaseModel";

export default class AircraftModel extends BaseModel {

  constructor(props) {
    super(props);
    this.registrationNumber = 'N' + this.registrationNumber.toUpperCase();
    this.optionType = 'Aircraft';
  }

  getOptionLabel() {
    return this.registrationNumber + ' - ' + this.manufacturer + ' ' + this.model;
  }

  getManufacturer() {
    // if(this.manufacturer.toLowerCase().includes('textron') && this.model.toLowerCase().includes('172')) {
    //   return 'Cessna';
    // }

    // if(this.manufacturer.toLowerCase().includes('piper')) {
    //   return 'Piper';
    // }

    // if(this.manufacturer.toLowerCase().includes('boeing')) {
    //   return 'Boeing';
    // }

    const manufacturer = this.manufacturer.split(' ')[0];

    if(manufacturer.toUpperCase() === 'TEXTRON') {
      return 'CESSNA';
    }

    return manufacturer;
  }


  getDisplayName() {
    return this.getManufacturer() + ' ' + this.model;
  }


}
