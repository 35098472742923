
import BaseModel from "Lib/BaseModel";

export default class TagModel extends BaseModel {

  constructor(data) {
    super(data);

    this.optionType = 'Tags';
  }

  getOptionLabel() {
    return '#' + this.name;
  }



  getMentionData(){
    return {
      ...this,
      display: '#' + this.name,
    }
  }

}


