import React, { useState, useEffect } from "react";
import { MentionsInput, Mention } from "react-mentions";
import { styled } from "@mui/material";
import { useAuth } from "Auth/AuthProvider";
import { searchProfiles, searchTags } from "Search/SearchApi";
import ProfileAvatar from "Profile/ProfileAvatar";
import logger from "Lib/logger";
import "./TaggedTextField.css";

const StyledMentionsInput = styled(MentionsInput)({
  fontFamily: "monospace",
});

const TaggedText = ({ value = '', onChange = () => {}, className = '', ...props }) => {

  const { authHeaders, currentUser } = useAuth();
  const [_value, _setValue] = useState(value || "");

  useEffect(() => {
    _setValue(value || "");
  }, [value]);


  return (
    <StyledMentionsInput
      value={_value}
      onChange={(e) => {
        const newValue = e.target.value;
        const mentions = extractMentions(newValue);
        const profiles = mentions.filter(m => m.display.startsWith("@"));
        const tags = mentions.filter(m => m.display.startsWith("#"));
        console.log(profiles, tags);

        if (onChange) {

          onChange({
            value: newValue,
            profiles,
            tags,
          });
        }

        _setValue(newValue);
      }}
      className={`TaggedTextField ${className}`}
      placeholder="Write a caption..."
      {...props}
    >
      <Mention
        trigger="@"
        data={(q, callback) => {
          searchProfiles(q, authHeaders).then(data => {
            const profileOptions = data
              .filter(d => d.userId !== currentUser.id)
              .map(d => d.getMentionData());
            logger("profileOptions", profileOptions);
            callback(profileOptions);
          });
        }}

        className="mention-user"
        markup="@[__display__](profile:__id__)"
        renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => (
          <div className={focused ? 'focused' : ''} style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
            <ProfileAvatar profile={suggestion} sx={{ width: 20, height: 20 }} link={false} />
            {highlightedDisplay}
          </div>
        )}
      />

      <Mention
        trigger="#"
        data={(q, callback) => {
          searchTags({ q }, authHeaders).then(data => {
            const tagOptions = data.map(tag => tag.getMentionData());
            logger("tagOptions", tagOptions);
            callback(tagOptions);
          });
        }}
        className="mention-tag"
        markup="@[__display__](tag:__id__)"
      />
    </StyledMentionsInput>
  );
};

export default TaggedText;



const extractMentions = (text) => {
  const mentionRegex = /@\[(.+?)\]\((.+?):(.+?)\)/g; // Matches @mentions in the markup format
  const mentions = [];
  let match;
  while ((match = mentionRegex.exec(text)) !== null) {
    mentions.push({
      display: match[1], // The visible name (e.g., JohnDoe)
      type: match[2],    // The type (e.g., user)
      id: match[3],      // The unique ID (e.g., 1)
    });
  }
  return mentions;
};

