import { range, mean } from 'd3-array';

// Smooth the data by grouping values or averaging
const smoothData = (audioData, groups) => {
  // const groupSize = Math.max(1, Math.floor(audioData.length / samplingRate)); // Adjust group size based on zoom level
  const groupSize = Math.max(1, Math.floor(audioData.length / groups));
  return range(0, audioData.length, groupSize).map(i => {
    const chunk = audioData.slice(i, i + groupSize);
    return mean(chunk); // Take the average of the chunk
  });
};

export default smoothData;