import { useRef, useEffect } from 'react'
import * as d3 from 'd3';
import { Typography, Box } from '@mui/material';
import TraceModel from 'Models/TraceModel';
import './TraceChart.css';
import {formatCount } from 'Lib/format';

export default function TraceChart({
  color = TraceModel.color,
  height = 80,
  tracePath,
  onBrushMove = () => {}
}) {

  const svgRef = useRef();


  useEffect(() => {
    if (!tracePath) return;


    draw(
      svgRef,
      tracePath,
      {
        color,
        height,
        // margin,
        onBrushMove
      })
    ;
  }, [tracePath]);


  return (
  <Box className="TraceChart">
    <div className="spanLayer">
      <div className="spanLayer-move">
        <span className="dot groundSpeed">●</span>
        <span className="groundSpeedSpan" />
        <span className="dot altitude">●</span>
        <span className="altitudeSpan" />
      </div>
    </div>
    <svg ref={svgRef} width="100%" height={height}>
      <g className="root">
      <rect width="100%" height={height} style={{ fill: "rgb(245,245,245)", fillOpacity: 0.5, rx: 5 }} />

        <g className="altFt">
          <path className="altFt" />
        </g>
        <g className="groundSpeed">
          <path className="groundSpeed" />
        </g>
        <line className="brush"/>
      </g>
    </svg>
    <div className="axisLabels" style={{ display: 'flex', flexDirection: 'row'}}>
      <div className="altitude" style={{ flex: 1 }}>Pressure Altitude</div>
      <div className="groundSpeed" style={{ flex: 1, textAlign: 'right' }}>Ground Speed</div>
    </div>
  </Box>
  );
}



function draw(svgRef, tracePath, options) {
  const columns = ['altFt', 'groundSpeed']

  const width = svgRef.current.clientWidth;
  const height = options.height;

  const svg = d3.select(svgRef.current);
  const gRoot = svg.select('g.root');

  const slightXPadding = 1
  const xScale = d3.scaleLinear()
    .domain(d3.extent(tracePath, d => d.t))
    .range([slightXPadding, width - slightXPadding]);


  columns.forEach((dataKey, i) => {

    const g = gRoot.select('.' + dataKey)

    const filteredData = tracePath.filter(d => isFinite(d[dataKey])  && d[dataKey] !== null && d[dataKey] !== undefined);

    const yExtent = d3.extent(filteredData, d => d[dataKey]);

    yExtent[0] = 0;

    const slightTopPadding = 5;
    const y = d3.scaleLinear()
      .domain(yExtent)
      .range([height + 2* slightTopPadding, slightTopPadding])
      .nice()

    const line = d3.line()
      .x(d => xScale(d.t))
      .y(d => y(d[dataKey]));


    g.select(`path.${dataKey}`)
      .datum(filteredData)
      .attr('fill', 'none')
      .attr('opacity', 1)
      .attr('stroke', i === 0 ? options.color : '#367AF6')
      .attr('stroke-linejoin', 'round')
      .attr('stroke-linecap', 'round')
      .attr('stroke-width', 1.5)
      .attr('d', line);


  });


  let isTouchDown = false;

  const brush = gRoot.select('line.brush')
    .attr('class', 'brush')
    .attr('x1', -20)
    .attr('y1', height)
    .attr('x2', 0)
    .attr('y2', 0)
    .style('stroke', '#777')
    .style('stroke-width', 1.5)
    .style('opacity', 0)
    .style('stroke-linecap', 'round');


  const down = event => {
    event.preventDefault();

    const e = event.touches ? event.touches[0] : event;


    isTouchDown = true;
    let [eX, _] = d3.pointer(e, gRoot.node);

    brush
      .attr('x1', eX)
      .attr('x2', eX)
      .style('opacity', 0.6);



    gRoot.selectAll(`text.label`)
      .transition()
      .style('opacity', 0);


    gRoot.selectAll(`path.gradient-line`)
      .transition()
      .style('opacity', 1);

    showBrush(e);
  };


  const move = event => {

    const e = event.touches ? event.touches[0] : event;

    if (isTouchDown) {
      showBrush(e);
    }
  }

  const rootLayer = d3.select(svg.node().parentNode);
  const moveLayer = rootLayer.select('div.spanLayer-move');
  const groundSpeedSpan = moveLayer.select('span.groundSpeedSpan');
  const altitudeSpan = moveLayer.select('span.altitudeSpan');


  const showBrush = (e) => {

    let [x, _] = d3.pointer(e, svgRef.current);

    if(x < 0) {
      return;
    }

    const t = Math.round(xScale.invert(x));

    const pointIndex = tracePath.findIndex((p, i) => (
      p.t >= t
    ));

    if (pointIndex === -1) {
      return;
    }


    const point = tracePath[pointIndex];

    brush
      .attr('x1', x)
      .attr('x2', x)


    if (options.onBrushMove) {
      options.onBrushMove(point);
    }


    const isRight = x > width / 2;
    const padTip = slightXPadding - 0.5;

    moveLayer
      .style('opacity', 1)
      .style('left', isRight ? 'inherit' : (x - padTip) + 'px')
      .style('right', isRight ? (width - x - padTip) + 'px' : 'inherit')
      .style('border-radius', isRight ? '5px 5px 0 5px' : '5px 5px 5px 0')

    groundSpeedSpan.text(formatCount(point.groundSpeed) + ' kts');
    altitudeSpan.text(formatCount(point.altFt) + ' ft');


  }

  const up = e => {
    isTouchDown = false;
    setTimeout(() => {
      moveLayer.style('opacity', 0);
      brush.style('opacity', 0);
    }, 3000);

  }



  svg.on('mousedown', down)
    .on('touchstart', down)
    .on('mousemove', move)
    .on('touchmove', move)
    .on('mouseup', up)
    .on('touchend', up)
    .on('touchcancel', up);

}

