import React from 'react';

const ImgRenditions = ({ visual, thumbnail, thumbnailSize = 80, style = {}, ...other }) => {
  other.onContextMenu = e => {
    if (!window.location.origin.includes('localhost')) {
      e.preventDefault();
    }
  }

  if (thumbnail) {
    // let url = visual.getThumbnailUrl();
    let url = visual.url;
    if (visual.renditions.length > 0) {
      const r = visual.renditions[visual.renditions?.length - 1];
      url = r.url;
    }

    return (
      <div style={{ position: 'relative', overflow: "hidden", width: thumbnailSize, height: thumbnailSize, ...style }}>
        <img
          src={url}
          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover' }}
          {...other}
        />
      </div>
    );
  }


  if(visual.constructor.name === 'File') {
    return <img
      src={URL.createObjectURL(visual)}
      {...other}
    />;
  }


  if (!visual.renditions?.length) {
    return <img
      src={visual.url}
      style={{...style}}
      {...other}
    />;
  }


  return (
    <img
      src={visual.renditions[0].url}
      srcSet={generateSrcSet(visual.renditions)}
      sizes={generateSizesAttr(visual.renditions)}
      loading="lazy"
      style={{...style}}
      {...other}
    />
  );
};

export default ImgRenditions;

const generateSrcSet = (renditions) => {
  return renditions
    .flatMap((rendition) => {
      // Generate both standard and Retina srcSet entries for renditions smaller than 2560
      const srcSet = [`${rendition.url} ${rendition.size}w`];
      if (rendition.size < 1920) {
        srcSet.push(`${rendition.url} ${rendition.size * 2}w`);
      }
      return srcSet;
    })
    .join(', ');
};

const generateSizesAttr = (renditions) => {
  return renditions
    .map((rendition, index) => {
      if (rendition.size >= 1920) return `(max-width: 1920px) ${rendition.size}px`;
      if (rendition.size >= 1280) return `(max-width: 1280px) ${rendition.size}px`;
      if (rendition.size >= 768) return `(max-width: 768px) ${rendition.size}px`;
      if (rendition.size >= 480) return `(max-width: 480px) ${rendition.size}px`;

      if (index === renditions.length - 1) return `${rendition.size}px`;
    })
    .filter(Boolean) // Remove any undefined values
    .join(', ');
};

// const ImgRenditions = ({ visual, thumbnail, defaultSize, ...other }) => {
//   if (thumbnail && visual.renditions.length) {
//     const r = visual.renditions[visual.renditions.length - 1];
//     return <img src={r.url} {...other} />
//   }

//   if(!visual.renditions.length)  {
//     return (
//       <img src={visual.url} {...other} />
//     )
//   }

//   return (
//     <img
//       src={defaultSize ? visual.renditions.find(p => p.size === defaultSize) : visual.renditions[0].url}
//       srcSet={visual.renditions.map(r => `${r.url} ${r.size}w`).join(', ')}
//       sizes={generateSizesAttr(visual.renditions)}
//       {...other}
//     />
//   );

// }

// export default ImgRenditions;


// const generateSizesAttr = (renditions) => {
//   return renditions
//     .map((rendition, index) => {
//       // Define viewport width conditions based on rendition size
//       if (rendition.size >= 1920) return `(min-width: 1920px) ${rendition.size}px`;
//       if (rendition.size >= 1280) return `(min-width: 1280px) ${rendition.size}px`;
//       if (rendition.size >= 768) return `(min-width: 768px) ${rendition.size}px`;
//       if (rendition.size >= 480) return `(min-width: 480px) ${rendition.size}px`;

//       // For the smallest rendition, assume it covers all smaller widths
//       if (index === renditions.length - 1) return `${rendition.size}px`;
//     })
//     .filter(Boolean) // Remove any undefined values
//     .join(', ');
// };