import { useEffect, useState } from 'react';
import { Typography, Grid, Box, Container, List, ListItem, ListItemText, Switch, ListItemSecondaryAction, Paper } from '@mui/material';
import Layout from 'Layout/Layout';
import WjCard from 'Lib/WjCard';

const DEVELOPMENT = '%COMMIT_HASH%';


function Settings() {
  const [commitHash, setCommitHash] = useState(null);

  useEffect(() => {
    const commitHash = document.querySelector('meta[name="commit-hash"]').getAttribute('content');
    setCommitHash(commitHash);
  }, []);

  return (
    <Layout>

      <Container sx={{ mb: 6, maxWidth: 470 }} style={{ backgroundColor: "#F9F8F5", borderTop: '1px solid #f1f1f1' }} >
        <Grid container spacing={0} >
          <Grid item xs={12}>
            <Container maxWidth="sm" disableGutters sx={{ ml: 'auto', mr: 'auto', mt: 2 }}>

              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, pl: 1 }}>
                <Box sx={{ flex: 1 }}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    Settings
                  </Typography>
                </Box>
              </Box>

              <WjCard>
                <Box sx={{ display: 'flex', alignItems: 'flex-start', p: 2 }}>
                  <Typography variant="body2" sx={{ flex: 1 }}>
                    Notify me about...
                  </Typography>
                  <Paper sx={{ flex: 1, backgroundColor: 'whitesmoke' }} elevation={0}>
                    <List >
                      <ListItem>
                        <ListItemText secondary="News and announcements" />
                        <ListItemSecondaryAction>
                          <Switch />
                        </ListItemSecondaryAction>
                      </ListItem>
                    </List>
                  </Paper>
                </Box>

              </WjCard>

              <Typography variant="caption" color="text.secondary" sx={{ fontSize: "0.625rem" }}>
                Version: {commitHash === DEVELOPMENT ? 'DEVELOPMENT' : commitHash}
              </Typography>

            </Container>


          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
}

export default Settings;
