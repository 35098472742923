
import { DateTime } from 'luxon';
import BaseModel from "Lib/BaseModel";
import ProfileModel from "Models/ProfileModel";

export default class UserPairModel extends BaseModel {
  constructor(data) {
    super(data || {});


    if(this.sender) {
      this.sender = new ProfileModel({...this.sender.profile, userId: this.senderId});
    }

    if(this.acceptor) {
      this.acceptor = new ProfileModel({...this.acceptor.profile, userId: this.acceptorId});
    }

    const { profileUserId } = this;

    if(!profileUserId) {
      return;
    }

    if(this.senderId === profileUserId ) {
      this.notCurrentProfileUser = this.acceptor;
    }
    else if (this.acceptorId === profileUserId) {
      this.notCurrentProfileUser = this.sender;
    }
    else {
      throw new Error('UserPairModel: notCurrentProfileUser not found');
    }
  }

  static STATUS_PAIRED = 'paired';
  static STATUS_MUTED = 'muted';
  static STATUS_PENDING = 'pending';


  isSent() {
    return [UserPairModel.STATUS_PENDING, UserPairModel.STATUS_MUTED].includes(this.status) && this.senderId === this.currentUserId;
  }

  isAwaitingAcceptance() {
    return this.status === UserPairModel.STATUS_PENDING && this.acceptorId === this.currentUserId;
  }

  isPaired() {
    return this.status === UserPairModel.STATUS_PAIRED;
  }

  isPending() {
    return this.status === UserPairModel.STATUS_PENDING;
  }

  isMuted() {
    return this.status === UserPairModel.STATUS_MUTED && this.acceptor.userId === this.currentUserId;
  }

  isMyProfile() {
    return this.profileUserId === this.currentUserId;
  }


}


