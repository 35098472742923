import BaseModel from "Lib/BaseModel";

export default class VisualModel extends BaseModel {
  constructor(data) {
    super(data || {});
  }

  isImage(){
    return this.contentType.includes('image');
  }

  isVideo(){
    return this.contentType.includes('video');
  }

  isQuicktime(){
    return this.contentType.includes('quicktime');
  }

  getLatLng(){
    if(!this.metadata) return null;

    const { geo, latitude, longitude } = this.metadata;

    if(geo && geo.latitude && geo.longitude){
      const { latitude, longitude } = geo;
      return [latitude, longitude];
    }

    if(latitude &&  longitude) {
      return [latitude, longitude];
    }

    return null;
  }

  getThumbnailUrl(){
    if (this.isQuicktime()){
      return null;
    }

    if(this.poster){
      return this.poster;
    }

    if(this.renditions && this.renditions.length > 0){
      return this.renditions[this.renditions.length - 1].url;
    }

    return this.url;
  }

}
