import { createTheme } from '@mui/material/styles';


export const themeData = {
  palette: {
    primary: {
      main: '#FC5200'
    },
    secondary: {
      main: '#3C3C3C'
    },
    primary: {
      main: '#FC5200',
    },
    secondary: {
      main: '#3C3C3C',
    },
    success: {
      main: '#4CAF50', // Customize the success color
      contrastText: '#FFFFFF', // Optional: customize text color
    },
    info: {
      main: '#2196F3', // Customize the info color
      contrastText: '#FFFFFF',
    },
    warning: {
      main: '#FF9800', // Customize the warning color
      contrastText: '#FFFFFF',
    },
    error: {
      main: '#F44336', // Customize the error color
      contrastText: '#FFFFFF',
    },
  }
}

const theme = createTheme(themeData);

export default theme;