import { ListItem, ListItemAvatar, Avatar, Typography, Box, Chip, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import ProfileAvatar from 'Profile/ProfileAvatar';
import ProfileAvatarAndName from 'Profile/ProfileAvatarAndName';

export default function LikeListItem({ like }) {

  return (
    <ListItem id={like.id} key={like.id} sx={{ p: 1, pl: 1.5, pr: 1.5, display: 'flex', flexDirection: 'row' }}>
      <ListItemAvatar sx={{ alignSelf: 'flex-start', flex: 1 }}>
        <ProfileAvatarAndName profile={like.profile} />
      </ListItemAvatar>

      <div style={{ display: 'flex' }}>
        <Typography variant="caption" color="text.secondary" sx={{ p: 0.5, pl: 0, display: 'inline-block', alignContent: 'center', fontSize: '0.7rem' }}>
          {like.relativeCreatedAt()}
        </Typography>
      </div>
    </ListItem>

  )
}