import axios from 'axios';
import LikeModel from 'Models/LikeModel';
import PostModel from 'Models/PostModel';
import CommentModel from 'Models/CommentModel';
import FlightModel from 'Models/FlightModel';
import logger from 'Lib/logger';

export const getLikesPage = (likeableTypeAndId, page, authHeaders) => {
  const perPage = 10;
  const offset = page * perPage;

  return axios.get(`/likes/${likeableTypeAndId.type}/${likeableTypeAndId.id}.json?offset=${offset}&limit=${perPage}`, authHeaders)
    .then(response => {
      const { data } = response;
      logger('getLikesPage page', page, 'count', data.count);


      const likes = data.likes.map(like => new LikeModel(like));
      logger('likes', likes);

      const likeable = newLikeable(likeableTypeAndId.type, data.likeable);
      logger('likeable', likeable);

      return { likes, likeable, count: data.count };
    });
}



export const like = (likeableTypeAndId, authHeaders) => {
  return axios.post(getUrl(likeableTypeAndId), {}, authHeaders)
    .then(response => response.data.likeable);
}


export const unlike = (likeableTypeAndId, authHeaders) => {
  return axios.delete(getUrl(likeableTypeAndId), authHeaders)
  .then(response => response.data.likeable);
}


function getUrl(likeableTypeAndId) {
  return `/likes/${likeableTypeAndId.type}/${likeableTypeAndId.id}.json`;
}



const newLikeable = (likeableType, likeableData) => {
  const modelMap = {
    post: PostModel,
    comment: CommentModel,
    flight: FlightModel,
  }

  const Model = modelMap[likeableType];
  return new Model(likeableData);
}
