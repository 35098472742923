import React from "react";
import { Box } from "@mui/material";
import { StyledInputProvider } from "./StyledInputContext";

const AuthScreen = ({ children, backgroundType, inputStyles }) => {
    const backgroundSources = {
        auth1: [
            { srcSet: "/auth/auth1-768.jpg", media: "(max-width: 768px)" },
            { srcSet: "/auth/auth1-992.jpg", media: "(max-width: 992px)" },
            { srcSet: "/auth/auth1-1200.jpg", media: "(max-width: 1200px)" },
            { srcSet: "/auth/auth1-1400.jpg", media: "(max-width: 1400px)" },
            { srcSet: "/auth/auth1-1920.jpg", media: "(max-width: 1920px)" },
            { srcSet: "/auth/auth1-3024.jpg", media: "(max-width: 3024px)" },
        ],
        auth2: [
            { srcSet: "/auth/auth2-768.jpg", media: "(max-width: 768px)" },
            { srcSet: "/auth/auth2-992.jpg", media: "(max-width: 992px)" },
            { srcSet: "/auth/auth2-1200.jpg", media: "(max-width: 1200px)" },
            { srcSet: "/auth/auth2-1400.jpg", media: "(max-width: 1400px)" },
            { srcSet: "/auth/auth2-1920.jpg", media: "(max-width: 1920px)" },
            { srcSet: "/auth/auth2-3024.jpg", media: "(max-width: 3024px)" },
        ],
    };

    const selectedBackground = backgroundSources[backgroundType] || backgroundSources.auth1;

    return (
        <StyledInputProvider value={inputStyles}>
            <Box sx={{
                position: "relative",
                backgroundColor: "rgba(255,255,255,0.91)",
                backgroundBlendMode: "overlay",
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "100vh",
                display: "flex",
                color: "black",
                textAlign: "center",
            }}
            >
                <picture>
                    {selectedBackground.map(({ srcSet, media }) => (
                        <source key={srcSet} srcSet={srcSet} media={media} />
                    ))}
                    <img src={selectedBackground[selectedBackground.length - 1].srcSet} alt=""
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            zIndex: -1,
                        }}
                    />
                </picture>
                {children}
            </Box>
        </StyledInputProvider>
    );
};

export default AuthScreen;
