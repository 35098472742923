import { useState, createContext, useContext } from 'react';

import logger from 'Lib/logger';
import { useAuth } from 'Auth/AuthProvider';
import SearchModal from './SearchModal';


export const SearchContext = createContext();


export default function SearchProvider({ children  }) {

  const { user, authHeaders } = useAuth();

  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);

  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);


  const openSearchModal = () => {
    logger('openSearchModal');

    setLoading(true);
    setIsOpen(true);

  };

  const closeSearchModal = () => {
    setIsOpen(false);
    setCount(0);
    setPage(0);
  }


  return (
    <SearchContext.Provider
      value={{
        openSearchModal,
        closeSearchModal,
      }}>

      {  children }
      <SearchModal
          loading={loading}
          open={isOpen}
          onClose={closeSearchModal}
        />
    </SearchContext.Provider>
  );


}


export const useSearch = () => useContext(SearchContext);

