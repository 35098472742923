import { Box, Toolbar, IconButton, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { ArrowBackIos } from '@mui/icons-material';

export default function CardHeader(props) {
  return (
    <Box
      sx={{ p: 1, display: 'flex', borderBottom: '1px solid #e0e0e0', alignItems: 'center' }}
    >
      {
        props.backTo && !props.onClickBack && <div style={{ alignContent: 'center' }}>
          <IconButton size="small" component={Link} to={props.backTo}>
            <ArrowBackIos/>
          </IconButton>
        </div>
      }

    {
        props.onClickBack && !props.backTo && <div style={{ alignContent: 'center' }}>
          <IconButton size="small" onClick={props.onClickBack}>
            <ArrowBackIos/>
          </IconButton>
        </div>
      }

      {
        props.title ?
        <Typography variant="body1" sx={{ flex: 1, fontWeight: 'bold'}}>{props.title}</Typography>
         :
        <>{ props.children }</>
      }

      <Toolbar variant="dense" disableGutters sx={{ minHeight: 24 }}>
       {props.toolbar}
      </Toolbar>
    </Box>
  )
}