import Layout from "Layout/Layout";
import LogbookTable from './LogbookTable';
import { Grid, Box, Typography } from '@mui/material';
import { LOGBOOK_ENTRIES_PER_PAGE } from './LogbookApi';
import { useEffect, useState } from "react";
import { useAuth } from "Auth/AuthProvider";
import FlightModel from "Models/FlightModel";
import withAuth from "../Auth/withAuth";

const LogbookCreatePage = () => {
  const { authHeaders } = useAuth();
  const [ flights, setFlights ] = useState([]);


  useEffect(() => {
    const newFlights = []
    for (let i = 0; i < LOGBOOK_ENTRIES_PER_PAGE; i++) {
      newFlights.push(new FlightModel());
    }
    setFlights(newFlights);

    setTimeout(() => document.querySelector('.Cell input').focus(), 200);
  }, []);

  return (
    <Layout>
      <Box sx={{ p: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6">New Logbook Page</Typography>
          </Grid>
          <Grid item xs={12}>
            <LogbookTable flights={flights}/>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  )
}

export default withAuth(LogbookCreatePage);
