import axios from 'axios';
import logger from 'Lib/logger';
import UserPairModel from 'Models/UserPairModel';

export const getUserPairs = ({ currentUserId, profileUserId, status = null, limit = 5, offset = 0 }, authHeaders) => {

  const isCurrentUser = currentUserId === profileUserId;

  let url = isCurrentUser ? '/user_pairs.json' : `/user_pairs/users/${profileUserId}.json`;
  url += `?limit=${limit}&offset=${offset}`;

  if (status) {
    url += `&status=${status}`;
  }

  logger('getUserPairs', url);

  return axios.get(url, authHeaders)
    .then(response => {
      const { data } = response;
      console.log('data', data);

      console.log(data.user_pairs)
      const userPairs = data.user_pairs.map(p =>
        new UserPairModel({...p, currentUserId, profileUserId})
      );

      logger('userPairs', userPairs);

      return { userPairs:userPairs, count: data.count };
    });
}


export const getUserPair = ({ profileUserId, currentUserId }, authHeaders) => {
  return axios.get(`/user_pairs/${profileUserId}.json`, authHeaders)
  .then(response => {
    logger('getUserPair', response.data.user_pair);
    const { user_pair } = response.data;
    return user_pair ? new UserPairModel({...response.data.user_pair, currentUserId, profileUserId}) : null;
  });
}


export const createUserPair = ({ profileUserId, currentUserId }, authHeaders) => {
  return axios.post(`/user_pairs/users/${profileUserId}/create.json`, {}, authHeaders)
  .then(response => {
    logger('createUserPair', response.data.user_pair);
    return new UserPairModel({...response.data.user_pair, currentUserId, profileUserId});
  });
}


export const updateUserPair = ({ userPairId, status }, authHeaders) => {
  return axios.post(`/user_pairs/${userPairId}.json`, { user_pair: { status } }, authHeaders)
  .then(response => {
    logger('updateUserPair', response.data.user_pair);
    return new UserPairModel(response.data.user_pair);
  });
}


export const deleteUserPair = ({ userPairId }, authHeaders) => {
  return axios.delete(`/user_pairs/${userPairId}.json`, authHeaders)
  .then(response => {
    logger('deleteUserPair', response);
  });
}
