import { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/CheckCircle';
import logger from 'Lib/logger';

export default function EditAudioStartTimeModal({ time, open = false, onSave=() => {}, onClose = () => {}, onUpdateFlight }) {
  const [_time, _setTime] = useState(time);

  useEffect(() => {
    if (open) {
      _setTime(time);
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Box sx={rootSx}>
        <TextField
          value={_time || ''}
          onChange={e => {
            const newTime = e.target.value;
            logger("newTime", newTime);
            _setTime(newTime);
          }}
          label="Audio Recording Start Time (Local)"
          helperText="Adjust to match the flight start time."
          fullWidth
          sx={{ mt: 2 }}
          size="small"
          inputProps={{ type: 'time' }}
          InputLabelProps={{ shrink: true }}
        />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
          <IconButton variant="text" size="small" onClick={onClose}>
            <ClearIcon/>
          </IconButton>
          <IconButton
            variant="contained"
            size="small"
            onClick={() => onSave(_time)}
            color={_time ? 'primary' : 'default'}
            disabled={!_time}>
              <CheckIcon/>
            </IconButton>
        </Box>
      </Box>
    </Modal>

  );
}

const rootSx = {
  p: 2,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 350,
  bgcolor: 'background.paper',
  boxShadow: 24,
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 1
};
