import { AirlineStops, FlightLand, FlightTakeoff } from "@mui/icons-material";
import { Box, Tooltip, Typography } from "@mui/material";
import haversineDistance from "Lib/haversineDistance";
import { formatNm } from "Lib/format";

export default function AirportChips({ airports, airportInfo = [], takeoffTime, landingTime }) {

  const airportLookup = {};
  airportInfo.forEach(a => {
    airportLookup[a.icaoId || a.arptId] = a;
  });
  console.log("airportLookup", airportLookup);



  return (
    <Box sx={{ p: 1, display: 'flex', position: 'relative', justifyContent: 'space-between' }}>
      <Box style={{
        right: 30,
        left: 30,
        position: 'absolute',
        top: 25,
        borderTop: '2px solid #FC5200',
        zIndex: 1,
        display: 'flex',
        fontSize: '10px',
        textAlign: 'center',
        color: '#777',
        paddingTop: 4
      }}>
        {
          airports.map((a, i) => {
            if (i === 0 || a === airports[i - 1]) return null;

            const a1 = airportLookup[a];
            const a0 = airportLookup[airports[i - 1]];

            const distance = haversineDistance(+a0.lat, +a0.lng, +a1.lat, +a1.lng);

            return (
              <div key={i} style={{ flex: 1 }}>
                {  formatNm(distance)  }nm
              </div>
          )
          })
        }
      </Box>

      {airports.map((a, i) => {
        const isFirst = i === 0;
        const isLast = i === airports.length - 1;
        const isMiddle = !isFirst && !isLast;

        return (
          <div key={i} style={{ zIndex: 2  }}>
            <div
              style={{
                marginTop: 5,
                marginBottom: 15,
                height: 24, width: 24,
                backgroundColor: '#FC5200',
                color: 'white',
                marginLeft: 'auto',
                marginRight: 'auto',
                borderRadius: '50%',
                textAlign: 'center',
                lineHeight: '24px',
              }}
            >
              {isFirst && <FlightTakeoff style={{ fontSize: 14 }} />}
              {isLast && <FlightLand style={{ fontSize: 14 }} />}
              {isMiddle && <AirlineStops style={{ fontSize: 14 }} />}
            </div>
            <div>
              <Tooltip title={airportLookup[a]?.name}>
              <Typography variant="caption" sx={{ display: 'block', textAlign: 'center', fontSize: '14px', fontWeight: 700, p: '1px' }}>
                {a}
              </Typography>
              </Tooltip>
              {/* <Typography
                variant="caption"
                sx={{ display: 'block', textAlign: 'center', fontSize: '10px', p: '1px', maxWidth: 100, textWrap: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
              >
                {airportLookup[a]?.name}
              </Typography> */}
              <Typography variant="caption" sx={{ textAlign: 'center', fontSize: '12px', p: '1px' }}>
                {isFirst && takeoffTime}
                {isLast && landingTime}

              </Typography>

            </div>

            {/* <Chip
            label={a}
            color="secondary"
            size="small"
            sx={{ fontSize: '10px', fontWeight: 700, p: '1px' }}
          /> */}
          </div>
        )
      })}
    </Box>
  );

}