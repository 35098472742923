import React, { useState, useEffect } from "react";
import { useFlight } from "Flights/FlightProvider";
import L from "leaflet";
import "./MapVisuals.css";
import { useMap } from "react-leaflet";

const MapVisuals = function ({ onClick = () => {} }) {
  const map = useMap();

  // const [ markers, setMarkers ] = useState([]);
  // const [ icons, setIcons ] = useState([]);
  // const markers = [];

  const { visuals } = useFlight();
  console.log("MapVisuals", visuals);

  useEffect(() => {

    console.log("map", map);
    console.log("visuals", visuals);

    if (!map || !visuals) return;



    const visualsWithGeo = visuals.filter(v => v.getLatLng());

    const newMarkers = [];

    // Store markers in a ref so we can update them later

    // Create base icon - use a consistent size
    const createIcon = (v) => {

      console.log("MapVisual v", v, v.getThumbnailUrl());

      return L.divIcon({
        html: `
          <img src="${v.getThumbnailUrl()}" style="width: 100%; min-height: 100%;" />
        `,
        iconSize: [32, 32],
        iconAnchor: [16, 16],
        popupAnchor: [16, -16],
        className: 'visual-container',
        latlng: v.getLatLng(),
      });
    };

    // Create and add markers
    visualsWithGeo.forEach((v) => {
      const icon = createIcon(v);
      const marker = L.marker(
        icon.options.latlng,
        { icon }
      ).addTo(map)
      .on('click', () => onClick(v));
      newMarkers.push(marker);
    });


    // Add zoom end event listener
    const onZoomEnd = () => {
      const zoom = map.getZoom();

      let scale;
      if(zoom >= 12){
        console.log("zoom > 12");
        scale = 2.5;
      } else if(zoom >= 8){
        console.log("zoom > 10");
        scale = 1;
      } else {
        console.log("else");
        scale = 0.5;
      }

      newMarkers.forEach(m => {
        const element = m.getElement();
        if (element) {
          element.style.width = `${32 * scale}px`;
          element.style.height = `${32 * scale}px`;
        }
      });

    };

    map.on('zoomend', onZoomEnd);

    // Initial scale
    onZoomEnd();

    console.log("newMarkers", newMarkers);

    // Cleanup
    return () => {
      newMarkers.forEach(m => m.remove());
      map?.off('zoomend', onZoomEnd);
    };

  }, [map, visuals]);

  return null
};

export default MapVisuals;
