import { LinearProgress, Typography } from "@mui/material";
import Nav from "Layout/Layout";
import ProfileForm from "./ProfileForm";
import ProfileModel from "Models/ProfileModel";
import { useProfile } from "./MyProfileProvider";
import { useNavigate } from "react-router-dom";
import withAuth from "../Auth/withAuth";

const MyProfileEdit = () => {
  document.title = "Edit Profile | WingJockey";

  const navigate = useNavigate();
  const { isLoadingProfile, profile, setProfile } = useProfile();

  return (
    <Nav>
      {
        isLoadingProfile || !profile ? <LinearProgress /> :
        <>
          <ProfileForm
            title={!profile.isProfileComplete() ?  "Complete Your Profile" : null}
            profile={profile}
            onUpdateProfilePhoto={profilePhotoUrl => setProfile(new ProfileModel({...profile, profilePhotoUrl}))}
            onUpdateProfileBanner={profileBannerUrl => setProfile(new ProfileModel({...profile, profileBannerUrl}))}
            onSave={(profile) => {
              setProfile(profile);
              navigate('/');
            }}
            showCancel={profile.isProfileComplete()}
          />
        </>
      }
    </Nav>
  )

}

export default withAuth(MyProfileEdit);
