import React, { useState, useRef, useEffect } from 'react';
import * as d3 from 'd3';
import { IconButton, LinearProgress, Chip, Box, Toolbar } from '@mui/material';
import smoothData from './smoothData';
import StretchySvg from './StretchySvg';
import { Add, Clear, Pause, PlayArrow } from '@mui/icons-material';
import { scrollIntoView } from "seamless-scroll-polyfill";
import "./AudioTrackBuilder.css";
import { formatDuration } from 'Lib/format';
import logger from 'Lib/logger';

const formatScale = d3.format('.1f');

export default class AudioTrackBuilder extends React.Component {

  constructor(props) {
    super(props);

    this.containerRef = React.createRef();
    this.gRootRef = React.createRef();
    this.scaleInfoRef = React.createRef();
    this.audioRef = React.createRef();
    this.currentTimeRef = React.createRef();

    this.state = {
      isReady: false,
      duration: 0,
      selection: { start: null, end: null },
      isPlaying: false,
      zoomLevel: 1,
      snippets: []
    }
  }

  componentDidMount() {
    const audio = this.audioRef.current;
    audio.load();
    audio.addEventListener('timeupdate', this.updatePlayhead);
  }

  loadWaveformData = () => {
    const audio = this.audioRef.current;

    d3.json(this.props.lufsUrl).then(data => {
      this.setState({
        duration: audio.duration,
        intensityValues: data,
      }, this.drawWaveform)
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.isReady && this.state.isReady) {
      this.loadWaveformData();
    }
    if (prevProps.currentTimeSeconds !== this.props.currentTimeSeconds) {
      this.setPlayheadAndAudioTime(this.props.currentTimeSeconds);
    }
  }

  componentWillUnmount() {
    const audio = this.audioRef.current;
    audio.removeEventListener('timeupdate', this.updatePlayhead); // Clean up listener
  }

  drawWaveform = () => {
    const { margin, height } = this.props;

    const gRootNode = this.gRootRef.current;
    const svgNode = gRootNode.parentNode;

    const gRoot = d3.select(gRootNode);
    const svg = d3.select(svgNode);


    const domain = d3.extent(this.state.intensityValues);

    this.yScale = d3
      .scaleLinear()
      .domain(domain)
      .range([margin.top, height - margin.top - margin.bottom]);

    gRoot.attr('transform', `translate(0, ${(height) / 2})`)

    const dragRegion = gRoot.select('rect.drag-region')
      .attr('x', 0)
      .attr('y', (-height / 4))
      .attr('width', "100%")
      .attr('height', height / 2)
      .attr('fill', "whitesmoke")
      .attr('fill-opacity', 0.5)
      .style('rx', 0.5)
      .style('ry', 0.5)
      .on('touchmove', e => {
        // Prevent scrolling while dragging
        e.preventDefault();
      });

    gRoot
      .select('line.playhead')
      .attr('x1', 0)
      .attr('x2', 0)
      .attr('y1', -height / 2)
      .attr('y2', height / 2)
      .attr('stroke', 'black')
      .attr('stroke-width', 3);

    svg.on('click', (event) => {
      const [mouseX] = d3.pointer(event);
      this.handleClick(mouseX)
    });

    this.drawBands();
    this.drawAxis();

    // Draggging selection
    let selectionRect = gRoot.select('rect.selection-rect');
    let startX = 0;

    // Helper function to get pointer position
    const getPointerPosition = (event) => {
      if (event.touches && event.touches.length) {
        return d3.pointer(event.touches[0], svgNode);
      }
      return d3.pointer(event, svgNode);
    };

    // Start Selection Event
    const startSelection = (event) => {
      logger('startSelection');
      const [x] = getPointerPosition(event);
      if (!isFinite(x)) {
        logger('!isFinite', !isFinite)
        return;  // Ensure x is finite
      }

      startX = x;
      svg.on('mousemove touchmove', dragSelection);
      svg.on('mouseup touchend', endSelection);

      selectionRect.attr('y', -height / 2)
        .attr('height', height)
        .attr('fill', 'blue')
        .attr('fill-opacity', 0.3);

    };

    // Drag Selection Event
    const dragSelection = (event) => {
      const [currentX] = getPointerPosition(event);
      const svgWidth = svgNode.clientWidth;
      const { duration } = this.audioRef.current;

      if (!isFinite(currentX) || !isFinite(startX) || !isFinite(svgWidth)) {
        logger('!isFinite', !isFinite)
        return;
      }

      const startPercent = (Math.min(startX, currentX) / svgWidth);
      const endPercent = (Math.max(startX, currentX) / svgWidth);

      this.setState({
        selection: { start: startPercent * duration, end: endPercent * duration },
      }, this.updatePlayheadPositionToStart);

      if (isFinite(startPercent) && isFinite(endPercent)) {
        selectionRect
          .attr('x', `${startPercent * 100}%`)
          .attr('width', `${endPercent * 100 - startPercent * 100}%`);
      }
    };

    // End Selection Event
    const endSelection = (event) => {
      logger('endSelection');
      startX = null;
      svg.on('mousemove touchmove', null).on('mouseup touchend', null);

      const startPercent = parseFloat(selectionRect.attr('x')) / 100;
      const widthPercent = parseFloat(selectionRect.attr('width')) / 100;

      if (!isFinite(startPercent) || !isFinite(widthPercent)) return;

      const audio = this.audioRef.current;
      const duration = audio.duration;
      const newTime = startPercent * duration;

      // On desktop, the click event is called after the mouseup event
      // But on touch devices, no click event is called
      if ('ontouchstart' in window) {
        this.handleClick(startPercent * svgNode.clientWidth);
      }

    };

    // Bind Start Selection to the select-area rectangle
    dragRegion.on('mousedown', startSelection).on('touchstart', startSelection);
  }


  updatePlayheadPositionToStart = () => {
    clearTimeout(this.updatePlayheadPositionTimeout);
    this.updatePlayheadPositionTimeout = setTimeout(() => {
      this.setAudioTime(this.state.selection.start);
    }, 200);
  }


  handleClick = (mouseX) => {
    logger('handleClick', mouseX);

    const svgNode = this.gRootRef.current.parentNode;
    const svgWidth = svgNode.clientWidth;
    const duration = this.audioRef.current.duration;
    const newTime = (mouseX / svgWidth) * duration;

    this.setPlayheadPercent(mouseX / svgWidth);
    this.setAudioTime(newTime);
  }


  setPlayheadAndAudioTime = (offsetSeconds) => {
    const percent = offsetSeconds / this.state.duration;
    this.setPlayheadPercent(percent);
    this.setAudioTime(offsetSeconds);
  }



  setPlayheadPercent = (percent) => {
    const newX = (percent * 100) + '%';
    d3.select(this.gRootRef.current).select('.playhead').attr('x1', newX).attr('x2', newX);
  }


  drawAxis = () => {
    const svgNode = this.gRootRef.current.parentNode;
    const svg = d3.select(svgNode);
    const width = svgNode.clientWidth;

    const xScaleTime = d3.scaleTime()
      .domain([0, this.state.duration]) // [startTime, endTime] (JavaScript Date objects)
      .range([0, width])
      .nice();



    const xAxis = d3.axisTop(xScaleTime)
      .ticks(width > 600 ? 3 : 6) // Specify the number of ticks
      .tickFormat(formatDuration);


    if (!this.props.hideAxis) {
      svg.select('.x-axis')
        .attr("transform", `translate(0, ${30})`) // Move the axis to the bottom
        .call(xAxis);
    }



  }

  onZoomChange = (d) => {
    logger('onZoomChange', d);
    this.drawAxis();
  }


  drawBands = () => {
    logger("drawBands");
    const gRoot = d3.select(this.gRootRef.current);
    const svgNode = this.gRootRef.current.parentNode;

    const band = 1.2;
    const samples = Math.max(Math.floor(svgNode.clientWidth / band), 2000);

    const intensityValues = smoothData(this.state.intensityValues, samples);


    this.xScale = d3
      .scaleLinear()
      .domain([0, 1])
      .range([0, 100]);


    const gBg = gRoot.select('g.bg');

    const rects = gBg.selectAll('rect')
      .data(intensityValues, (_, i) => i);

    rects.enter().append('rect')
      .merge(rects)
      .style('fill', "rgb(252, 82, 0)")
      .style('fill-opacity', 0.9)
      .attr('height', d => this.yScale(d))
      .attr('width', band)
      .attr('x', (_, i) => (i / intensityValues.length * 100) + '%')
      .attr('y', d => -this.yScale(d) / 2)
      .attr("rx", band / 2)
      .attr("ry", band / 2);

    rects.exit().remove();
  }

  onZoomEnd = (e) => {
    this.drawBandsTimeout = setTimeout(() => {
      clearTimeout(this.drawBandsTimeout);
      this.drawBands();
    })
  }

  catchInterrupt = (error) => {
    if (error.name === 'AbortError') {
      console.warn("Play request was interrupted by a pause call.");
    } else {
      console.error("An error occurred during playback:", error);
    }
  }

  handlePlay = () => {
    const audio = this.audioRef.current;
    logger('handlePlay', audio.currentTime);
    audio.play().catch(this.catchInterrupt);
    this.setState({ isPlaying: true });
  };

  handlePause = () => {
    this.audioRef.current.pause();
    this.setState({ isPlaying: false });
  };


  setAudioTime = (newTime) => {
    const audio = this.audioRef.current;
    audio.currentTime = newTime;


    // Force a refresh in playback position

    if (this.state.isPlaying) {
      setTimeout(() => {
        audio.pause();
        audio.play().catch(this.catchInterrupt);
      }, 50);
    }
  }



  // Function to update playhead position based on audio time
  updatePlayhead = () => {
    const audio = this.audioRef.current;
    const { currentTime, duration } = audio;
    this.setPlayheadPercent(currentTime / duration);
    this.currentTimeRef.current.textContent = formatDuration(currentTime);
    this.props.onChange({ timeSeconds: currentTime, duration: duration });
  };


  scrollPlayheadIntoView = () => {
    logger('scrollPlayheadIntoView')
    const gRoot = d3.select(this.gRootRef.current);
    const playhead = gRoot.select('line.playhead');
    scrollIntoView(
      playhead.node(),
      { behavior: 'smooth', block: 'nearest', inline: "nearest" }
    )
  };

  scrollSelectionIntoView = () => {
    logger('scrollSelectionIntoView')
    const gRoot = d3.select(this.gRootRef.current);
    const selectionRect = gRoot.select('rect.selection-rect');
    scrollIntoView(
      selectionRect.node(),
      { behavior: 'smooth', block: 'nearest', inline: "nearest" }
    )

  };

  clearSelection = () => {
    const gRoot = d3.select(this.gRootRef.current);
    gRoot.select('rect.selection-rect').attr('width', 0);
    this.setState({ selection: { start: null, end: null } });
  }

  onAddSnippet = () => {
    const { selection } = this.state;
    const newSnippet = { ...selection, id: Date.now() };
    this.setState({
      snippets: [...this.state.snippets, newSnippet],
      selection: { start: null, end: null }
    });
  }



  render() {
    const { audioUrl } = this.props;
    const { isReady, isPlaying, selection } = this.state;

    return (
      <div className="AudioTrackBuilder" style={{ maxWidth: 1800, marginLeft: 'auto', marginRight: 'auto' }}>
        {/* Hidden audio element */}
        <audio
          ref={this.audioRef}
          src={audioUrl}
          preload="auto"
          hidden
          onLoadedMetadata={() => {
            this.setState({ isReady: true });
          }}
        />



        <div style={{
          //  border: '2px solid rgb(252, 82, 0)',
          borderRadius: 8
        }}>
          {!isReady ? <LinearProgress /> : null}
          {
            isReady &&

            <StretchySvg
              centerOnStretch
              height={this.props.height}
              minScale={1}
              maxScale={this.props.maxScale}
              onZoomChange={this.onZoomChange}
              onZoomEnd={this.onZoomEnd}
            >
              <g ref={this.gRootRef} className="root">
                <g className="bg" />
                <rect className="drag-region" />
                <rect className="selection-rect" />
                <line className="playhead" />
              </g>

              <g className="x-axis" />
            </StretchySvg>
          }
        </div>

        <div>


          {/* Player controls */}
          <div style={{ paddingTop: 10, display: 'flex', flexDirection: 'row', fontSize: 12 }}>
            {/* Left */}
            <Box sx={{ flex: 1 }}>
              <IconButton size="small" onClick={() => isPlaying ? this.handlePause() : this.handlePlay()} color="primary" sx={{ bgcolor: 'whitesmoke', mr: '8px' }}>
                {isPlaying ? <Pause /> : <PlayArrow />}
              </IconButton>
              <div className="player-time" onClick={this.scrollPlayheadIntoView} tabIndex={0}>
                <span ref={this.currentTimeRef} style={{ fontWeight: 'bold' }}>00:00:00</span >
                &nbsp;/&nbsp;
                {formatDuration(this.state.duration)}
              </div>

              <Toolbar disableGutters>

              </Toolbar>

            </Box>

            {/* Right */}
            <Box sx={{ flex: 1, textAlign: 'right' }}>


              <div className="player-time" onClick={this.scrollSelectionIntoView} tabIndex={0}>
                {
                  selection.start && selection.end ?
                    <>{formatDuration(selection.start)}&nbsp;-&nbsp;{formatDuration(selection.end)}</>
                    : "Select a region"
                }
              </div>

              <Toolbar disableGutters sx={{ justifyContent: 'flex-end' }}>
                <IconButton
                  disabled={!selection.start}
                  color="primary"
                  sx={{ bgcolor: 'whitesmoke', mr: '6px' }}
                  onClick={this.onAddSnippet}
                >
                  <Add />
                </IconButton>

                <IconButton
                  disabled={!selection.start}
                  color="primary"
                  sx={{ bgcolor: 'whitesmoke' }}
                  onClick={this.clearSelection}
                >
                  <Clear />
                </IconButton>
              </Toolbar>

            </Box>



          </div>


        </div>


        <Box>
          {this.state.snippets.map(snippet => JSON.stringify(snippet))}
        </Box>


      </div>
    );
  }
}


AudioTrackBuilder.defaultProps = {
  margin: { top: 40, right: 0, bottom: 40, left: 0 },
  height: 300,
  maxScale: 30
}
