import React from 'react';
import { Link } from 'react-router-dom';
import { Box, MenuItem, MenuList, Menu, ListItemIcon, ListItemText, Avatar, IconButton, Button } from '@mui/material';
import { ArrowDropDownRounded, ArrowDropUpRounded, NotificationsNone, NotificationsActive, Search, Settings, AddCircleOutline, AccountCircle, TrackChanges, Logout, Flight, Send, PostAdd, TrendingUp, Book, TravelExplore, Public, Map, FlightTakeoff, LocationOn } from '@mui/icons-material';
import { useSearch } from 'Search/SearchProvider';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SearchAutocomplete from 'Search/SearchAutocomplete';
import ProfileAvatar from 'Profile/ProfileAvatar';
import { useNotifications } from 'Notifications/NotificationsProvider';
import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { formatCount } from 'Lib/format';



const SignedInNavToolbar = ({ profile }) => {
  const { openSearchModal } = useSearch();
  let { unreadCount } = useNotifications();
  const [anchorElAdd, setAnchorElAdd] = React.useState(null);
  const [anchorElExplore, setAnchorElExplore] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const theme = useTheme();

  const exploreDropdownLinks = [
    { to: '/explore/my-flights', text: 'My Flights', icon: FlightTakeoff },
    { to: '/explore/pilots', text: 'Pilots', icon: TrackChanges },
    { to: profile?.homeAirportId ? `/explore/airports/${profile?.homeAirportId}` : '/explore/airports', text: profile?.homeAirportId ? profile?.homeAirportId : 'Home Airport', icon: LocationOn },
  ];

  const addDropdownLinks = [
    { to: '/flights/new', text: 'Log Flight', icon: Flight },
    { to: '/posts/new', text: 'Create a Post', icon: PostAdd },
    { to: '/friends/invite', text: 'Invite a Friend', icon: Send },
  ];


  const avatarDropdownLinks = [
    { to: '/notifications', text: 'Notifications', icon: unreadCount > 0 ? NotificationsActive : NotificationsNone, badgeCount: unreadCount },
    { to: '/profile', text: 'My Profile', icon: AccountCircle },
    { to: '/logbook', text: 'Logbook+', icon: Book },
    { to: '/progress-tracker', text: 'Progress Tracker', icon: TrendingUp },
    { to: '/settings', text: 'Settings', icon: Settings },
    { to: '/sign-out', text: 'Sign out', icon: Logout },
  ];



  const handleOpenAddMenu = (event) => {
    setAnchorElAdd(event.currentTarget);
  };

  const handleCloseAddMenu = () => {
    setAnchorElAdd(null);
  };

  const handleOpenExploreMenu = (event) => {
    setAnchorElExplore(event.currentTarget);
  };

  const handleCloseExploreMenu = () => {
    setAnchorElExplore(null);
  };


  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const isPhone = useMediaQuery(theme.breakpoints.down('sm'));


  return (
    <>


      <Box sx={{ flexGrow: 0, display: 'flex' }}>
        {isPhone &&
          <IconButton
            sx={{ width: 50 }}
            onClick={openSearchModal}
          >
            <TravelExplore />
          </IconButton>
        }

        {
          !isPhone &&
          <SearchAutocomplete />
        }


        {
          !isPhone &&
          <>

            <IconButton
              sx={{ width: 50 }}
              onClick={handleOpenExploreMenu}
            >
              <Public color={window.location.pathname.includes('/explore/') ? 'primary' : 'inherit'} />
            </IconButton>

            <DropdownMenu
              id="menu-add"
              anchorEl={anchorElExplore}
              links={exploreDropdownLinks}
              handleClose={handleCloseExploreMenu}
            />


          </>
        }




        <IconButton
          sx={{ width: 50 }}
          onClick={handleOpenAddMenu}
        >
          <AddCircleOutline color={window.location.pathname === '/flights/new' ? 'primary' : 'inherit'} />
        </IconButton>

        <DropdownMenu
          id="menu-add"
          anchorEl={anchorElAdd}
          links={addDropdownLinks}
          handleClose={handleCloseAddMenu}
        />



        <Button
          size="small"
          onClick={handleOpenUserMenu}
          sx={{ p: 1 }}
        >
          {
            profile && profile.profilePhotoUrl &&
            <Badge badgeContent={unreadCount}
              color="primary"
              sx={{ opacity: anchorElUser ? 0.5 : 1 }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <ProfileAvatar link={false} profile={profile} sx={{ height: 34, width: 34 }} />
            </Badge>
          }
          {
            anchorElUser ? <ArrowDropUpRounded sx={{ color: "#999" }} /> : <ArrowDropDownRounded sx={{ color: "#999" }} />
          }
        </Button>
        <DropdownMenu
          id="menu-user"
          anchorEl={anchorElUser}
          links={avatarDropdownLinks}
          handleClose={handleCloseUserMenu}
        />

      </Box>

    </>
  )
};



const DropdownMenu = ({ id, anchorEl, links, handleClose }) => {
  return (
    <Menu
      sx={{ mt: '40px' }}
      id={id}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <MenuList disablePadding sx={{ root: { padding: 0 } }}>
        {links.map((link, i) => {

      const Icon = link.icon;
          const isLast = i === links.length - 1;
          return (
            <MenuItem
              key={link.to}
              component={Link}
              to={link.to}
              onClick={handleClose}
              sx={{
                borderBottom: isLast ? 'none' : '1px solid #f1f1f1',
                justifyItems: 'center'
              }}
            >
              <ListItemIcon color="primary">

                <Badge
                  badgeContent={link.badgeCount || 0} color="success" variant="dot" anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}>
                  <Icon color="primary" sx={{ height: 20, width: 20 }} />
                </Badge>
              </ListItemIcon>

              <ListItemText disableTypography sx={{}}>
                {link.text}
              </ListItemText>
              {
                link.badgeCount > 0 &&
                <ListItemSecondaryAction>
                  <Typography
                    variant="caption"
                    color="info"
                    sx={{ fontSize: '0.65rem' }}>
                      {formatCount(link.badgeCount)}
                    </Typography>
                </ListItemSecondaryAction>
              }

            </MenuItem>
          )
        })}
      </MenuList>
    </Menu>
  )
}


export default SignedInNavToolbar;