import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'Auth/AuthProvider'
import withAuth from "./withAuth";

const SignOut = () => {
  const navigate = useNavigate();
  const { signOut } = useAuth();

  signOut()
    .then(() => {
      navigate('/');
    });

  return null;
}

export default withAuth(SignOut);
