import { ListItemButton, ListItemText, ListItemAvatar, Avatar } from '@mui/material';
import { Link } from 'react-router-dom';
import { Tag } from '@mui/icons-material';
import { formatCount } from 'Lib/format';


export default function TagOption({ tag, onClick = () => { } }) {
  return (
    <ListItemButton
      key={tag.id}
      component={Link}
      to={`/search?tag=${tag.name}`}
      onClick={onClick}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'rgba(0, 0, 0, 0.08)' }}>
          <Tag color="secondary" />
        </Avatar>

      </ListItemAvatar>
      <ListItemText
        primary={tag.name}
        secondary={ formatCount(tag.taggingsCount) + ( tag.taggingsCount === 1 ? ' post' : ' posts' ) }
      />
    </ListItemButton>
  );
}



