import { Modal, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import VisualGallery from 'Lib/VisualGallery';


const VisualGalleryModal = ({ open, handleClose, visuals, initialIndex }) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiBox-root': {
          outline: 'none', // to remove the outline on modal
        },
      }}
    >
      <>
      <Box className={classes.modalBox}>
        <VisualGallery
          visuals={visuals}
          initialIndex={initialIndex}
        />
      </Box>
      </>
    </Modal>
  );
};

export default VisualGalleryModal;


const useStyles = makeStyles(() => ({
  modalBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'black',
    borderRadius: 8,
    boxShadow: 'none',
    maxWidth: '90%',
    maxHeight: '90%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    position: 'relative',
    overflow: 'hidden', // Ensure images don't overflow
  }
}));

