import { Badge, Tooltip, IconButton } from '@mui/material';
import { ThumbUpAlt, ThumbUpOffAlt } from '@mui/icons-material';
import { like, unlike } from 'Likes/LikesApi';
import { useLikes } from 'Likes/LikeProvider';
import { useAuth } from 'Auth/AuthProvider';
import AddRounded from '@mui/icons-material/AddRounded';
import logger from 'Lib/logger';


function LikeButton({ likeable, onToggleLike, isCurrentUser }) {
  const { openLikeModal } = useLikes();
  const { authHeaders } = useAuth();

  const toggleLike = (e) => {
    e.stopPropagation();
    logger('toggleLike isLiked', likeable.isLiked === true, likeable);

    const apiMethod = likeable.isLiked ? unlike : like;

    apiMethod({ type: likeable.likeableType, id: likeable.id }, authHeaders)
      .then(onToggleLike);
  };


  return (
    <Badge
      // badgeContent={
      //   likeable.likesCount > 0 ?
      //     <span
      //       className={`badge ${likeable.isLiked ? 'rotate-hover' : ''}`}
      //       style={{ transform: 'rotate(45deg)' }}
      //     >
      //       ✛
      //     </span> : 0}
      badgeContent={likeable.likesCount > 0 ? <AddRounded sx={{ fontSize: 14 }} /> : 0}
      color={likeable.isLiked ? 'primary' : 'secondary'}
      style={{
        WebkitTapHighlightColor: 'transparent',
      }}
      slotProps={{
        badge: {
          style: {
            fontSize: '0.9rem',
            fontWeight: 'bold',
            padding: 0,
            lineHeight: '20px',
          },
        }
      }}
      sx={{
        cursor: 'pointer',
        backgroundColor: 'transparent',
        '&:hover': {
          outline: 'none',
          backgroundColor: 'transparent',
        }
      }}
      onClick={() => openLikeModal({ type: likeable.likeableType, id: likeable.id })}
      component="div"

    >

      <Tooltip title={likeable.isLiked ? 'Unlike' : ( isCurrentUser ? `It's ok to like your own stuff 🤣` : 'Like' )}>
        <IconButton
          size="small" onClick={toggleLike}
          sx={{ border: '2px solid transparent' }}
        >
          {likeable.isLiked ? <ThumbUpAlt /> : <ThumbUpOffAlt />}
        </IconButton>
      </Tooltip>
    </Badge>

  )



}

export default LikeButton;