import React from "react";
import { MenuItem, TextField } from "@mui/material";
import { useStyledInputContext } from "./StyledInputContext";

const StyledInput = ({
    id,
    label,
    value,
    onChange,
    required = false,
    select = false,
    options = [],
    color,
    labelColor,
    borderColor,
    backgroundColor,
    fullWidth = true,
    margin = "normal",
    extInputProps = {},
    ...props
  }) => {
    const context = useStyledInputContext();
    const effectiveColor = color || context.color;
    const effectiveLabelColor = labelColor || context.labelColor;
    const effectiveBorderColor = borderColor || context.borderColor;
    const effectiveBackgroundColor = backgroundColor || context.backgroundColor;

    const inputBorderStyles = {
        mb: 2,
        "& .MuiOutlinedInput-root": {
            "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: `${effectiveBorderColor} !important`,
                borderWidth: "0 0 1px 0 !important",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: `${effectiveBorderColor} !important`,
                borderWidth: "0 0 2px 0 !important",
            },
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: `${effectiveBorderColor} !important`,
                borderWidth: "0 0 1px 0 !important",
            },
        },
        "& .MuiInputLabel-root": {
            color: `${effectiveLabelColor} !important`,
        },
        "& .MuiInputLabel-root.Mui-focused": {
            color: `${effectiveLabelColor} !important`,
        },
    };

    const inputLabelProps = {
        sx: {
            "&.MuiInputLabel-shrink": {
                color: effectiveLabelColor,
                padding: "0 10px",
                borderRadius: "4px",
                transform: "translate(6px, 3px) scale(0.75)",
            },
            color: effectiveLabelColor,
        },
    };

    const inputProps = {
        style: {
            color: effectiveColor,
            backgroundColor: effectiveBackgroundColor,
            textAlign: select ? 'left' : 'inherit',
        },
        sx: {
            "& .MuiOutlinedInput-input": {
                padding: "23px 14px 10px",
            },
        },
        ...extInputProps,
    };

    return (
        <TextField
            id={id}
            label={label}
            value={value}
            onChange={onChange}
            required={required}
            fullWidth={fullWidth}
            margin={margin}
            InputLabelProps={inputLabelProps}
            InputProps={inputProps}
            sx={inputBorderStyles}
            select={select}
            {...props}
        >
            {select && options.map((option) => (
                    <MenuItem key={option} value={option}>
                        {option}
                    </MenuItem>
                ))}
        </TextField>
    );
};

export default StyledInput;
