import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import  * as FlightsApi from 'Flights/FlightsApi';
import { useParams } from 'react-router-dom';
import { useAuth } from 'Auth/AuthProvider';
import logger from 'Lib/logger';
import FlightModel from 'Models/FlightModel';

// Create context
const FlightContext = createContext();


export const FlightProvider = ({ children }) => {
  const params = useParams();
  const { authHeaders } = useAuth();
  const [flight, setFlight] = useState(null);
  const [visuals, setVisuals] = useState([]);
  const [audio, setAudio] = useState(null);

  const [isPollingVisuals, setIsPollingVisuals] = useState(false);
  const [isPollingAudio, setIsPollingAudio] = useState(false);


  // Fetch flight data
  const fetchFlight = useCallback(async () => {
    try {
      const flight = await FlightsApi.getFlight(params.flightId, authHeaders);
      setFlight(flight);
      logger('fetchFlight', flight);
    } catch (error) {
      console.error("Error fetching flight data:", error);
    }
  }, [params.flightId]);


  // Fetch visuals
  const fetchVisuals = useCallback(async () => {
    logger('fetchVisuals...');
    try {
      const visuals = await FlightsApi.getVisualsByFlight(params.flightId, authHeaders);
      setVisuals(visuals);

      if (visuals.filter(v => v.isQuicktime()).length > 0) {
        logger('Polling visuals...');
        setIsPollingVisuals(true);
      } else {
        setIsPollingVisuals(false);
      }
    } catch (error) {
      console.error("Error fetching flight visuals:", error);
    }
  }, [params.flightId]);

  // Fetch audio
  const fetchAudio = useCallback(async () => {
    logger('fetchAudio...');
    try {
      const audios = await FlightsApi.getAudiosByFlight(params.flightId, authHeaders);
      const newAudio = audios.length > 0 ? audios[0] : null;

      if (!newAudio) {
        setAudio(null);
        return;
      }

      console.log('audio', audio);
      const isNewAudio = !audio || (audio.id !== newAudio.id);
      const isStillProcessing = newAudio.metadata.status === "processing";

      if (isNewAudio) {
        logger('New audio detected:', newAudio);
        if (audio) {
          logger('Clearing previous audio:', audio.id);
        }
        setAudio(newAudio);
      }

      if (isStillProcessing) {
        logger('Polling audios...');
        setIsPollingAudio(true);
      }
      else {
        setIsPollingAudio(false);
      }

    } catch (error) {
      console.error("Error fetching flight audios:", error);
    }
  }, [params.flightId]);

  const updateFlightMetadata = (flightData) => {
    return FlightsApi.updateFlightMetadata(
      new FlightModel({id: flight.id, ...flightData}),
      authHeaders
    )
      .then(newFlightData => {
        const newFlight = new FlightModel({ ...flight.asJSON(), ...newFlightData });
        setFlight(newFlight);
      });
  }


  const updateAudioMetadata = (newMetadata) => {
    logger('updateAudioMetadata newMetadata', newMetadata);
    return FlightsApi.updateAudioMetadata(
      flight.id,
      audio.id,
      newMetadata,
      authHeaders
    )
      .then(audio => {
        const newAudio = new FlightModel(audio);
        logger('updateAudioMetadata newAudio', newAudio);
        setAudio(newAudio);
      });
  }




  // Initialize data on mount
  useEffect(() => {
    fetchFlight();
    fetchVisuals();
    fetchAudio();
  }, [fetchFlight, fetchVisuals, fetchAudio]);


  // Polling mechanism for visual processing
  useEffect(() => {
    if (!isPollingVisuals) return;

    const intervalId = setInterval(() => {
      fetchVisuals(); // Poll attachments only
    }, 5000); // 5-second interval, adjust as needed

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [isPollingVisuals, fetchVisuals]);


  // Polling mechanism for audio processing
  useEffect(() => {
    if (!isPollingAudio) return;

    const intervalId = setInterval(() => {
      fetchAudio(); // Poll attachments only
    }, 30 * 1000); // 5-second interval, adjust as needed

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [isPollingAudio, fetchAudio]);



  // Provide the state and polling control functions
  return (
    <FlightContext.Provider
      value={{
        flight,
        setFlight,
        visuals,
        audio,
        fetchVisuals,
        fetchFlight,
        fetchAudio,
        updateAudioMetadata,
        updateFlightMetadata,
      }}
    >
      {children}
    </FlightContext.Provider>
  );
};

export const useFlight = () => useContext(FlightContext);

export default FlightProvider;