import axios from 'axios';
import ProfileModel from '../Models/ProfileModel';
import { convertKeysToSnakeCase } from '../Lib/convertCase';
import logger from 'Lib/logger';
import FlightStatsModel from 'Models/FlightStatsModel';
import UserPairModel from 'Models/UserPairModel';
import AirportModel from 'Models/AirportModel';

// Function to load the pilot profile
export const getMyProfile = (authHeaders) => {
  return axios.get('/profiles.json', authHeaders)
    .then(response => {
      const profile = new ProfileModel(response.data.profile || {} );
      logger('My profile loaded:', profile);

      const flightStats =  new FlightStatsModel(response.data.flight_stats || {} );
      logger('Flight stats loaded:', flightStats);

      const airport = new AirportModel(response.data.airport || {});
      logger('Airport loaded:', airport);

      return { profile, flightStats, airport };
    })
    .catch(error => {
      console.error('Error loading my pilot profile:', error);
    });
};

// Function to update the pilot profile
export const updateMyProfile = (formData, authHeaders) => {

  formData.profileBanner = formData.profileBannerUrl;
  delete formData.profileBannerUrl;
  delete formData.optionType;

  const {
    medicalExpirationDates,
    profilePhotoUrl,
    flightReviewExpirationDate,
    ...profileData
  } = formData;

  const profile = convertKeysToSnakeCase({
    ...profileData,
    firstName: formData.firstName.trim(),
    lastName: formData.lastName.trim(),
  });



  try {
    return axios.post(
      '/profiles.json',
      { profile },
      authHeaders
    ).then(response => {
      logger('Profile updated:', response.data);
      return new ProfileModel(response.data.profile);
    });

  } catch (error) {
    console.error('Error updating my pilot profile:', error);
    throw error;
  }
};



// Function to load the pilot profile
export const getNotMyProfile = (userId, authHeaders) => {
  return axios.get(`/profiles/${userId}.json`, authHeaders)
    .then(response => {
      logger('response.data:', response.data);
      const profile = new ProfileModel(response.data.profile || {} );
      logger('Not my profile loaded:', profile);

      const flightStats =  new FlightStatsModel(response.data.flight_stats || {} );
      logger('Flight stats loaded:', flightStats);

      const userPairStatus = new UserPairModel(response.data.user_pair_status || {});

      return { profile, flightStats, userPairStatus };
    })
    .catch(error => {
      console.error('Error loading pilot profile:', error);
    });
};
