import React, { useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField, Button, Container, Typography, MenuItem, Grid, Checkbox, FormControlLabel } from '@mui/material';
import { updateMyProfile } from './ProfileApi';
import ProfileModel from '../Models/ProfileModel';
import ProfilePhotoUpload from './ProfilePhotoUpload';
import ProfileBannerUpload from './ProfileBannerUpload';
import ProfileSectionCard from './ProfileSectionCard';
import { LoadingButton } from '@mui/lab';
import { Link } from 'react-router-dom';
import HomeAirportAutocomplete from './HomeAirportAutocomplete';
import { useSnackbar } from 'Lib/Snackbar';
import { ArrowBackIos } from '@mui/icons-material';
import DateField from 'Lib/DateField';
const { useAuth } = require('Auth/AuthProvider');


export const requiredFields = [
  { field: 'firstName', message: 'Please enter the departure date.' },
  { field: 'registrationNumber', message: 'Please enter a tail number.' },
  { field: 'departureAndArrivalAirports', message: 'Please enter airports.' },
  { field: 'totalHours', message: 'Please enter total hours' },
];



export default function ProfileForm(props) {
  const [isSaving, setIsSaving] = useState(false);
  const [profile, setProfile] = useState(new ProfileModel({ ...props.profile } || {}));
  const { showSnackbar } = useSnackbar();
  const { authHeaders } = useAuth();

  const handleChange = e => {
    const { name } = e.target;
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setProfile(new ProfileModel({ ...profile, [name]: value }));
  };

  const handleDateBlur = e => {
    if (e.target.name == "soloDate") profile.soloDate = e.target.value; // + "T00:00:00.0000";
    if (e.target.name == "privatePilotDate") profile.privatePilotDate = e.target.value; // + "T00:00:00.0000";
    if (e.target.name == "flightReviewDate") profile.flightReviewDate = e.target.value; // + "T00:00:00.0000";
    if (e.target.name == "medicalDate") profile.medicalDate = e.target.value; // + "T00:00:00.0000";
  };

  const handleSubmit = e => {
    e.preventDefault();
    setIsSaving(true);
    return updateMyProfile(profile, authHeaders)
      .then(() => {
        showSnackbar('Profile updated');
        if (props.onSave) {
          props.onSave(profile);
        }
      })
      .finally(() => setIsSaving(false));
  };



  return (
    <Container maxWidth="md" sx={{ p: 1, pt: 6 }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        {props.title || "Edit Your Profile"}
      </Typography>

      <ProfileSectionCard title="Pilot Info" sx={{ mb: 4 }}>
        <ProfileBannerUpload
          profileBannerUrl={profile.profileBannerUrl}
          onUpdate={profileBannerUrl =>
            props.onUpdateProfileBanner(profileBannerUrl)
          }
        />


        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <ProfilePhotoUpload
              profilePhotoUrl={profile.profilePhotoUrl}
              onUpdate={profilePhotoUrl =>
                props.onUpdateProfilePhoto(profilePhotoUrl)
              }
            />
          </Grid>

          <Grid item xs={12} md={8}>
            {/* Nested Form Grid */}
            <Grid container spacing={2}>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  required
                  label="First Name"
                  name="firstName"
                  value={profile.firstName || ""}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  required
                  label="Last Name"
                  name="lastName"
                  value={profile.lastName || ""}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12}>
                <HomeAirportAutocomplete
                  value={profile.homeAirportId}
                  onChange={(value) => setProfile(new ProfileModel({ ...profile, homeAirportId: value }))}
                />
              </Grid>

              <Grid item xs={12}>

                <TextField
                  sx={{ mt: 0 }}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="pilotType"
                  label="I am a..."
                  name="pilotType"
                  value={profile.pilotType || ""}
                  onChange={handleChange}
                  select
                >
                  {ProfileModel.PILOT_TYPES.map((t) => (
                    <MenuItem key={t} value={t}>
                      {t}
                    </MenuItem>
                  ))}
                </TextField>

              </Grid>


            </Grid>
          </Grid>


          <Grid item xs={12} md={4}>
          </Grid>

        </Grid>
      </ProfileSectionCard>

      <ProfileSectionCard title="Aeronatical Experience" sx={{ mb: 2 }}>
        <Grid container spacing={2}>

          <Grid item xs={12} md={4}>
            {/* <TextField
              value={profile.soloDate?.split('T')[0] || ""}
              name="soloDate"
              onChange={handleChange}
              label="First Solo Date"
              fullWidth
              inputProps={{ type: 'date' }}
              InputLabelProps={{ shrink: true }}
            /> */}

            <DateField
              value={ profile.soloDate || ""}
              name="soloDate"
              onBlur={handleDateBlur}
              label="First Solo Date"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={4}>
            {/* <TextField
              value={profile.privatePilotDate?.split('T')[0] || ""}
              name="privatePilotDate"
              onChange={handleChange}
              label="Private Pilot Certificate Date"
              fullWidth
              inputProps={{ type: 'date' }}
              InputLabelProps={{ shrink: true }}
            /> */}
            <DateField
              value={ profile.privatePilotDate || ""}
              name="privatePilotDate"
              onBlur={handleDateBlur}
              label="Private Pilot Certificate Date"
              fullWidth
            />
          </Grid>


          <Grid item xs={12} md={4}>
            {/* <TextField
              value={profile.flightReviewDate?.split('T')[0] || ""}
              name="flightReviewDate"
              onChange={handleChange}
              label="Recent Flight Review or Checkride Date"
              fullWidth
              inputProps={{ type: 'date' }}
              InputLabelProps={{ shrink: true }}
            /> */}

            <DateField
              value={ profile.flightReviewDate || ""}
              name="flightReviewDate"
              onBlur={handleDateBlur}
              label="Recent Flight Review or Checkride Date"
              fullWidth
            />
          </Grid>



          <Grid item xs={12}>
            <Typography variant="caption" sx={{ display: 'block', mt: 3 }}>
              Ratings & Endorsements
            </Typography>
          </Grid>


          <Grid item xs={12} md={4}>
            <FormControlLabel
              label="Instrument Rating"
              name="hasInstrumentRating"
              control={<Checkbox checked={profile.hasInstrumentRating || false} onChange={handleChange} />}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <FormControlLabel
              label="Multi-Engine"
              name="hasMultiEngineEndorsement"
              control={<Checkbox checked={profile.hasMultiEngineEndorsement || false} onChange={handleChange} />}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <FormControlLabel
              label="High Performance"
              name="hasHighPerformanceEndorsement"
              control={<Checkbox checked={profile.hasHighPerformanceEndorsement || false} onChange={handleChange} />}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <FormControlLabel
              label="Complex Airplane"
              name="hasComplexEndorsement"
              control={<Checkbox checked={profile.hasComplexEndorsement || false} onChange={handleChange} />}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <FormControlLabel
              label="High Altitude"
              name="hasHighAltitudeEndorsement"
              control={<Checkbox checked={profile.hasHighAltitudeEndorsement || false} onChange={handleChange} />}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <FormControlLabel
              label="Tailwheel"
              name="hasTailwheelEndorsement"
              control={<Checkbox checked={profile.hasTailwheelEndorsement || false} onChange={handleChange} />}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <FormControlLabel
              label="Glider"
              name="hasGliderEndorsement"
              control={<Checkbox checked={profile.hasGliderEndorsement || false} onChange={handleChange} />}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="caption" sx={{ display: 'block', mt: 3 }}>
              Medical
            </Typography>
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              sx={{ mt: 0 }}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="medicalType"
              label="Medical Certificate Type"
              name="medicalType"
              value={profile.medicalType || ""}
              onChange={handleChange}
              select
            >
              {ProfileModel.MEDICAL_TYPES.map((t) => (
                <MenuItem key={t} value={t}>
                  {t}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} md={4}>
            {/* <TextField
              value={ profile.medicalDate || ""}
              name="medicalDate"
              onChange={handleChange}
              label="Medical Exam Date"
              fullWidth
              inputProps={{ type: 'date' }}
              InputLabelProps={{ shrink: true }}
            /> */}
            <DateField
              value={ profile.medicalDate || ""}
              name="medicalDate"
              onBlur={handleDateBlur}
              label="Medical Exam Date"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              sx={{ mt: 0 }}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="isMedicalDateOverForty"
              label="Age at Medical Exam"
              name="isMedicalDateOverForty"
              value={profile.isMedicalDateOverForty?.toString() || ""}
              onChange={handleChange}
              select
            >
              <MenuItem value="false">Under 40</MenuItem>
              <MenuItem value="true">Over 40</MenuItem>
            </TextField>
          </Grid>


        </Grid>
      </ProfileSectionCard>

      <Grid sx={{ mb: 6 }} container spacing={2}>
        {
          props.showCancel &&
          <Grid item xs={6}>
            <Button
              component={Link}
              to="/profile"
              variant="outlined"
              fullWidth
              startIcon={<ArrowBackIos />}
            >
              Profile
            </Button>
          </Grid>

        }

        <Grid item xs={props.showCancel ? 6 : 12}>
          <form onSubmit={handleSubmit} >
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              sx={{ boxShadow: 0 }}
              loading={isSaving}
            >
              Save
            </LoadingButton>
          </form>
        </Grid>
      </Grid>

    </Container >
  );

};


const splitDateStringPresent = (date) => {
  return date && date.indexOf('T') > -1 ? date.split('T')[0] : date;
}