import BaseModel from "Lib/BaseModel";

export default class AudioModel extends BaseModel {
  constructor(data) {
    super(data || {});

    this.metadata = BaseModel.camelizeKeys(this.metadata || {});
  }

}
