import { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/CheckCircle';
import TaggedTextField from 'TaggedText/TaggedTextField';
import './EditNotesModal.css';

export default function EditNotesModal({
  open = false,
  text = '',
  onSave = () => {},
  onClose = () => {},
  label = "Notes",
  placeholder = "Enter your notes..."
}) {
  const [_text, _setText] = useState(text || "");
  const [keyboardOffset, setKeyboardOffset] = useState(0);

  useEffect(() => {
    const { body } = document
    const html = body.parentElement;


    if (open) {
      _setText(text || '');
      html.style.position = 'fixed';
      html.style.overflow = 'hidden';
      html.style.scrollbarWidth = 'none';
      html.style.touchAction = 'none';
    } else {
      html.style.position = '';
      html.style.overflow = '';
      html.style.scrollbarWidth = 'none';
      html.style.touchAction = '';
    }

    const preventHtmlScroll = (e) => {
      e.preventDefault();
      if (open) {
        html.scrollTop = 0;
      }
    }


    const handleResize = () => {
      if (window.visualViewport) {
        const viewportHeight = window.visualViewport.height;
        const windowHeight = window.innerHeight;

        // Check if the keyboard is visible
        const isKeyboardVisible = viewportHeight < windowHeight;
        if (isKeyboardVisible) {
          const keyboardOffset = windowHeight - viewportHeight
          setKeyboardOffset(keyboardOffset); // Calculate keyboard height
          console.log("keyboardOffset", keyboardOffset)
        } else {
          setKeyboardOffset(0);
        }
      }
    };

    window.document.addEventListener("scroll", preventHtmlScroll);
    window.visualViewport?.addEventListener('resize', handleResize);

    return () => {
      window.document.removeEventListener("scroll", preventHtmlScroll);
      window.visualViewport?.removeEventListener('resize', handleResize);
    };



  }, [open]);

  if(!open) return null;

  return (
    <Modal
      open={open}
      onClose={onClose}
    >

      <Box
        sx={{
          maxWidth: 'sm',
          marginLeft: 'auto',
          marginRight: 'auto',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          bgcolor: 'background.paper',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          pb: `env(safe-area-inset-bottom)`,
        }}
      >
        {/* Header */}
        <Box
          sx={{
            zIndex: 1,
            display: 'flex',
            alignItems: 'center',
            px: 2,
            py: 1,
          }}
        >
          <Typography
            variant="caption"
            color="primary"
            sx={{ flex: 1, fontSize: '0.85rem' }}
          >
            {label}
          </Typography>
          <IconButton onClick={onClose}>
            <ClearIcon />
          </IconButton>
          <IconButton
            // disabled={text && !_text.trim()}
            onClick={() => {
              onSave(_text);
              onClose();
            }}
          >
            <CheckIcon color={!_text.trim() ? '' : 'primary'} />
          </IconButton>
        </Box>

        {/* TextArea */}
        <Box
          sx={{
            flex: 1,
            overflow: 'auto',
            px: 2,
            py: 1,
          }}
        >
          <TaggedTextField
            value={_text}
            className="EditNotesModal"
            label=""
            placeholder={placeholder}
            onChange={(data) => _setText(data.value)}
            autoFocus
            sx={{
              height: 'auto',
              backgroundColor: 'whitesmoke',
              minHeight: '90%',
              overflow: 'scroll',
              pb: keyboardOffset > 0 ? (keyboardOffset + 100) + 'px' : 4
            }}
          />
        </Box>
      </Box>
    </Modal>
  );
}


let longSampleText = `Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.`;
longSampleText = longSampleText.repeat(3);