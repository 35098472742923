import { useState } from 'react';
import { ListItem, ListItemAvatar, Avatar, Button, Typography, Menu, MenuItem, IconButton, Box } from '@mui/material';
import { MoreVert, ThumbUpAltOutlined, ThumbUpAlt, Reply } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import TaggedText from 'TaggedText/TaggedText';
import ProfileAvatar from 'Profile/ProfileAvatar';
import ListItemButton from '@mui/material/ListItemButton';



export default function NotificationItem({ notification, onClick }) {
  const [anchorEl, setAnchorEl] = useState(null);
  // const { onMarkAsRead } = useNotifications();
  console.log("notification", notification)


  return (
    <ListItemButton id={notification.id} key={notification.id}
      sx={{
        p: 1, pl: 1.5, pr: 1.5, display: 'flex', flexDirection: 'row',
        bgcolor: notification.isRead ? 'transparent' : 'lightyellow',
        borderBottom: '1px solid #e0e0e0',
       }}
      component={Link}
      to={notification.getLink()}
      onClick={onClick}
    >
      <ListItemAvatar sx={{ alignSelf: 'flex-start', flex: 0 }}>
        <ProfileAvatar profile={notification.profile} />
      </ListItemAvatar>

      <Box sx={{ flex: 1 }}>
        <Typography variant='body2' style={{ fontSize: '0.80em' }}>
          <span style={{ fontWeight: 'bold'}}>{notification.profile?.getFullName()}</span>
          {notification.getMessage()}
        </Typography>

        {/* Comment toolbar */}
        <div style={{ display: 'flex' }}>
          <Typography variant="caption" color="text.secondary"
            sx={{ p: 0.5, pl: 0, display: 'inline-block', alignContent: 'center', fontSize: '0.7rem'}}
          >
            {notification.relativeCreatedAt()}
          </Typography>

          {/* <Button
            sx={{ fontSize: '0.75rem'}}
            startIcon={comment.isLiked ? <ThumbUpAlt /> : <ThumbUpAltOutlined />}
            color={comment.isLiked ? 'primary' : 'secondary'}
            size="small"
            variant="text"
          >
            {comment.likesCount > 0 && comment.likesCount }
          </Button> */}

          {/* <Button
            sx={{ fontSize: '0.75rem' }}
            size="small"
            variant="text"
            color="secondary"
            onClick={onClickReply}
          >
            Reply
          </Button> */}

        </div>
      </Box>

      {/* {currentUserId && (

        <Box sx={{ flex: 0, alignSelf: 'flex-start' }}>
          <IconButton onClick={handleMenuOpen}>
            <MoreVert />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            {doesOwnComment && <MenuItem onClick={() => onDeleteComment(comment.id)}>Delete</MenuItem>}
            <MenuItem>Report</MenuItem>
          </Menu>
        </Box>
      )} */}
    </ListItemButton>

  )
}



const longCommentText = `It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.`