import axios from 'axios';
import CommentModel from 'Models/CommentModel';
import PostModel from 'Models/PostModel';
import FlightModel from 'Models/FlightModel';
import logger from 'Lib/logger';


export const getCommentsPage = (commentableTypeAndId, page, authHeaders) => {
  const perPage = 5;
  const offset = page * perPage;
  return axios.get(`/comments/${commentableTypeAndId.type}/${commentableTypeAndId.id}.json?offset=${offset}&limit=${perPage}`, authHeaders)
    .then(response => {
      const { data } = response;
      logger('getCommentsPage page', page, 'count', data.count);


      const comments = data.comments.map(comment => new CommentModel(comment));
      logger('comments', comments);

      const commentable = newCommentable(commentableTypeAndId.type, data.commentable);
      logger('commentable', commentable);

      return { comments, commentable, count: data.count };
    });
}


export const createOrUpdateComment = (commentData, authHeaders) => {
  logger("createOrUpdateComment", commentData);

  let url = `/comments/${commentData.commentableType}/${commentData.commentableId}.json`;

  return axios.post(
    url, {
    comment: new CommentModel(commentData).asJSON(['profile']),
  },
    authHeaders
  )
    .then(response => {
      const { data } = response;

      return {
        comment: new CommentModel(data.comment),
        commentable: newCommentable(commentData.commentableType, data.commentable),
      };
    });
}


const newCommentable = (commentableType, commentableData) => {
  const modelMap = {
    post: PostModel,
    flight: FlightModel,
    comment: CommentModel,
  }

  const Model = modelMap[commentableType];
  return new Model(commentableData);
}


export const deleteComment = (commentableTypeAndId, commentId, authHeaders) => {
  return axios.delete(`/comments/${commentableTypeAndId.type}/${commentableTypeAndId.id}/${commentId}.json`, authHeaders)
    .then(response => {
      const { data } = response;
      return {
        commentable: newCommentable(commentableTypeAndId.type, data.commentable),
      }
    });
}
