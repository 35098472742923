import React, { useEffect, useState } from 'react';
import L from "leaflet";
import airplaneIcon from './airplaneIcon';
import { useFlight } from 'Flights/FlightProvider';
import brushingBus from 'BrushingBus/BrushingBus';



export default function AirplaneBrush({ isLoaded , mapRef }) {
  const [airplaneMarker, _] = useState(L.marker([0, 0], { icon: airplaneIcon }));

  useEffect(() => {

    if (document.querySelectorAll('.airplane-icon').length === 0) {
      airplaneMarker.addTo(mapRef.current);
    }

    const onBrushUpdate = (event) => {
      const { detail } = event;
      setBrushPosition(airplaneMarker, detail.point);
    };

    brushingBus.addEventListener('brush-update', onBrushUpdate);

    return () => {
      brushingBus.removeEventListener('brush-update', onBrushUpdate);
    };


  }, [mapRef.current, isLoaded]);

  if (!isLoaded) return null;


  return null;
}



function setBrushPosition(marker, point) {
  if (!marker || !point) return;
  marker.setLatLng([point.lat, point.lng]);
  const markerElement = marker.getElement();
  if (markerElement) {
    markerElement.style.transform = markerElement.style.transform + ` rotate(${point.bearing}deg)`
  }
}
