import { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/CheckCircle';

export default function EditTitleModal({ open = false, text, onSave=() => {}, onClose = () => {}, onUpdateFlight }) {
  const [_text, _setText] = useState(text);

  useEffect(() => {
    if (open) {
      _setText(text);
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Box sx={rootSx}>
        <TextField
          value={_text}
          label="Flight Name"
          placeholder={ text }
          variant="outlined"
          onChange={(e) => { _setText(e.target.value) }}
          fullWidth
          autoFocus
          sx={{ mt: 1, mb: 1 }}
        />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
          <IconButton variant="text" size="small" onClick={onClose}>
            <ClearIcon/>
          </IconButton>
          <IconButton
            variant="contained"
            size="small"
            sx={{boxShadow: 'none'}}
            onClick={() => {
              onSave(_text);
              onClose();
            }}
            color={_text.trim().length > 0 ? 'primary' : 'default'}
            disabled={!_text}>
              <CheckIcon/>
            </IconButton>
        </Box>
      </Box>
    </Modal>

  );
}

const rootSx = {
  p: 2,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 350,
  bgcolor: 'background.paper',
  boxShadow: 24,
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 1
};
