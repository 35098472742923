import { useState } from 'react';
import { ListItem, ListItemAvatar, Avatar, Typography, Box, Chip, Button, ListItemSecondaryAction, Tooltip, IconButton, Menu, MenuItem, Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import { CheckCircleOutline, Clear, MoreVert, NotInterested } from '@mui/icons-material'
import UserPairModel from 'Models/UserPairModel';
import ProfileAvatar from 'Profile/ProfileAvatar';
import ProfileName from 'Profile/ProfileName';

export default function UserPairList({ userPair, onUpdateUserPair, onDeleteUserPair }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <ListItem
      id={userPair.id}
      key={userPair.id}
      sx={{ p: 1, pl: 1.5, pr: 1.5, display: 'flex', flexDirection: 'row', borderBottom: '1px solid #f1f1f1' }}
    >
      <ListItemAvatar sx={{ alignSelf: 'flex-start', flex: 0 }}>
        <ProfileAvatar profile={userPair.notCurrentProfileUser} />
      </ListItemAvatar>

      <Box sx={{ flex: 1 }}>
        <ProfileName profile={userPair.notCurrentProfileUser} />

        {
          true && (
            <ListItemSecondaryAction>

              {
                (userPair.isAwaitingAcceptance() || userPair.isMuted()) && (
                  <Tooltip title="Accept">
                    <IconButton onClick={() => onUpdateUserPair(userPair.id, UserPairModel.STATUS_PAIRED)}>
                      <CheckCircleOutline />
                    </IconButton>
                  </Tooltip>
                )
              }


              {
                userPair.isAwaitingAcceptance() && (
                  <Tooltip title="Mute">
                    <IconButton onClick={() => onUpdateUserPair(userPair.id, UserPairModel.STATUS_MUTED)}>
                      <NotInterested />
                    </IconButton>
                  </Tooltip>
                )
              }

              {
                userPair.isSent() && (
                  <Tooltip title="Cancel">
                    <IconButton onClick={() => onDeleteUserPair(userPair.id)}>
                      <Clear />
                    </IconButton>
                  </Tooltip>
                )
              }

              {
                userPair.isMyProfile() && userPair.isPaired() && (
                  <>
                    <IconButton onClick={handleOpen}>
                      <MoreVert />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={() => onDeleteUserPair(userPair.id)}>Remove</MenuItem>
                    </Menu>
                  </>
                )
              }

            </ListItemSecondaryAction>
          )
        }


        {/* Comment toolbar */}
        <div style={{ display: 'flex' }}>
          <Typography variant="caption" color="text.secondary" sx={{ p: 0.5, pl: 0, display: 'inline-block', alignContent: 'center', fontSize: '0.6rem' }}>
            {userPair.relativeUpdatedAt()}
          </Typography>
        </div>
      </Box>
    </ListItem>

  )
}
