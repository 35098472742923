import { ListItemButton, ListItemText, Chip, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';

export default function AirportOption({ airport, onClick = () => {} }) {
  return (
    <ListItemButton
      key={airport.id}
      component={Link}
      to={`/explore/airports/${airport.getId()}`}
      onClick={onClick}
    >
      <ListItemText
        primary={<span style={{ fontWeight: 'bold', display: 'inline-block', marginBottom: 4 }}>
          {/* {airport.getId() + ': ' + airport.getDisplayName()} */}
          {airport.getDisplayName()}
        </span>}
        secondary={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Chip label={airport.getId()} size="small" sx={{
              mr: 1,
              borderRadius: 1, }}
            />
            <Typography className="NoWrap" variant="caption" sx={{}}>{airport.city}, {airport.stateName}</Typography>
          </Box>
        }
      />
    </ListItemButton>
  );
}
