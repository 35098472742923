import { Modal, Box, Typography, LinearProgress, IconButton, Divider, List, Button, ListItem } from '@mui/material';
import { Close } from '@mui/icons-material';
import LikeListItem from './LikeListItem';
import TaggedText from 'TaggedText/TaggedText';
import { MoreVert } from '@mui/icons-material';
import ProfileAvatar from 'Profile/ProfileAvatar';
import logger from 'Lib/logger';



export default function LikeModal({ open, onClose, likeable, likes, count, onLoadMore }) {
  if (!open) return null;

  if (!likeable) return <LinearProgress />;

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="post-modal-title"
      slotProps={{ backdrop: { style: { backgroundColor: 'rgba(0, 0, 0, 0.8)' } } }}
    >
      <Box sx={style}>

        <Box sx={headerStyle}>

          <Box sx={{ alignSelf: 'flex-start', pt: 1 }}>
            <ProfileAvatar profile={likeable.profile} />
          </Box>

          <Box>
            <Typography variant="body2" sx={{ fontFamily: 'monospace' }}>
              <span style={{ fontWeight: 'bold', fontFamily: 'sans-serif' }}>{likeable.profile?.getFullName()}</span>
              &nbsp;
              {
                likeable.mentions ?
                  <TaggedText value={likeable.getCommentableCaption()} mentions={likeable.mentions} />
                  :
                  <span>{likeable.caption}</span>
              }
            </Typography>
            <Typography variant="caption" color="text.secondary">{likeable.relativeCreatedAt()}</Typography>
          </Box>

          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </Box>


        <Divider />

        <Box style={likesListStyle}>


          <List dense>
            {
              likes.map((l, i) => (
                <LikeListItem
                  key={l.id + '-' + i}
                  like={l}
                />
              ))}

            {
              count > likes.length &&
              <ListItem sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <MoreVert sx={{ color: '#999', pb: 1 }} />
                <Button
                  size="small"
                  color="secondary"
                  onClick={onLoadMore}
                  variant="text"
                  sx={{
                    m: 0,
                    textTransform: 'none',
                    backgroundColor: 'rgba(0, 0, 0, 0.05)',
                    borderRadius: '12px',
                    fontWeight: 'bold',
                    fontSize: '0.65rem',
                    pl: 1.5,
                    pr: 1.5,
                  }}
                >
                  {count - likes.length} more likes
                </Button>

              </ListItem>
            }
          </List>

          {
            likes.length === 0 &&
            <Typography variant="caption" color="text.secondary" sx={{ p: 1, textAlign: 'center', display: 'block' }}>No likes yet.</Typography>
          }
        </Box>


      </Box>

    </Modal>
  );
}




const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 350,
  bgcolor: 'background.paper',
  boxShadow: 24,
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 2,
};


const headerStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: 2,
  mb: 1,
  flex: 0,
  p: 1,
  pl: 1.5,
  pr: 1.5
};

const likesListStyle = {
  flex: 1,
  overflow: 'auto'
};