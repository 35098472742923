import { useState } from 'react';
import { ListItem, ListItemAvatar, Avatar, Button, Typography, Menu, MenuItem, IconButton, Box } from '@mui/material';
import { MoreVert, ThumbUpAltOutlined, ThumbUpAlt, Reply } from '@mui/icons-material';
import { useComments } from './CommentProvider';
import { Link } from 'react-router-dom';
import TaggedText from 'TaggedText/TaggedText';
import ProfileAvatar from 'Profile/ProfileAvatar';



export default function CommentListItem({ comment, currentUserId, onClickReply }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { onDeleteComment } = useComments();

  const doesOwnComment = comment.userId === currentUserId;

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <ListItem id={comment.id} key={comment.id} sx={{ p: 1, pl: 1.5, pr: 1.5, display: 'flex', flexDirection: 'row' }}>
      <ListItemAvatar sx={{ alignSelf: 'flex-start', flex: 0 }}>
        <ProfileAvatar profile={comment.profile} />
      </ListItemAvatar>

      <Box sx={{ flex: 1 }}>
        {/* Comment text */}
        <Typography variant='body2' style={{ fontSize: '0.80em',fontFamily: 'monospace' }}>
          <span style={{ fontWeight: 'bold', fontFamily: 'sans-serif' }}>{comment.profile?.getFullName()}</span>
          &nbsp;
          <TaggedText value={comment.content} mentions={comment.mentions} />
        </Typography>

        {/* Comment toolbar */}
        <div style={{ display: 'flex' }}>
          <Typography variant="caption" color="text.secondary"
            sx={{ p: 0.5, pl: 0, display: 'inline-block', alignContent: 'center' }}
          >
            {comment.relativeCreatedAt()}
          </Typography>

          {/* <Button
            sx={{ fontSize: '0.75rem'}}
            startIcon={comment.isLiked ? <ThumbUpAlt /> : <ThumbUpAltOutlined />}
            color={comment.isLiked ? 'primary' : 'secondary'}
            size="small"
            variant="text"
          >
            {comment.likesCount > 0 && comment.likesCount }
          </Button> */}

          {/* <Button
            sx={{ fontSize: '0.75rem' }}
            size="small"
            variant="text"
            color="secondary"
            onClick={onClickReply}
          >
            Reply
          </Button> */}

        </div>
      </Box>

      {currentUserId && (

        <Box sx={{ flex: 0, alignSelf: 'flex-start' }}>
          <IconButton onClick={handleMenuOpen}>
            <MoreVert />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            {doesOwnComment && <MenuItem onClick={() => onDeleteComment(comment.id)}>Delete</MenuItem>}
            <MenuItem>Report</MenuItem>
          </Menu>
        </Box>
      )}
    </ListItem>

  )
}



const longCommentText = `It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.`