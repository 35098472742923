import { useState, useEffect } from "react";
import { Box, Skeleton, Typography, Button, Chip, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";
import { Container } from "@mui/material";
import WjCard from "Lib/WjCard";
import UserPairList from "UserPairs/UserPairList";



function ProfileFlyingWith({ isCurrentUser, profile }) {

  const [fetchedAt, setFetchedAt] = useState(Date.now());


  return (<>

    <Container maxWidth="xs">


      {
        isCurrentUser && (
          <PendingMutedSend onUpdate={() => setFetchedAt(Date.now())} profile={profile} />
        )}

      {/* TODO: Add search */}
      {/* <TextField
        size="small"
        margin="dense"
        variant="outlined"
        fullWidth
        sx={{
          '.MuiTextField-root': {
            border: 'none',
            outline: 'none'
          },
          bgcolor: 'white',
          mb: 2,
          outline: 'none'
        }}


        InputProps={{
          startAdornment: <Search />,
          sx: { color: '0.8rem', outline: 'none', border: 'none' },
        }}
        inputProps={{
        }}
      /> */}


      <WjCard>
        <UserPairList fetchedAt={fetchedAt} profile={profile} />
      </WjCard>

    </Container>


  </>
  );
}

export default ProfileFlyingWith;






function PendingMutedSend({ profile, onUpdate }) {
  const [status, setStatus] = useState("pending");

  const baseStyles = {
    textTransform: 'none',
    fontSize: '10px',
    mr: 1,
  };

  const activeStyles = {
    ...baseStyles,
    backgroundColor: '#f1f1f1',
    fontWeight: 'bold',
    '&:active': { backgroundColor: '#f1f1f1'},
    '&:focus': { backgroundColor: '#f1f1f1'}
  };

  const inactiveStyles = {
    ...baseStyles,
    color: '#777',
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>

        {
          ['pending', 'muted', 'sent'].map(s => (
            <Button
              variant="text"
              size="small"
              sx={status === s ? activeStyles : inactiveStyles}
              color={status === s ? 'primary' : undefined}
              onClick={() => setStatus(s)}
            >
              {s.charAt(0).toUpperCase() + s.slice(1)}
            </Button>
          ))}

      </Box>

      <WjCard sx={{ mb: 3 }}>
        <UserPairList onUpdate={onUpdate} status={status} profile={profile} />
      </WjCard>
    </>

  );
}