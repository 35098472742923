import { DateTime } from 'luxon';
import { toCamelCase, toSnakeCase } from "./convertCase";

export default class BaseModel {
  constructor(data){
    for (const key in data) {
      const camelCaseKey = toCamelCase(key);
      this[camelCaseKey] = data[key];

    }
  }

  static camelizeKeys(object, excludeKeys = []) {
    const camelCaseData = {};
    for (const key in object) {
      if (object.hasOwnProperty(key) && !excludeKeys.includes(key)) {
        const camelCaseKey = toCamelCase(key);
        camelCaseData[camelCaseKey] = object[key];
      }
    }
    return camelCaseData;
  }

  static asJSON(object, excludeKeys = []) {
    const snakeCaseData = {};
    for (const key in object) {
      if (object.hasOwnProperty(key) && !excludeKeys.includes(key)) {
        const snakeCaseKey = toSnakeCase(key);
        snakeCaseData[snakeCaseKey] = object[key];
      }
    }
    return snakeCaseData;
  }

  asJSON(excludeKeys = []) {
    const snakeCaseData = {};
    for (const key in this) {
      if (this.hasOwnProperty(key) && !excludeKeys.includes(key)) {
        const snakeCaseKey = toSnakeCase(key);
        snakeCaseData[snakeCaseKey] = this[key];
      }
    }
    return snakeCaseData;
  }


  relativeCreatedAt() {
    const parsedDate = DateTime.fromISO(this.createdAt); // Replace with `fromFormat` if needed
    return  parsedDate.toRelative();
  }


  relativeUpdatedAt() {
    const parsedDate = DateTime.fromISO(this.updatedAt); // Replace with `fromFormat` if needed
    return  parsedDate.toRelative();
  }


}