// events.js

const brushingBus = new EventTarget();



export const dispatchBrushUpdate = (detail) => {
  brushingBus.dispatchEvent(new CustomEvent('brush-update', { detail }) );
}




export default brushingBus;