import { useProfile } from 'Profile/MyProfileProvider';
import Profile from './Profile';
import { LinearProgress } from '@mui/material';
import withAuth from "../Auth/withAuth";

const MyProfile = () => {
  const { isLoadingProfile, profile, flightStats } = useProfile();


  return (
    isLoadingProfile ? <LinearProgress /> :
    <Profile profile={profile} isLoadingProfile={isLoadingProfile} flightStats={flightStats} />
  )

}

export default withAuth(MyProfile);
