import { useEffect, useState } from 'react';
import { Box, LinearProgress, Typography, Divider, Chip, Button, Skeleton, Stack, Paper } from '@mui/material';
import { useAuth } from 'Auth/AuthProvider';
import { useNavigate, useParams } from 'react-router-dom';
import logger from 'Lib/logger';
import { formatDate, formatTime, formatHours, formatCount } from 'Lib/format';
import { getTrace, getTracePath } from './TracesApi';
// import { useFlightMap } from 'FlightMap/FlightMap';
import { useFlightMap } from "FlightMap/FlightMapProvider";
import CardHeader from 'FlightMap/Shared/CardHeader';
import TraceChart from './TraceChart';
import { Link } from 'react-router-dom';


/*
  This component should do a few things. It should show the details of the trace / trace.
  If the user is not logged in or the flight isn't logged, it should show the trace details.
  These details may be copied to the flight itself
  If the user is logged in - it should show the details of the flight if it is logged.
*/


export default function TraceDetail(props) {
  const params = useParams();
  const navigate = useNavigate();

  const { authHeaders, isSignedIn } = useAuth();
  const { setMapTraces } = useFlightMap();

  const [loading, setLoading] = useState(true);
  const [trace, setTrace] = useState(null);
  const [flight, setFlight] = useState(null);
  const [tracePath, setTracePath] = useState(null);

  useEffect(() => {
    console.log('traceUid:', params.traceUid);
    setLoading(true);

    getTrace(params.traceUid, authHeaders)
      .then(trace => {
        if (!trace) {
          logger('Error loading trace!');
          return;
        }

        setTrace(trace);
        setMapTraces([trace]);

        return getTracePath(trace);
      })
      .then(tracePath => {
        setTracePath(tracePath);
        setLoading(false);
      });
  }, [params.traceUid]);



  const onClick = e => {

    if (!isSignedIn) {
      return navigate('/sign-up', { state: { from: `/flights/new?trace-uid=${trace.traceUid}` }});
    }

    return navigate(`/flights/new?trace-uid=${trace.traceUid}`);
  }

  const isLogged = flight && flight.id;
  const tailNumber = params.traceUid.split('-')[0];

  return (
    <Paper>
      <CardHeader
        onClickBack={() => navigate(-1)}
        toolbar={
          <>
            {/* {
              flight && flight.traces.length === 0 &&
              <IconButton onClick={() => navigate(`/flights/${params.flightId}/traces`)}>
                <Route />
              </IconButton>pram
                 <IconButton color="primary" onClick={() => navigate(`/flights/${params.flightId}/edit`)}>
              <NoteAdd />
            </IconButton>
            }
               */}

          </>
        }
      >
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
          <div style={{ flex: 1 }}>
            <Chip
              label={tailNumber}
              size="large"
              sx={{
                borderBottomLeftRadius: 0,
                borderTopRightRadius: 0,
                fontWeight: 'bold',
                fontSize: 18,
                margin: '4px 8px'
              }}
            />
          </div>
        </Box>
      </CardHeader>
      {
        loading ? <>
          <LinearProgress />
          <Box sx={{ p: 1 }}>
            <Stack spacing={2}>
              {
                [1, 2, 3, 4, 5, 6].map(i => (
                  <Skeleton key={i} variant="rectangular" width="100%" height={60} />
                ))
              }
            </Stack>
          </Box>
        </>
          : <Divider />
      }
      {
        !loading &&
        <>
          <Box sx={{ p: 1 }}>
            <Typography component="div" variant="body1" sx={{ fontWeight: 'bold', display: 'flex', m: 0 }}>
              {formatDate(trace.takeoffT)}
            </Typography>
          </Box>

          <Box sx={{ p: 1, display: 'flex' }}>
            <div style={{ flex: 1 }}>
              <Typography sx={{ fontSize: '0.65rem' }} variant="caption">Distance</Typography><br />
              {formatCount(trace.getNauticalMiles())}nm
            </div>
            <div style={{ flex: 1 }}>
              <Typography sx={{ fontSize: '0.65rem' }} variant="caption">Duration</Typography><br />
              {formatHours(trace.getDurationHours())}h
            </div>
            <div style={{ flex: 1 }}>
              <Typography sx={{ fontSize: '0.65rem' }} variant="caption">Type</Typography><br />
              {trace.type}
            </div>
          </Box>

          <Box sx={{ p: 1 }}>
            <Typography sx={{ fontSize: '0.65rem' }} variant="caption">First Seen</Typography><br />
            {formatTime(trace.takeoffT)} near <Chip size="small" label={trace.getAirport('departure')} />
          </Box>

          <Box sx={{ p: 1, pt: 0, pb: 2 }}>
            <Typography sx={{ fontSize: '0.65rem' }} variant="caption">Last Seen</Typography><br />
            {/* */}
            {formatTime(trace.landingT)} near <Chip size="small" label={trace.getAirport('destination')} />
          </Box>

          <Box sx={{ pl: 1, pr: 1 }}>
            <TraceChart tracePath={tracePath} />
          </Box >

          <Box sx={{ p: 1 }}>
            {
              trace.flightId ? (
                <Button component={Link} to={`/flights/${trace.registrationNumber}-${trace.flightId}`} color="primary" fullWidth variant="outlined">
                  View Flight
                </Button>
              )
                :
                <Button onClick={onClick} color="primary" fullWidth variant="contained">
                  Log Flight
                </Button>
            }

          </Box>
        </>
      }
    </Paper>

  )
}
