import React from "react";
import { Box, ListItem, ListItemButton, List, Typography, ListItemText, CircularProgress, Divider, Chip, Avatar, ListItemAvatar } from "@mui/material";
import * as d3 from "d3";
import { Link } from "react-router-dom";
import { formatDate, formatHours, formatTime } from "Lib/format";
import ImgRenditions from "Lib/ImgRenditions";
import VideoRenditions from "Lib/VideoRenditions";

export default function FlightList(props) {
  const { flights } = props;




  if (props.loading) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', padding: 12, height: '100%', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress size={24} />
        <Typography variant="caption">Loading...</Typography>
      </div>
    )
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ flex: 1, width: '100%', overflowY: 'scroll' }}>
        <List sx={{ pt: 0 }}>

          {
            flights.map((flight, i) => {
              const airports = flight.departureAndArrivalAirports || [];
              const departureDate = formatDate(flight.getDepartureUTC());

              const highlightFlight = () => {
                d3.selectAll(`.flight-curve:not(.t-${flight.id})`).style('opacity', 0.3);
                d3.selectAll(`.flight-${flight.id}`).style('opacity', 1);
              }

              const unhighlightFlight = () => {
                d3.selectAll(`.flight-curve`).style('opacity', 1);
              }

              return (
                <React.Fragment key={i}>
                  <ListItemButton
                    key={flight.id}
                    component={Link}
                    to={`/flights/${flight.id}`}
                    dense
                    onMouseEnter={highlightFlight}
                    onTouchStart={highlightFlight}
                    onMouseLeave={unhighlightFlight}
                    onTouchEnd={unhighlightFlight}
                  >


                    <ListItemText
                      disableTypography
                    >

                      <Box sx={{ display: 'flex', mb: 1 }}>
                        {
                          props.showUser &&
                            <Avatar src={flight.profile.profilePhotoUrl} sx={{ border: '1px solid #ddd', width: 24, height: 24, mr: 1 }} size={24} />
                        }

                        <Typography variant="body2">
                          {props.showUser ? flight.profile.getFullName() : flight.title}
                          <br />
                          <span style={{ fontSize: '0.75rem', color: '#777', fontWeight: '500' }}>
                            {props.showUser ? flight.title : departureDate + ' ' + formatTime(flight.getDepartureUTC())}
                          </span>
                        </Typography>

                        <div style={{ textAlign: 'right', flex: 1 }}>
                          <Chip
                            label={flight.registrationNumber} size="small"
                            sx={{
                              fontSize: 11,
                              borderBottomLeftRadius: 0,
                              borderTopRightRadius: 0,
                            }}
                          />
                        </div>
                      </Box>

                      <Typography variant="caption" component="div" style={{ display: 'flex' }}>
                        {/* <div style={{ flex: 1 }}>

                          {airports.map((a, i) => <Chip key={i} label={a} size="small" sx={{ mr: '4px', fontSize: '0.55rem', p: 0, height: 18 }} />)}
                        </div>

                        <div style={{ textAlign: 'right' }}>
                          {flight.logbookEntry?.totalHours}h
                        </div> */}
                      </Typography>


                      <Box sx={{ mt: 1 }} style={{ overflowX: 'scroll', flex: 1, display: 'flex' }}>
                        <Box style={{}}>
                          <div style={{ flex: 1, display: 'flex' }}>
                            {
                              flight.visualUrls.map(v => (
                                <>
                                  {v.isImage() && <ImgRenditions visual={v} thumbnail thumbnailSize={36} style={{ marginRight: 5, flex: 1, borderRadius: 4 }} />}
                                  {v.isVideo() && <VideoRenditions visual={v} thumbnail thumbnailSize={36} hideDuration style={{ marginRight: 5, flex: 1, borderRadius: 4 }} />}
                                </>

                              ))
                            }
                          </div>
                        </Box>
                      </Box>

                    </ListItemText>
                  </ListItemButton>
                  <Divider />

                </React.Fragment>
              )
            })
          }
        </List>
      </div>
    </div>
  )

}

