
import BaseModel from "Lib/BaseModel";
import ProfileModel from "Models/ProfileModel";

export default class LikeModel extends BaseModel {
  constructor(data) {
    super(data || {});

    this.profile = new ProfileModel(this.profile || {});
  }

}


