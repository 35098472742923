import { useState, useEffect } from 'react';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { IconButton, Box, Typography, Skeleton } from '@mui/material';
import { debounce } from 'lodash';
import { makeStyles } from '@mui/styles';
import ImgRenditions from 'Lib/ImgRenditions';
import VideoRenditions from 'Lib/VideoRenditions';


export default function VisualGallery({ visuals, initialIndex, goToIndex=false, sx = {} }) {
  const classes = useStyles();

  const [currentIndex, setCurrentIndex] = useState(initialIndex);

  const handleChangeIndex = debounce((index) => {
    setCurrentIndex(index);
  }, 100); // Adjust debounce timing as needed

  // useEffect(() => {
  //   setCurrentIndex(initialIndex);
  //   }
  // }, [initialIndex]);


  useEffect(() => {
    // When the visuals array is updated, set the current index to the last visual
    if(goToIndex && visuals.length > 0) {
      setCurrentIndex(visuals.length - 1);
    }
  }, [initialIndex, visuals]);



  return (
    <Box className="visual-gallery" sx={{ height: '100%', position: 'relative', overflow: 'hidden', ...sx }}>
      {/* Swipeable carousel using react-swipeable-views */}
      <SwipeableViews
        index={currentIndex}
        onChangeIndex={handleChangeIndex}
        enableMouseEvents
        style={{ height: '100%' }}
        containerStyle={{ height: '100%' }}
      >
        {visuals.map((visual, index) => (
          <div key={index} className={classes.carouselContainer}>
            {isImage(visual) &&
              <ImgRenditions style={{ maxHeight: window.visualViewport.height * 0.9 }} visual={visual} className={classes.image} />
            }
            {isVideo(visual) &&
              <VideoRenditions style={{ maxHeight: window.visualViewport.height * 0.9 }} visual={visual} className={classes.image} />
            }
          </div>
        ))}



      </SwipeableViews>

      {/* Navigation Arrows */}

      {
        visuals.length > 1 && (
          <div className={classes.navigationIcons}>
            <IconButton
              onClick={() => handleChangeIndex(currentIndex - 1)}
              style={{ visibility: currentIndex === 0 ? 'hidden' : 'visible' }}
              className={classes.arrowButton}
            >
              <ArrowBack />
            </IconButton>
            <IconButton
              onClick={() => handleChangeIndex(currentIndex + 1)}
              style={{ visibility: currentIndex === visuals.length - 1 ? 'hidden' : 'visible' }}
              className={classes.arrowButton}
            >
              <ArrowForward />
            </IconButton>
          </div>

        )
      }

      {/* Dot Indicators */}
      <div className={classes.dotsContainer}>
        { visuals.length > 1 && visuals.map((_, index) => (
          <div
            key={index}
            className={`${classes.dot} ${index === currentIndex ? 'active' : ''}`}
          />
        ))}
      </div>
    </Box>
  );
}

const isImage = (visual) => {
  // React renames the constructors
  if (visual.isImage) {
    return visual.isImage();
  } else if (visual.constructor.name === 'File') {
    return visual.type.includes('image');
  } else {
    throw new Error('Invalid visual type');
  }
}

const isVideo = (visual) => {
  // React renames the constructors
  if (visual.isVideo) {
    return visual.isVideo();
  } else if (visual.constructor.name === 'File') {
    return visual.type.includes('video');
  } else {
    throw new Error('Invalid visual type');
  }
}



const useStyles = makeStyles(() => ({
  carouselContainer: {
    width: '100%',
    height: '100%', // Limit the height of the image container
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'black',
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
    height: 'auto',
    objectFit: 'contain', // Ensure the image scales to fit within container
  },
  navigationIcons: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '0 16px',
    pointerEvents: 'none',
  },
  arrowButton: {
    pointerEvents: 'auto',
    color: 'rgba(255, 255, 255, 0.5)',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
  },
  dotsContainer: {
    position: 'absolute',
    bottom: '24px',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  dot: {
    height: '7px',
    width: '7px',
    borderRadius: '50%',
    backgroundColor: 'whitesmoke',
    opacity: 0.6,
    margin: 4,
    '&.active': {
      backgroundColor: 'white',
      opacity: 1,
    },
  },
}));

