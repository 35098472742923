import { useState, useEffect } from "react";
import { Box, Skeleton, Typography } from "@mui/material";
import { getUserPairs, updateUserPair, deleteUserPair } from "UserPairs/UserPairsApi";
import { useAuth } from "Auth/AuthProvider";
import UserPairListItem from "UserPairs/UserPairListItem";

export default function UserPairList({ profile, status=null, perPage = 5, fetchedAt=null, onUpdate=null }) {
  const { currentUser, authHeaders } = useAuth();

  const isCurrentUser = profile.userId === currentUser?.id;

  const currentUserId = currentUser?.id;
  const profileUserId = profile.userId;


  const baseParams = {
    currentUserId,
    profileUserId,
    perPage,
    status,
  };

  if (status) {
    baseParams.status = status;
  }


  const [isLoading, setIsLoading] = useState(true);
  const [userPairs, setUserPairs] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);


  const onUpdateUserPair = (userPairId, status) => {
    updateUserPair({ userPairId, status }, authHeaders)
      .then(() => {
        const newUserPairs = userPairs.filter(p => p.id !== userPairId);
        setUserPairs(newUserPairs);

        if(onUpdate){
          onUpdate();
        }
      });
  }

  const onDeleteUserPair = (userPairId, status) => {
    deleteUserPair({ userPairId, status }, authHeaders)
      .then(() => {
        const newUserPairs = userPairs.filter(p => p.id !== userPairId);
        setUserPairs(newUserPairs);
        if( onUpdate ){
          onUpdate();
        }
      });
  }


  // Paried user pairs
  useEffect(() => {
    const params = { ...baseParams, limit: perPage, offset: page * perPage};

    getUserPairs(params, authHeaders)
      .then(data => {
        setUserPairs(data.userPairs);
        setCount(data.count);
      })
      .finally(() => setIsLoading(false));

  }, [fetchedAt, status, page]);



  return (
    <>
    {
      !isLoading && userPairs.length === 0 && (
        <Box sx={{ mt: 2, mb: 2, textAlign: 'center'}}>
          <Typography variant="caption" color="text.secondary" sx={{fontSize: '0.7rem' }}>Nothing here</Typography>
        </Box>
      )
    }

    {
      userPairs.map(p => (
        <UserPairListItem
          key={p.id}
          isCurrentUser={isCurrentUser}
          userPair={p}
          onUpdateUserPair={onUpdateUserPair}
          onDeleteUserPair={onDeleteUserPair}
      />
      ))
    }
    </>
  )



}

