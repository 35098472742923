import React, { useEffect, useState, useRef, useContext } from 'react';
import TraceList from "../Shared/TraceList";
import { useAuth } from 'Auth/AuthProvider';
import { useParams, useSearchParams } from 'react-router-dom';
import { searchAirportTraces, searchAircraftTraces } from './SearchTracesApi';
import Pagination from '@mui/material/Pagination';
import { useFlightMap } from "FlightMap/FlightMapProvider";
import { LinearProgress, Skeleton, Box, Stack, Card, Typography } from '@mui/material';
import CardHeader from 'FlightMap/Shared/CardHeader';
import StickyHeader from 'FlightMap/Shared/Sticky';
import NothingFoundBox from 'FlightMap/Shared/NothingFoundBox';
import { AirplanemodeInactive } from '@mui/icons-material';
import { Paper } from '@mui/material';
import AirportCardHeader from './AirportCardHeader';
import AircraftCardHeader from './AircraftCardHeader';

function SearchTraces() {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();


  const limit = 10;

  const [loading, setLoading] = useState(false);
  const [traces, setTraces] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(() => {
    const pageParam = searchParams.get('page');
    return pageParam ? parseInt(pageParam) - 1 : 0;
  });

  const { authHeaders } = useAuth();
  const { setMapTraces, setMapFlights } = useFlightMap();


  const isAirport = window.location.pathname.includes('/airports');
  const isAircraft = window.location.pathname.includes('/traces');

  useEffect(() => {
    if (params.query === undefined) return;

    // Set the page from the search params
    const page = searchParams.get('page') ? +searchParams.get('page') - 1 : 0;
    setPage(page);


    // Clear any flights from the map
    setMapFlights([]);
    setLoading(true);
    // Scroll to the top of the map card
    window.document.getElementById('MapCardScroll').scrollTop = 0;



    if (isAirport) {

      searchAirportTraces(
        params.query,
        { limit, offset: page * limit },
        authHeaders
      )
        .then(data => {
          const { count, traces } = data;
          setTraces(traces);
          setCount(count);

          setLoading(false);

          setMapTraces(traces);
        });
    } else {

      searchAircraftTraces(
        params.query,
        { limit, offset: page * limit },
        authHeaders
      )
        .then(data => {
          const { count, traces } = data;
          setTraces(traces);
          setCount(count);
          setLoading(false);

          setMapTraces(traces);
        });
    }

  }, [params.query, page, searchParams]);


  return (
    <Paper sx={{ minHeight: '100%', display: 'flex', flexDirection: 'column', pt: 0, pb: 0, borderRadius: 0 }}>
      <StickyHeader>
        {
          isAirport && (
            <AirportCardHeader airportId={params.query} />
          )
        }

        {
          isAircraft && (
            <AircraftCardHeader registrationNumber={params.query} />
          )
        }
      </StickyHeader>



      {
        loading && <>
          <LinearProgress />
          <Box sx={{ p: 1 }}>
            <Stack spacing={2}>
              {
                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => (
                  <Skeleton key={i} variant="rectangular" width="100%" height={90} />
                ))
              }
            </Stack>
          </Box>
        </>
      }
      {
        !loading && traces.length === 0 && (
          <NothingFoundBox>
            <>
              <AirplanemodeInactive />
              <p>No flights found.</p>
            </>
          </NothingFoundBox>
        )
      }

      {
        !loading && traces.length > 0 &&
        <Box style={{ flex: 1 }}>
          <TraceList
            traces={traces}
            showRegistrationNumber={!isAircraft}

          />
          <Box sx={{ display: 'flex', justifyContent: 'center', pb: 1 }}>
            <Pagination
              size="small"
              count={Math.ceil(count / limit)}
              color="primary"
              page={page + 1}
              siblingCount={1}
              onChange={(e, page) => {
                setPage(page - 1);
                setSearchParams({ page: page });
              }}
              sx={{ justifyContent: 'center', fontSize: '0.65rem' }}
            />
          </Box>
        </Box>
      }
    </Paper>
  );
}

export default SearchTraces;