import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import logger from 'Lib/logger';
import { LinearProgress } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';


export const AuthContext = createContext();

const TOKEN_KEY = 'jwt';

export default function AuthProvider({children}){
  const navigate = useNavigate();

  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
  const [user, setUser] = useState(null);
  const [authHeaders, setAuthHeaders] = useState(null);


  useEffect(() => {
    const checkAuth = () => {
      logger("Checking auth...");

      const token = localStorage.getItem(TOKEN_KEY);

      if (!token) {
        logger('No token found');
        setIsCheckingAuth(false);
        return;
      }

      return axios.get('/auth/check.json', {
        headers: { 'Authorization': `Bearer ${token}`},
      })
      .then(response => {
        logger('response', response);

        if (response.status === 200 && response.data.user) {
          const { user } = response.data;
          logger('Setting user...', user);
          setAuthHeaders({ headers: { 'Authorization': `Bearer ${token}` }});
          setUser(user);
        }
        else {
          logger('Unsetting user:', response.status);
          localStorage.removeItem(TOKEN_KEY);
          setUser(null);
          setAuthHeaders(null);
          navigate('/sign-in');
        }

        setIsCheckingAuth(false);
      })
      .catch(error => {
        logger('Error checking auth:', error);
        console.error('Error checking auth:', error);
        setUser(null);
        setAuthHeaders(null);
      })

    };

    checkAuth();
  }, []);


  const signIn = (email, password) => {
    logger('Signing in...', email);

    return axios.post(`/users/sign_in.json`, {
      user: { email, password }
    })
    .then(response => {
      logger('Signed in:', response);
      const { user } = response.data;
      setUser(user);

      const authorizationHeader = response.headers.authorization;
      if (authorizationHeader) {
        parseAndSetToken(authorizationHeader);
      }
      return user;
    })
    .catch(error => {
      if (error.response.status === 401) {
        logger('Error signing in:', error);
        // Handle any errors that occur during the sign-in process
        setIsCheckingAuth(false);
      } else {
        throw error;
      }
    });
  };


  const parseAndSetToken = (authorizationHeader) => {
    const token = authorizationHeader.split(' ')[1];
    logger('Setting token...', token);
    localStorage.setItem(TOKEN_KEY, token);
    setAuthHeaders({ headers: { 'Authorization': `Bearer ${token}` }});
  };

  const signOut = () => {
    const token = localStorage.getItem(TOKEN_KEY);
    return axios.delete(`/users/sign_out.json`, authHeaders)
    .finally(() => {
      setUser(null);
      setAuthHeaders(null);
      localStorage.removeItem(TOKEN_KEY);
    });
  };

  const register = (email, password, passwordConfirmation) => {
    return axios.post(`/users.json`, {
      user: {
        email,
        password,
        password_confirmation: passwordConfirmation
      }
    }, { withCredentials: true });
  };

  const requestReset = (email) => {
    return axios.post(`/users/password.json`, {
      user: {
        email,
      }
    });
  };

  const resetPassword = (resetPasswordToken, password, passwordConfirmation) => {
    return axios.put(`/users/password.json`, {
      user: {
        reset_password_token: resetPasswordToken,
        password: password,
        password_confirmation: passwordConfirmation
      }
    }, { withCredentials: true });
  };


  const updatePassword = (password, passwordConfirmation) => {
    return axios.post(`/users.json`, {
      user: {
        password,
        password_confirmation: passwordConfirmation
      }
    }, authHeaders);
  };

  const requestConfirmationCode = (user_id, email) => {
    return axios.post(`/users/confirmation.json`, {
      user: {
        id: user_id,
        email: email
      }
    })
        .then((response) => {
          return { success: true, message: response.data.message || "Confirmation code sent successfully" };
        })
        .catch((error) => {
          const errorMessage = error.response?.data?.message || "Failed to send confirmation code";
          return { success: false, message: errorMessage };
        });
  };

  const confirmAccount = (confirmationToken) => {
    return axios.get(`/users/confirmation.json`, {
      params: { confirmation_token: confirmationToken }
    })
        .then(response => {
          if (response.data.success) {
            const { user, authorization } = response.data;

            if (authorization) {
              parseAndSetToken(authorization);
            }
            setUser(user);

            return { success: true, message: response.data.message, signed_in: !!authorization };
          } else {
            return { success: false, message: response.data.message || "Account confirmation failed" };
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 404) {
            return {success: false, message: "Invalid or expired confirmation code"};
          } else if (error.response?.data?.message) {
            return { success: false, message: error.response.data.message };
          } else {
            return { success: false, message: "An error occurred while confirming the account" };
          }
        });
  };

  const isSignedIn = user ? true : false;


  return (
    <AuthContext.Provider
      value={{
        user,
        register,
        signIn,
        signOut,
        authHeaders,
        isSignedIn,
        updatePassword,
        requestReset,
        resetPassword,
        requestConfirmationCode,
        confirmAccount,
        currentUser: user,
      }}>
      { isCheckingAuth ? <LinearProgress/> : children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
