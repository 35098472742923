import React, { useState } from 'react';
import { Box, Button, Typography, LinearProgress } from '@mui/material';
import AddIcCall from '@mui/icons-material/AddIcCall';
import axios from 'axios';
import { useAuth } from 'Auth/AuthProvider';
import logger from 'Lib/logger';
import { useSnackbar } from 'Lib/Snackbar';
import calculateFileChecksum from 'Lib/calculateFileChecksum';
import { HeadsetMic } from '@mui/icons-material';
import { useFlight } from 'Flights/FlightProvider';

const FlightAudioUpload = (props) => {
  const { authHeaders } = useAuth();
  const { showSnackbar } = useSnackbar();
  const { fetchAudio } = useFlight();

  const [selectedAudio, setSelectedAudio] = useState(null);
  const [progress, setProgress] = useState(0);

  const handleAudioUpload = async (event) => {
    const file = event.target.files[0];

    if (file && file.size <= 500 * 1024 * 1024) { // Limit to 500 MB
      const audio = {
        file,
        name: file.name,
        type: file.type,
        url: URL.createObjectURL(file),
      };

      setSelectedAudio(audio);
      setProgress(0); // Reset progress

      // Start uploading the file immediately
      const signedId = await uploadFileToStorage(audio);

      // Notify the server once the upload is complete
      if (signedId) {
        notifyServer([signedId]);
      }
    } else {
      showSnackbar('File size exceeds the 500 MB limit');
    }

    event.target.value = null; // Clear file input
  };


  const uploadFileToStorage = async (audio) => {
    try {
      const blob = {
        filename: audio.file.name,
        byte_size: audio.file.size,
        checksum: await calculateFileChecksum(audio.file),
        content_type: audio.file.type,
      };

      const directUploadResponse = await axios.post(
        '/rails/active_storage/direct_uploads',
        { blob },
        {
          headers: {
            ...authHeaders.headers,
            'Content-Type': 'application/json',
          },
        }
      );

      const { data } = directUploadResponse;
      const { url } = data.direct_upload;

      await axios.put(url, audio.file, {
        headers: { "Content-Type": audio.file.type },
        onUploadProgress: (progressEvent) => {
          const uploadProgress = (progressEvent.loaded / progressEvent.total) * 100;
          setProgress(uploadProgress);
        },
        maxContentLength: 10000000,
        maxBodyLength: 10000000
      });

      return data.signed_id;
    } catch (error) {
      console.error('Error uploading file:', error);
      showSnackbar('Failed to upload audio');
      return null;
    }
  };

  const notifyServer = async (signedIds) => {
    if (signedIds.length > 0) {
      try {
        const response = await axios.post(
          `/flights/${props.flightId}/upload_audios`,
          { audio: signedIds },
          { headers: authHeaders.headers }
        );

        logger(response.data);
        showSnackbar('Audio uploaded successfully!');

        props.onUpdate();

        setSelectedAudio(null);
        setProgress(0);

        fetchAudio();

      } catch (error) {
        console.error('Error notifying server:', error);
        showSnackbar('Error completing upload process');
      }
    } else {
      showSnackbar('No audio uploaded');
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
      <input
        accept="audio/mp3,audio/wav"
        style={{ display: 'none' }}
        id="audio-upload"
        type="file"
        onChange={handleAudioUpload}
      />

      {!selectedAudio &&
        <Button
          component="label"
          htmlFor="audio-upload"
          color="secondary"
          variant="text"
          size="small"
          startIcon={<HeadsetMic sx={{ opacity: 0.5 }}  />}
          sx={{
            fontSize: '0.6rem',
            alignSelf: 'center',
            p: 3,
            border: '1px dashed #ddd',
            backgroundColor: 'rgba(60, 60, 60, 0.04)',
            '&:hover': {
              backgroundColor: 'rgba(60, 60, 60, 0.04)',
            }
          }}
          fullWidth
        >
          Add
        </Button>
      }
      {selectedAudio && (
        <Box width="100%" mt={2}>
          <Typography variant="body2">{selectedAudio.name}</Typography>
          <LinearProgress variant="determinate" value={progress} />
        </Box>
      )}
    </Box>
  );
};

export default FlightAudioUpload;
