import Post from 'Posts/Post';
import FeedFlight from 'Feed/FeedFlight';

export default function FeedItem({item, currentUserId}) {
  if(item.feedableType === 'Post') {
    return <Post post={item} currentUserId={currentUserId} />
  }
  else if (item.feedableType === 'Flight') {
    return <FeedFlight flight={item} currentUserId={currentUserId} />
  }
}