import { useState } from "react";
import { IconButton, Menu, MenuItem, FormControlLabel, Checkbox } from "@mui/material";
import { Layers } from '@mui/icons-material';
import { useFlightMap } from "FlightMap/FlightMapProvider";

export default function LayersButtonAndMenu(props) {
  const { isVfrLayerVisible, isIfrLayerVisible, setIsVfrLayerVisible, setIsIfrLayerVisible } = useFlightMap();

  const [anchorEl, setAnchorEl] = useState(null);

  // Layers Menus
  const handleLayersMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLayersMenuClose = () => {
    setAnchorEl(null);
  };


  return (
    <>
      <IconButton
        size="small"
        variant="outlined"
        sx={{
            ml: 1,
            ...props.sx
          }}
        onClick={handleLayersMenuOpen}
      >
        <Layers />
      </IconButton>

      {/* Popup Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleLayersMenuClose}
      >
        <MenuItem>
          <FormControlLabel
            control={
              <Checkbox
                checked={isVfrLayerVisible}
                onChange={() => {
                  if (!isVfrLayerVisible) {
                    setIsIfrLayerVisible(false);
                    setIsVfrLayerVisible(true);
                  } else {
                    setIsVfrLayerVisible(false);
                  }

                }}
              />
            }
            label="VFR Chart Layer"
          />
        </MenuItem>
        <MenuItem>
          <FormControlLabel
            control={
              <Checkbox
                checked={isIfrLayerVisible}
                onChange={() => {
                  if (!isIfrLayerVisible) {
                    setIsVfrLayerVisible(false);
                    setIsIfrLayerVisible(true);
                  } else {
                    setIsIfrLayerVisible(false);
                  }

                }}
              />
            }
            label="IFR Chart Layer"
          />
        </MenuItem>
      </Menu>
    </>
  );
}