import {  Box, styled } from "@mui/material";
import './MapCard.css';

const Root = styled('div')(({ theme, topOffset }) => ({
  position: 'absolute',
  zIndex: 1000,
  padding: 0,
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    postition: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '50%',
  },
  [theme.breakpoints.up('sm')]: {
    left: 20,
    top: topOffset || 20,
    bottom: 42,
    borderRadius: 5,
    width: 280,
  }
}));


const MapCardScroll = styled(Box)(({ theme }) => ({
  overflowX: 'hidden',
  [theme.breakpoints.down('sm')]: {
    height: '100%',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  [theme.breakpoints.up('sm')]: {
    maxHeight: '100%',
    borderRadius: 5,
  }
}));

export default function MapCard(props) {
  return (
    <Root topOffset={props.topOffset}>
      <MapCardScroll id="MapCardScroll" sx={{ overflowX: 'hidden' }}>
        {props.children}
      </MapCardScroll>
    </Root>
  );
}
