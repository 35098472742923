import { useEffect, useState } from 'react';
import { Modal, Box, IconButton, Chip } from '@mui/material';
import { ArrowBack, TrackChanges } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { FlightTakeoff, LocationOn  } from "@mui/icons-material";
import { useProfile } from 'Profile/MyProfileProvider';
import { useAuth } from 'Auth/AuthProvider';
import { searchRegistrations, searchAirports } from 'Search/SearchApi';
import SearchAutocomplete from './SearchAutocomplete';


export default function SearchModal({ open, onClose }) {

  const { isSignedIn, authHeaders } = useAuth();
  const { isLoadingProfile, profile } = useProfile();

  const [query, setQuery] = useState("");
  const [registrations, setRegistrations] = useState([]);
  const [airports, setAirports] = useState([]);


  // const navigate = useNavigate();


  let timeout = null;


  useEffect(() => {
    if (query.length === 0) {
      setRegistrations([]);
      setAirports([]);
      return;
    }

    clearTimeout(timeout);
    timeout = setTimeout(() => {
      searchRegistrations(query, authHeaders)
        .then(registrations => setRegistrations(registrations));

      searchAirports(query, authHeaders)
        .then(airports => setAirports(airports));
    }, 200);

  }, [query])



  const clearAndClose = () => {
    console.log("clearAndClose");
    setQuery("");
    setRegistrations([]);
    setAirports([]);
    onClose();
  }



  if (!open) return null;


  return (
    <Modal
      open={open}
      onClose={clearAndClose}
      aria-labelledby="post-modal-title"
      slotProps={{
        backdrop: { style: { backgroundColor: 'rgba(0, 0, 0, 0.8)' } },
      }}
    >
      <Box sx={style}>


        <Box sx={{ pt: 1, pb: 1, mb: 0, bgcolor: '#F9F8F5' }}>
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', mt: 2, mb: 1, }}>

            <IconButton
              onClick={clearAndClose}
              sx={{ mr: 1 }}
            >
              <ArrowBack />
            </IconButton>


            <Chip
              icon={<FlightTakeoff color="primary" />}
              label="My Flights"
              size="small"
              component={Link}
              onClick={clearAndClose}
              to="/explore/my-flights"
              sx={{ ...chipStyle, mr: 1 }}
            />

            <Chip
              icon={<TrackChanges color="primary" />}
              label="Pilots"
              size="small"
              component={Link}
              onClick={clearAndClose}
              to={`/explore/pilots`}
              sx={{ ...chipStyle, mr: 1 }}
            />

            <Chip
              icon={<LocationOn color="primary" />}
              label={isSignedIn && !isLoadingProfile ? profile.homeAirportId : 'Home Airport'}
              size="small"
              component={Link}
              onClick={clearAndClose}
              to={`/explore/airports/${profile.homeAirportId}`}
              sx={chipStyle}
            />


          </Box>
        </Box>


        <Box>
          <SearchAutocomplete autoFocus={true} stayOpen style={{ width: '100%' }} isModal onClickResult={clearAndClose} />
        </Box>


      </Box>

    </Modal>
  );
}




const style = {
  zIndex: 1000,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height: '100%', // Changed from 100vh to 100% to handle mobile Safari better
  borderRadius: 0,
  maxWidth: 600,
  maxHeight: 'fill-available', // Added for iOS Safari
  bgcolor: '#F9F8F5',
  boxShadow: 24,
  display: 'flex',
  flexDirection: 'column',
};



const chipStyle = {
  pt: 2,
  pb: 2,
  pl: 1,
  pr: 1,
  mt: 0.5,
  fontWeight: 'bold',
  backgroundColor: 'white',
  border: '1px solid #e0e0e0',
};