import { useState } from 'react';
import { Box, Typography, Avatar, IconButton, Menu, MenuItem, Toolbar, Badge, Button, Tooltip } from '@mui/material';
import logger from 'Lib/logger';
import { AirplanemodeActive, ChatBubble, ChatBubbleOutline, MoreHoriz, ThumbUpAlt, ThumbUpOffAlt } from '@mui/icons-material';
import { useAuth } from 'Auth/AuthProvider';
import { useComments } from 'Comments/CommentProvider';
import { useSnackbar } from 'Lib/Snackbar';
import { Link } from 'react-router-dom';
import ProfileAvatarAndName from 'Profile/ProfileAvatarAndName';
import FlightModel from 'Models/FlightModel';
import LikeButton from 'Likes/LikeButton';

export default function FeedFlight(props) {
  const [flight, setPost] = useState(props.flight);
  const { showSnackbar } = useSnackbar();

  const { currentUserId } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const { openCommentModal } = useComments();

  const isCurrentUser = currentUserId === flight.profile.userId;

  // Layers Menus
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const toggleLike = (e) => {
  //   e.stopPropagation();
  //   logger('toggleLike', flight.isLiked === true);

  //   const apiMethod = flight.isLiked ? unlike : like;

  //   apiMethod({ type: 'flight', id: flight.id }, authHeaders)
  //     .then(({ is_liked, likes_count }) => {
  //       const newPost = new FlightModel({
  //         ...flight.asJSON(),
  //         is_liked,
  //         likes_count
  //       });
  //       setPost(newPost);
  //     });
  // };


  const updateCommentsCallback = (data) => {
    const { commentable } = data;

    const newFlight = new FlightModel({
        ...flight,
        commentsCount: commentable.commentsCount,
        hasUserComments: commentable.hasUserComments,
      });
      setPost(newFlight);
  };

  const { profile } = flight;



  return (

    <Box sx={style}>
      <Box sx={headerStyle}>
        <ProfileAvatarAndName profile={profile} />


        {/* <Box sx={{ flex: 0 }}>
          <IconButton onClick={handleOpen}>
            <MoreHoriz />
          </IconButton>

        </Box> */}
      </Box>


      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <img src={flight.mapImageUrl} alt="Flight" />
      </Box>

      <Toolbar disableGutters sx={{ mt: 1, ml: 1, mr: 1 }}>
        <div style={{ flex: 1 }}>

        <LikeButton
            isCurrentUser={isCurrentUser}
            likeable={flight}
            onToggleLike={({is_liked, likes_count}) => {
              logger('onToggleLike', is_liked, likes_count);
              const newFlight = new FlightModel({
                ...flight.asJSON(),
                is_liked,
                likes_count
              });
              logger('onToggleLike newFlight', newFlight);
              setPost(newFlight);
            }}
          />
          <Badge
            badgeContent={flight.commentsCount}
            color={flight.hasUserComments ? 'primary' : 'secondary'}
          >
            <Tooltip title="Add a comment">
            <IconButton
              size="small"
              sx={{  ml: 2, border: '2px solid transparent' }}
              onClick={() => openCommentModal({ type: 'flight', id: flight.id } , updateCommentsCallback )}
            >
              {flight.hasUserComments ? <ChatBubble /> : <ChatBubbleOutline />}
            </IconButton>
            </Tooltip>
          </Badge>
        </div>

        <div style={{ flex: 1, textAlign: 'right' }}>

            <Button
              size="small"
              color="primary"
              component={Link}
              variant="outlined"
              to={`/flights/${flight.id}`}
              endIcon={<AirplanemodeActive sx={{ transform: 'rotate(90deg)' }} />}
            >
              View Flight
            </Button>
        </div>
      </Toolbar>

      <Box sx={{ pl: 1, pr: 1 }}>
        <Typography variant="body2" sx={{ fontFamily: 'monospace' }}>
          <span style={{ fontWeight: 'bold', fontFamily: 'sans-serif' }}>{profile?.getFullName()}</span>
          &nbsp;
          <span>
            {flight.title}
          </span>
          {/* <TaggedText value={flight.caption} mentions={flight.mentions} /> */}
        </Typography>
        <Typography variant="caption" color="text.secondary">
          {flight.relativeCreatedAt()}
        </Typography>

      </Box>


    </Box>
  );
}


const style = {
  maxWidth: 470,
  mt: 2,
  mb: 4,
  ml: 'auto',
  mr: 'auto',
  position: 'relative'
};


const headerStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: 2,
  mb: 1,
  flex: 0,
  pl: 1, pr: 1
};
