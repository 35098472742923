import { Menu, MenuList, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { AUDIENCE_LABELS } from 'Models/Audiences';


export default function FlightSharingMenu({ currentAudience, anchorEl, onClose, onUpdateAudience }) {


  const handleUpdateAudience = (audience) => {
    console.log('handleUpdateAudience', audience);

    onUpdateAudience(audience);
    onClose();
  }

  return (

    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      onChange={handleUpdateAudience}
    >
      <MenuList disablePadding>
        {
          Object.keys(AUDIENCE_LABELS).map((audience, i) => {
            const isLast = i === Object.keys(AUDIENCE_LABELS).length - 1;
            const label = AUDIENCE_LABELS[audience];
            const Icon = label.icon;
            return (
              <MenuItem
                onClick={() => handleUpdateAudience(audience)}
                key={audience}
                value={audience}
                selected={audience === currentAudience}
                sx={{ borderBottom: isLast ? 'none' : '1px solid #f1f1f1' }}
              >
                <ListItemIcon>
                  <Icon color="primary" sx={{ height: 20, width: 20 }} />
                </ListItemIcon>
                <ListItemText
                  primary={label.label}
                  secondary={label.detail}
                />
              </MenuItem>
            )
          })
        }
      </MenuList>
    </Menu>
  )
}