import React, { createContext, useContext } from "react";

// Default colors from Figma
const StyledInputContext = createContext({
    color: "#212121",
    labelColor: "#212121",
    borderColor: "#212121",
    backgroundColor: "#F1F6FF",
});

export const useStyledInputContext = () => useContext(StyledInputContext);

export const StyledInputProvider = ({ value, children }) => (
    <StyledInputContext.Provider value={value}>
        {children}
    </StyledInputContext.Provider>
);
