import { useState } from 'react';
import { Modal, Box, Typography, Avatar, LinearProgress, IconButton, Divider, List, Button, ListItem } from '@mui/material';
import { Close } from '@mui/icons-material';
import CommentListItem from './CommentListItem';
import CommentForm from './CommentForm';
import { MoreVert } from '@mui/icons-material';
import TaggedText from 'TaggedText/TaggedText';
import logger from 'Lib/logger';

export default function CommentModal({ open, onClose, commentable, comments, count, onLoadMore, newComment, currentUserId }) {
  const [replyingToComment, setReplyingToComment] = useState(null);

  if (!open) return null;

  if (!commentable) return <LinearProgress />;

  const hasMoreComments = count - (newComment ? 1 : 0 ) > comments.length;

  logger("commentable",commentable);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="post-modal-title"
      slotProps={{ backdrop: { style: { backgroundColor: 'rgba(0, 0, 0, 0.8)' } } }}
    >
      <Box sx={style}>
        <Box sx={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid #e0e0e0', p: 1 }}>
          <Typography variant="h6" sx={{ flex: 1, fontWeight: 'bold' }}>Comments</Typography>
          <IconButton
            onClick={onClose}
            sx={{ flex: 0 }}
          >
            <Close />
          </IconButton>
        </Box>


        <Box sx={headerStyle}>
          <Box sx={{ alignSelf: 'flex-start', pt: 1 }}>
            <Avatar
              src={commentable.profile?.profilePhotoUrl}
            >
              {commentable.profile?.getInitials()}
            </Avatar>
          </Box>

          <Box>
            <Typography variant="body2" sx={{ fontFamily: 'monospace' }}>
              <span style={{ fontWeight: 'bold', fontFamily: 'sans-serif' }}>{commentable.profile?.getFullName()}</span>
              &nbsp;
              <TaggedText value={commentable.getCommentableCaption()} mentions={commentable.mentions} />
              {/* {commentable.caption} */}
              {/* {
                `The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.`
              } */}
            </Typography>
            <Typography variant="caption" color="text.secondary">{commentable.relativeCreatedAt()}</Typography>
          </Box>
        </Box>


        <Divider />

        <Box style={commentsListStyle}>


          <List dense>
            {
              comments.map((c, i) => (
                <CommentListItem
                  key={c.id + '-' + i}
                  comment={c}
                  onClickReply={() => setReplyingToComment(c)}
                  currentUserId={currentUserId}
                />
              ))}

            {
              hasMoreComments &&
              <ListItem sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <MoreVert sx={{ color: '#999', pb: 1 }} />
                <Button
                  size="small"
                  color="secondary"
                  onClick={onLoadMore}
                  variant="text"
                  sx={{
                    m: 0,
                    textTransform: 'none',
                    backgroundColor: 'rgba(0, 0, 0, 0.05)',
                    borderRadius: '12px',
                    fontWeight: 'bold',
                    fontSize: '0.65rem',
                    pl: 1.5,
                    pr: 1.5,
                  }}
                >
                  {count - comments.length} more comments
                </Button>
                {
                  newComment &&
                  <MoreVert sx={{ color: '#999', pt: 1 }} />
                }
              </ListItem>
            }

            {
              newComment && (
                <CommentListItem
                  key={newComment.id}
                  comment={newComment}
                  onClickReply={() => setReplyingToComment(newComment)}
                  currentUserId={currentUserId}
                />
              )
            }
          </List>

          {
            !newComment && comments.length === 0 &&
            <Typography variant="caption" color="text.secondary" sx={{ p: 1, textAlign: 'center', display: 'block' }}>No comments yet.</Typography>
          }
        </Box>


        {
          currentUserId && (
            <Box sx={buttonContainerStyle}>
              <CommentForm
                replyingToComment={replyingToComment}
                setReplyingToComment={setReplyingToComment}
              />
            </Box>
          )
        }


      </Box>

    </Modal>
  );
}




const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height: '100%', // Changed from 100vh to 100% to handle mobile Safari better
  maxWidth: 600,
  maxHeight: 'fill-available', // Added for iOS Safari
  bgcolor: 'background.paper',
  boxShadow: 24,
  display: 'flex',
  flexDirection: 'column',
};


const headerStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: 2,
  mb: 1,
  flex: 0,
  p: 1,
  pl: 1.5,
  pr: 1.5
};

const buttonContainerStyle = {
  display: 'flex',
  p: 1.5,
  borderTop: '1px solid #e0e0e0'
};

const commentsListStyle = {
  flex: 1,
  overflow: 'auto'
};