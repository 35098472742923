// Import a polyfill library if needed
import CryptoJS from 'crypto-js'; // Uncomment if using CryptoJS as a fallback

const calculateFileChecksum = async (file) => {
  const arrayBuffer = await file.arrayBuffer();

  // Check if crypto.subtle.digest is available
  if (window.crypto && window.crypto.subtle && window.crypto.subtle.digest) {
    // Use native crypto.subtle.digest if available
    const hashBuffer = await crypto.subtle.digest("SHA-256", arrayBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashBase64 = btoa(hashArray.map((byte) => String.fromCharCode(byte)).join(""));
    return hashBase64;
  } else {
    // Fallback to a polyfill (e.g., CryptoJS)
    const wordArray = CryptoJS.lib.WordArray.create(arrayBuffer);
    const hash = CryptoJS.SHA256(wordArray).toString(CryptoJS.enc.Base64);
    return hash;
  }
};

export default calculateFileChecksum;