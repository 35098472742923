import ImgRenditions from "Lib/ImgRenditions";
import VideoRenditions from "Lib/VideoRenditions";
import { Box } from "@mui/material";

export default function FlightVisualThumbnails({ visuals, onClick, selections, showIndex = false, sx={} }) {

  if(!visuals || visuals.length === 0) {
    return null;
  }

  return (

    <Box className="thumbnails" style={{ maxHeight: 96 }} sx={{ flex: 1, display: 'flex', p: 1, overflowX: 'auto', overflowY: 'hidden'}}>

      {/* Thumbnails */}
      {visuals.map((visual, index) => {
        const indexInSelections = selections.findIndex(v => v.id === visual.id);
        const isSelected = indexInSelections !== -1;

        return (
          <Box
            key={visual.id}
            sx={{
              position: 'relative',
              mr: 1,
              mb: 1,
              height: 80,
              width: 80,
            }}
            position="relative"
            mr={1}
            mb={1}

          >
            <Box
              borderRadius="8px"
              overflow="hidden"
              onClick={() => onClick({visual, index})}
              sx={{
                width: '100%',
                height: '100%',
                bgcolor: 'whitesmoke',
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
            >
              {
                visual.isImage() &&

                <div style={{ position: 'relative' }}>
                  <ImgRenditions thumbnail visual={visual} alt={`Uploaded ${index}`} />
                  {isSelected && <SelectedVisual index={ showIndex ? indexInSelections : null } />}
                </div>
              }
              {
                visual.isVideo() &&
                <div style={{ position: 'relative' }}>
                  <VideoRenditions thumbnail visual={visual} />
                  {isSelected && <SelectedVisual index={ showIndex ? indexInSelections : null } />}
                </div>
              }
            </Box>

          </Box>
        );
      })}
    </Box>

  )
}



const SelectedVisual = ({index}) => (
  <div style={{ position: 'absolute', top: 0, left: 0, right: 0, height: 80, border: '3px solid #4796F6', borderRadius: 8, backgroundColor: 'rgba(71, 150, 246, 0.25)' }} >

    {index !== null && (
      <div style={{ width: 20, height: 20, backgroundColor: '#4796F6', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white', top: 4, left: 4, position: 'absolute', fontSize: 10, border: '1px solid white', zIndex: 1000, fontWeight: 600 }}>{index + 1}</div>
    )}
  </div>
)
