import { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import Layout from 'Layout/Layout';
import { getOrSearchFeed } from 'Feed/FeedApi';
import { useAuth } from 'Auth/AuthProvider';
import CommentProvider from 'Comments/CommentProvider';
import LikeProvider from 'Likes/LikeProvider';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import FeedItem from 'Feed/FeedItem';
import Typography from '@mui/material/Typography';
import NothingFoundBox from 'FlightMap/Shared/NothingFoundBox';
import Box from '@mui/material/Box';
import AirplanemodeInactive from '@mui/icons-material/AirplanemodeInactive';
import CircularProgress from '@mui/material/CircularProgress';



export default function Search() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { authHeaders, currentUser } = useAuth();

  const [feed, setFeed] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [count, setCount] = useState(0);


  const tag = searchParams.get('tag');
  const q = searchParams.get('q');
  const searchQuery = tag ? '#' + tag : q;
  console.log("searchQuery", searchQuery);


  useEffect(() => {
    setLoading(true);
    setFeed([]);
    setCount(0);

    getOrSearchFeed({ q, tag, limit: 10, offset: 0 }, authHeaders)
      .then(feedAndCount => {
        setFeed(feedAndCount.feed);
        setCount(feedAndCount.count);
        setLoading(false);
      });
  }, [searchQuery, page, offset]);



  return (
    <Layout>
      <LikeProvider>
        <CommentProvider>
          <Container disableGutters sx={{ mb: 6, maxWidth: 470 }} style={{ backgroundColor: "#F9F8F5", borderTop: '1px solid #f1f1f1' }} >

            <Grid container spacing={0} >
              <Grid item xs={12}>
                <Container maxWidth="sm" sx={{ ml: 'auto', mr: 'auto' }}>

                  {
                    <Typography variant="h6" sx={{ m: 1, mt: 2, fontWeight: 'bold' }}>
                      {tag ? `#${tag}` : q}
                    </Typography>
                  }
                </Container>

                  { feed.map(f => <FeedItem item={f} key={f.id} currentUserId={currentUser.id} />) }


                  {feed.length === 0 &&
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>

                      {
                        loading && <>
                          <CircularProgress size={24} />
                          <Typography variant="caption" sx={{ mt: 2 }}>
                            Searching...
                          </Typography>
                        </>
                      }

                      {
                        !loading && <>
                          <AirplanemodeInactive sx={{ color: 'text.secondary' }} />
                          <Typography variant="caption" sx={{ mt: 2 }}>
                            No results found.
                          </Typography>
                        </>
                      }


                    </Box>

                  }


              </Grid>
            </Grid>
          </Container>

        </CommentProvider>
      </LikeProvider>
    </Layout>
  );
}

