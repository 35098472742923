import axios from 'axios';
import AirportModel from 'Models/AirportModel';




export const getAirportsByIds = (departureAndArrivalAirportIds, authHeaders) => {
  if(Array.isArray(departureAndArrivalAirportIds)) {
    departureAndArrivalAirportIds = departureAndArrivalAirportIds.join(',');
  }

  return axios.get(
    `/airports/find_by_ids.json?ids=${departureAndArrivalAirportIds}`,
    authHeaders
  )
    .then(response => {
      const { airports } = response.data;
      return airports.map(a => new AirportModel(a));
    });
}