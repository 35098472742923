import { Box, Typography } from '@mui/material';

export default function NothingFoundBox(props){
  return (
    <Box sx={{ m: 1, textAlign: 'center', display: 'flex', flexDirection: 'column', alignContent: 'center', flex: 1 }}>
    <Typography variant="caption" component="div" sx={{ flex: 1, alignContent: 'center' }}>
      {
        props.children
      }
    </Typography>
  </Box>
  )
}