import React, { useState, useEffect, useRef, useCallback } from 'react';
import { VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

const InfiniteScrollFeed = ({
  fetchFeedData,
  itemComponent: ItemComponent,
  itemProps = {},
  estimatedItemHeight = 990,
  loadingComponent = null,
}) => {

  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(-1);

  const sizeMap = useRef({}); // Cache of item heights
  const listRef = useRef(); // Ref for the List component
  const initialLoadDone = useRef(false); // Prevent duplicate initial load

  const loadMoreItems = useCallback(async (source) => {
    console.log('loadMoreItems', source);
    initialLoadDone.current = true;
    if (loading || !hasMore) return;

    setLoading(true);

    const nextPage = page + 1; // Use a local reference for the current page
    const newItems = await fetchFeedData(nextPage);
    console.log(`Fetched new item page ${nextPage}`, newItems);

    setItems((prevItems) => [...prevItems, ...newItems]);

    setHasMore(newItems.length > 0);

    setPage(nextPage); // Update page after fetch
    setLoading(false);
  }, [page, loading, hasMore, fetchFeedData]);


  // Trigger initial data fetch
  useEffect(() => {
    console.log('initialLoadDone', initialLoadDone);
    if (!loading && !initialLoadDone.current) {
      loadMoreItems("useEffect");
    }
  }, [loadMoreItems]);



  // Handle dynamic height measurement
  const getSize = (index) => sizeMap.current[index] || estimatedItemHeight;

  const setSize = (index, size) => {

    if (sizeMap.current[index] === size) {
      return;
    }
    // console.warn('Size update for index', index, 'with size', size);

    sizeMap.current[index] = size;

    if (listRef.current) {
      listRef.current.resetAfterIndex(index, false); // Reset with cached layout
    }
  };



  const Row = ({ index, style }) => {
    const ref = useRef();

    useEffect(() => {
      const element = ref.current;
      if (!element) return;

      let setSizeTimeout;
      let disconnectTimeout;
      let isDisconnected = false;

      const resizeObserver = new ResizeObserver((entries) => {
        if (isDisconnected) return; // Prevent actions after disconnect

        for (let entry of entries) {
          const height = entry.contentRect.height;

          // Debounce height updates
          clearTimeout(setSizeTimeout);
          setSizeTimeout = setTimeout(() => {
            // console.log('Resize observer setting size for index', index, 'to', height);
            setSize(index, height);

            // Disconnect observer after 1 second
            if (!disconnectTimeout) {
              disconnectTimeout = setTimeout(() => {
                if (!isDisconnected) {
                  // console.log('Disconnecting resize observer for index', index);
                  resizeObserver.disconnect();
                  isDisconnected = true;
                }
              }, 1000);
            }
          }, 200);
        }
      });

      resizeObserver.observe(element);

      // Cleanup function
      return () => {
        // console.log('Cleaning up resize observer for index', index);
        clearTimeout(setSizeTimeout);
        clearTimeout(disconnectTimeout);
        resizeObserver.disconnect();
        isDisconnected = true;
      };
    }, [ref, index]);

    if (index >= items.length) {
      const LoadingComponent = loadingComponent || <div />;
      return (
        <LoadingComponent
          style={{ ...style, height: getSize(index) }}
        />
      );
    }

    const item = items[index];
    return (
      <div key={item.id + '-' + index} style={{ ...style, height: 'auto' }} ref={ref}>
        <ItemComponent {...itemProps} item={item} />
      </div>
    );
  };

  const loadMoreIfNeeded = useCallback(
    ({ visibleStopIndex }) => {
      if (visibleStopIndex >= items.length - 1 && !loading && hasMore && page > -1) {
        loadMoreItems("loadMoreIfNeeded");
      }
    },
    [items.length, loading, hasMore, loadMoreItems]
  );

  return (
    <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
      <AutoSizer>
        {({ height, width }) => (
          <List
            ref={listRef}
            height={height}
            width={width}
            itemCount={hasMore ? items.length + 1 : items.length}
            itemSize={getSize}
            estimatedItemSize={estimatedItemHeight}
            onItemsRendered={loadMoreIfNeeded}
          >
            {Row}
          </List>
        )}
      </AutoSizer>
    </div>
  );
};

export default InfiniteScrollFeed;
