import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { TextField, Container, Typography, Box, Button, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import LoadingButton from '@mui/lab/LoadingButton';
import { useAuth } from './AuthProvider';
import { useSnackbar } from 'Lib/Snackbar';

const SignIn = () => {

  window.document.title = 'Sign In | WingJockey';

    const navigate = useNavigate();
    const location = useLocation();
    const { signIn } = useAuth();
    const { showSnackbar } = useSnackbar();

    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const from = location.state?.from || '/';

    const handleSubmit = (e) => {
        e.preventDefault();

        setIsLoading(true);
        signIn(email, password)
            .then(user => {
                setIsLoading(false);
                if (user) {
                    navigate(from, {replace: true});
                } else {
                    showSnackbar('Invalid username or password', {severity: 'error'});
                }
            })
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Box
            sx={{
                position: "relative",
                backgroundColor: '#000000B8',
                backgroundBlendMode: 'overlay',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                textAlign: 'center',
            }}
        >
            <picture>
                <source srcSet="/auth/auth1-768.jpg" media="(max-width: 768px)"/>
                <source srcSet="/auth/auth1-992.jpg" media="(max-width: 992px)"/>
                <source srcSet="/auth/auth1-1200.jpg" media="(max-width: 1200px)"/>
                <source srcSet="/auth/auth1-1400.jpg" media="(max-width: 1400px)"/>
                <source srcSet="/auth/auth1-1920.jpg" media="(max-width: 1920px)"/>
                <source srcSet="/auth/auth1-3024.jpg" media="(max-width: 3024px)"/>
                <img
                    src="/auth/auth1-3024.jpg"
                    alt="Background"
                    style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        zIndex: -1,
                    }}
                />
            </picture>
            <Container component='main' maxWidth='sm'>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component='h1' variant='h4' sx={{fontWeight: 'bold', mb: 2}}>
                        Welcome to WingJockey
                    </Typography>
                    <Typography variant='subtitle1' sx={{mb: 3, color: '#D2D2D2', fontSize: '1.5rem'}}>
                        Ident.
                    </Typography>
                    <Box
                        component='form'
                        onSubmit={handleSubmit}
                        sx={{
                            padding: 3,
                            borderRadius: 2,
                            width: '100%',
                        }}
                    >
                        <TextField
                            variant='outlined'
                            margin='normal'
                            required
                            fullWidth
                            id='email'
                            label='Email'
                            name='email'
                            type='email'
                            autoComplete='email'
                            autoFocus
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            InputLabelProps={{
                                sx: {
                                    "&.MuiInputLabel-shrink": {
                                        color: "#ff6b03",
                                        padding: "0 10px",
                                        borderRadius: "4px",
                                        transform: "translate(5px, 2px) scale(0.75)"
                                    },
                                    color: "#ff6b03",
                                },
                            }}
                            InputProps={{
                                style: {
                                    color: '#212121',
                                    backgroundColor: '#F1F6FF',
                                },
                                sx: {
                                    "& .MuiOutlinedInput-input": {
                                        padding: "21px 14px 12px",
                                    },
                                },
                            }}
                            sx={{
                                mb: 2,
                                "& .MuiOutlinedInput-root": {
                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "transparent",
                                    },
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#ff6b03",
                                        borderWidth: "0 0 3px 0",
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "transparent",
                                    },
                                },
                                "& .MuiInputLabel-root": {
                                    color: "#ff6b03",
                                },
                                "& .MuiInputLabel-root.Mui-focused": {
                                    color: "#ff6b03",
                                },
                            }}
                        />
                        <TextField
                            variant='outlined'
                            margin='normal'
                            required
                            fullWidth
                            name='password'
                            label='Password'
                            type={showPassword ? "text" : "password"}
                            id='password'
                            autoComplete='current-password'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            InputLabelProps={{
                                sx: {
                                    "&.MuiInputLabel-shrink": {
                                        color: "#ff6b03",
                                        padding: "0 10px",
                                        borderRadius: "4px",
                                        transform: "translate(5px, 2px) scale(0.75)"
                                    },
                                    color: "#ff6b03",
                                },
                            }}
                            InputProps={{
                                style: {
                                    color: '#212121',
                                    backgroundColor: '#F1F6FF',
                                },
                                sx: {
                                    "& .MuiOutlinedInput-input": {
                                        padding: "21px 14px 12px",
                                    },
                                },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <Visibility/> : <VisibilityOff/>}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                mb: 3,
                                "& .MuiOutlinedInput-root": {
                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "transparent",
                                    },
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#ff6b03",
                                        borderWidth: "0 0 3px 0",
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "transparent",
                                    },
                                },
                                "& .MuiInputLabel-root": {
                                    color: "#ff6b03",
                                },
                                "& .MuiInputLabel-root.Mui-focused": {
                                    color: "#ff6b03",
                                },
                            }}
                        />
                        <LoadingButton
                            loading={isLoading}
                            loadingIndicator='Signing In…'
                            type='submit'
                            fullWidth
                            variant='contained'
                            sx={{
                                backgroundColor: '#FF6B00',
                                color: 'white',
                                mb: 4,
                                '&:hover': {backgroundColor: '#FF5500'},
                            }}
                        >
                            Sign in
                        </LoadingButton>
                        {/* <Box
                            sx={{
                                // display: 'flex',
                                justifyContent: 'space-between',
                                gap: 3,
                                mb: 3,
                            }}
                        > */}
                            <Button
                                component={Link}
                                to='/reset-password'
                                variant="text"
                                fullWidth
                                sx={{ mb: 2 }}
                                // sx={{
                                //     flex: 1,
                                //     color: '#FF6B00',
                                //     backgroundColor: '#FFFFFF',
                                //     borderColor: "#FF6B00",
                                //     textTransform: 'none',
                                // }}
                            >
                                Forgot Password?
                            </Button>
                            <Button
                                component={Link}
                                to='/one-time-code'
                                variant="text"
                                fullWidth
                                // sx={{
                                //     flex: 1,
                                //     color: '#FF6B00',
                                //     backgroundColor: '#FFFFFF',
                                //     borderColor: "#FF6B00",
                                //     textTransform: 'none',
                                // }}
                            >
                                One-time login code
                            </Button>
                        {/* </Box> */}
                        <Typography variant='body2' sx={{color: '#ffffffb3'}}>
                            Don’t have an account?{' '}
                            <Button
                                component={Link}
                                to='/request-invite'
                                variant='text'
                                sx={{
                                    color: '#FFFFFF',
                                    textTransform: 'none',
                                    fontWeight: 'bold',
                                }}
                            >
                                Request an Invite
                            </Button>
                        </Typography>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

export default SignIn;
