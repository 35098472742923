import { useState } from 'react';
import { getUserFlights, getUserPairFlights } from 'Flights/FlightsApi';
import { useEffect } from "react";
import { useFlightMap } from "FlightMap/FlightMapProvider";
import { Box, LinearProgress, Pagination, Avatar } from "@mui/material";
import FlightList from "FlightMap/Shared/FlightList";
import { useAuth } from "Auth/AuthProvider";
import { useParams, Navigate, useSearchParams } from "react-router-dom";
import CardHeader from 'FlightMap/Shared/CardHeader';
import { AirplanemodeActive, FlightTakeoff } from '@mui/icons-material';
import StickyHeader from 'FlightMap/Shared/Sticky';
import NothingFoundBox from 'FlightMap/Shared/NothingFoundBox';
import { Paper } from '@mui/material';
import logger from 'Lib/logger';
import { TrackChanges } from '@mui/icons-material';
import ProfileAvatar from 'Profile/ProfileAvatar';
import { getNotMyProfile } from 'Profile/ProfileApi';



export default function ExploreFlights(props) {
  const limit = 10;
  const { userId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const { currentUser, authHeaders, isSignedIn } = useAuth();
  const { clearMap, setMapFlights, setMapTraces } = useFlightMap();

  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState(null);
  const [flights, setFlights] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(() => {
    const pageParam = searchParams.get('page');
    return pageParam ? parseInt(pageParam) - 1 : 0;
  });


  const isMyFlights = window.location.pathname.includes('/my-flights');
  const isPilots = window.location.pathname.includes('/pilots');
  const isUser = window.location.pathname.includes('/users');

  const isSelf = userId === currentUser.id;


  useEffect(() => {
    const page = searchParams.get('page') ? +searchParams.get('page') - 1 : 0;
    setPage(page);

    if (isSelf) {
      return;
    }

    logger('FlightsList useEffect', page);

    clearMap();

    if (isUser) {

      getNotMyProfile(userId, authHeaders)
        .then(data => {
          setProfile(data.profile);
        })

      getUserFlights(
        { userId, limit, offset: page * limit },
        authHeaders
      )
        .then(data => {
          setFlights(data.flights);
          setCount(data.count);

          setMapFlights(data.flights);
          setLoading(false);
        });

      return;
    }



    const apiMethod = isMyFlights ? getUserFlights : getUserPairFlights;

    setMapTraces([]);

    setLoading(true);
    apiMethod(
      { limit, offset: page * limit },
      authHeaders
    )
      .then(data => {
        setFlights(data.flights);
        setCount(data.count);

        setMapFlights(data.flights);
        setLoading(false);

      })

  }, [isMyFlights, isPilots, isUser, page]);



  if (isSelf) {
    return <Navigate to="/explore/my-flights" />
  }

  return (
    <Paper sx={{ minHeight: '100%', display: 'flex', flexDirection: 'column', pt: 0, pb: 0, borderRadius: 0 }}>

      <StickyHeader>
        <CardHeader
          title={
            <Box display='flex' alignItems='center'>
              {isMyFlights && <><FlightTakeoff color="primary" sx={{ mr: 1 }} />My Flights</>}
              {isPilots && <><TrackChanges color="primary" sx={{ mr: 1 }} />Pilots</>}
              {isUser && profile && <>
                <ProfileAvatar profile={profile} sx={{ mr: 1, height: 36, width: 36 }} />
                {profile?.getFullName()}
              </>}
            </Box>
          }
        /> </StickyHeader>

      {
        loading && <>
          <LinearProgress />
          <NothingFoundBox>
            <AirplanemodeActive />
            <p>Loading...</p>
          </NothingFoundBox>
        </>
      }


      {
        !loading && isSignedIn && flights.length > 0 &&
        <Box style={{ flex: 1 }}>
          <FlightList flights={flights} showUser={isPilots} />
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>

            {
              count > limit && <Pagination
              size="small"
              count={Math.ceil(count / limit)}
              color="primary"
              page={page + 1}
              onChange={(e, page) => {
                setPage(page - 1);
                setSearchParams({ page: page });
              }}
              sx={{ justifyContent: 'center' }}
            />
            }

          </Box>
        </Box>
      }

    </Paper>
  )

}