import { useEffect, useState } from 'react';
import { Box, Typography, TextField, MenuItem, LinearProgress } from '@mui/material';
import logger from 'Lib/logger';
import VisualGallery from 'Lib/VisualGallery';
import LoadingButton from '@mui/lab/LoadingButton';
import { updatePost } from './PostsApi';
import { useAuth } from 'Auth/AuthProvider';
import { useProfile } from 'Profile/MyProfileProvider';
import ProfileAvatar from 'Profile/ProfileAvatar';
import PostVisualUpload from 'Posts/PostVisualUpload';
import { useSnackbar } from 'Lib/Snackbar';
import { useNavigate, useParams } from 'react-router-dom';
import { getPost } from './PostsApi';
import { AUDIENCES, AUDIENCE_LABELS, AUDIENCES_PRIVATE } from 'Models/Audiences';
import { uploadFileToStorage, filterFilesForSize, notifyServer } from 'Lib/uploadHelpers';
import TaggedTextField from 'TaggedText/TaggedTextField';



export default function PostEdit() {

  const { authHeaders } = useAuth();
  const { postId } = useParams();
  const { profile, isLoadingProfile } = useProfile();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [post, setPost] = useState(null);

  const [caption, setCaption] = useState('');
  const [tags, setTags] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [audience, setAudience] = useState(null);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    getPost(postId, authHeaders)
      .then(post => {
        setPost(post);
        setCaption(post.caption);
        setAudience(post.audience);
        setTags(post.mentions.tags);
        setProfiles(post.mentions.profiles);
        setLoading(false);
      });
  }, [postId]);



  const handlePost = async (event) => {
    setLoading(true);

    updatePost({
      id: post.id,
      caption: caption.trim(),
      audience,
      mentions: { tags, profiles },
    }, authHeaders)
      .then(() => {
        showSnackbar('Posted updated');
        navigate(`/profile`);
      })

  };

  if (isLoadingProfile || loading) {
    return <LinearProgress />;
  }

  return (

    <Box sx={style}>
      <Box sx={headerStyle}>
        <ProfileAvatar profile={profile} sx={{ width: 32, height: 32 }} />
        <Typography variant="subtitle1">
          {profile?.getFullName()}
        </Typography>
      </Box>

      <TaggedTextField
        value={caption}
        onChange={
          data => {
            setCaption(data.value);
            setTags(data.tags);
            setProfiles(data.profiles);
          }}
      />


      <VisualGallery
        visuals={post.visualUrls}
        initialIndex={currentIndex}
        sx={{ flex: 1 }}
        goToIndex={true}
      />

      <Box sx={buttonContainerStyle}>
        <TextField
          variant="standard"
          size="small"
          select
          fullWidth
          label="Audience"
          sx={{ flex: 2 }}
          value={audience}
          onChange={(e) => setAudience(e.target.value)}
        >
          {
            AUDIENCES.filter(a => a !== AUDIENCES_PRIVATE).map(a => {
              return (
                <MenuItem key={a} value={a} selected={audience === a}>
                  {AUDIENCE_LABELS[a].label} ({AUDIENCE_LABELS[a].detail})
                </MenuItem>
              )
            })
          }
        </TextField>

        <LoadingButton
          size="small"
          variant="contained"
          onClick={handlePost}
          loading={loading}
          sx={{ flex: 1, height: 30, mt: 2 }}
        >
          Update
        </LoadingButton>
      </Box>
    </Box>
  );
}


const style = {
  margin: '0px auto',
  width: '100%',
  height: '100%',
  maxWidth: 600,
  bgcolor: 'background.paper',
  p: 2,
  display: 'flex',
  flexDirection: 'column',
};


const headerStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: 2,
  mb: 1,
  flex: 0
};

const buttonContainerStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 1,
  mt: 1,
  flex: 0
};
