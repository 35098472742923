import axios from 'axios';
import logger from 'Lib/logger';

axios.interceptors.response?.use(undefined, error => {

  return new Promise((resolve, reject) => {

    // const errorState = {
    //   errorStatus: error?.response?.status,
    //   errorMessage: error?.response?.message,
    // }

    const isSignIn = window.location.pathname.includes('/sign-in');

    console.log('error', error);

    if(error?.response?.status === 401 && !isSignIn){
      return redirectToSignIn();

      // return reject(error);
    }

    if(error?.response?.status === 403){
      logger( error );
      // return redirectToSignIn();
    }

    if(error?.response?.status === 503){
      return reject(error);
    }

    throw error;

  });

});


const redirectToSignIn = (navigate) => {
  window.history.pushState({}, '', '/sign-in');
  // Trigger a React re-render if necessary
  // For example, if you're not using React Router
  window.location.reload();
}