import { DateTime } from "luxon";
import BaseModel from "Lib/BaseModel";
import LogbookEntryModel from "Models/LogbookEntryModel";
import TraceModel from "Models/TraceModel";
import ProfileModel from "Models/ProfileModel";
import UserModel from "Models/UserModel";
import VisualModel from "./VisualModel";
import AircraftModel from "./AircraftModel";

export default class FlightModel extends BaseModel {
  constructor(data) {
    super(data || {});
    this.logbookEntry = new LogbookEntryModel(this.logbookEntry || {});
    this.departureAndArrivalAirports = this.departureAndArrivalAirports || [];
    this.traces = (this.traces || []).map(t => new TraceModel(t));
    this.profile = new ProfileModel(this.profile || {});
    this.user = new UserModel(this.user || {});
    this.visualUrls = (this.visualUrls || []).map(t => new VisualModel(t));


    this.likeableType = 'flight';
    this.commentableType = 'flight';
  }

  static color = '#FC5200';


  getDepartureUTC(){
    const localDateTime = `${this.departureDate}T${this.departureTime || '12:00'}`;
    const dateTimeInTimezone = DateTime.fromISO(localDateTime, { zone: this.departureTzid });
    return dateTimeInTimezone.toJSDate();
  }

  getArrivalUTC(){
    const { totalHours } = this.logbookEntry;
    const localDateTime = `${this.departureDate}T${this.departureTime || '12:00'}`;
    const dateTimeInTimezone = DateTime.fromISO(localDateTime, { zone: this.departureTzid });
    return dateTimeInTimezone.plus({ hours: totalHours }).toJSDate();
  }

  getCommentableCaption(){
    return this.title;
  }

  isSingleAirport(){
    if(this.departureAndArrivalAirports.length !== 2){
      return false;
    }

    const firstAirport = this.departureAndArrivalAirports[0];
    const lastAirport = this.departureAndArrivalAirports[1];
    return firstAirport === lastAirport;
  }

}
