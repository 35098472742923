import { DateTime } from 'luxon';
import BaseModel from "Lib/BaseModel";
import MedicalExpirationsModel from "./MedicalExpirationsModel";

export default class ProfileModel extends BaseModel {

  static PILOT_TYPES = [
    'Student Pilot',
    'Private Pilot',
    'Commercial Pilot',
    'Certified Flight Instructor',
    'Airline Transport Pilot',
    'Designated Pilot Examiner',
  ];

  static MEDICAL_TYPES = [
    'First Class',
    'Second Class',
    'Third Class',
    'Basic Med',
  ];

  constructor(data) {
    super(data);

    this.optionType = 'Pilots';


    const dates = [
      'soloDate',
      'privatePilotDate',
      'flightReviewDate',
      'flightReviewExpirationDate',
      'medicalDate',
    ];

    dates.forEach(d => {
      if(this[d] && !typeof this[d] === 'Date') {
        this[d] = new Date(this[d]);
      }
    });

    if(this.medicalExpirationDates) {
      this.medicalExpirationDates = new MedicalExpirationsModel(this.medicalExpirationDates);
    }
  }

  getOptionLabel() {
    return this.getFullName();
  }


  isProfileComplete(){
    const requiredFields = [
      'firstName',
      'lastName',
      'pilotType',
      'homeAirportId',
    ];

    return requiredFields.every(field => !!this[field]);
  }

  getInitials(){
    if(!this.firstName && !this.lastName) {
      return '';
    }
    return (this.firstName || "")[0] + (this.lastNameInitial || this.lastName || "")[0];
  }

  getFullName() {
    if(!this.firstName && !this.lastName) {
      return '';
    }
    const last = this.lastNameInitial || this.lastName
    return (this.firstName || "") + ' ' + (last || "")[0] + '.';
  }

  getFlightReviewRemainingDays() {
    const now = new Date();
    const diff = this.flightReviewExpirationDate - now;
    return Math.floor(diff / (1000 * 60 * 60 * 24));
  }

  relativeUpdatedAt() {
    const parsedDate = DateTime.fromISO(this.updatedAt); // Replace with `fromFormat` if needed
    return  parsedDate.toRelative();
  }

  getMentionData(){
    return {
      id: this.userId,
      display: '@' + this.getFullName() + ' ' + this.homeAirportId,
      profilePhotoUrl: this.profilePhotoUrl,
      userId: this.userId,
      getInitials: this.getInitials,
      type: 'profile'
    }
  }

}

