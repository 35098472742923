import { useEffect, useState } from 'react';
import { Box, LinearProgress, Button, CardHeader, Typography } from '@mui/material';
import { useAuth } from 'Auth/AuthProvider';
import { Link, useParams } from 'react-router-dom';
import { getTracePath } from 'FlightMap/TraceDetail/TracesApi';
import { useFlightMap } from 'FlightMap/FlightMapProvider';
import AirportChips from 'FlightMap/Shared/AirportChips';
import axios from 'axios';
import logger from 'Lib/logger';
import FlightModel from 'Models/FlightModel';
import Layout from 'Layout/Layout';
import BaseMap from 'BaseMap/BaseMap';
import BaseMapGL from 'BaseMap/BaseMapGL';
import { formatDate, formatTime } from 'Lib/format';
import { Grid } from '@mui/material';
import NoteAdd from '@mui/icons-material/NoteAdd';
import Container from '@mui/material/Container';

import TraceChart from 'FlightMap/TraceDetail/TraceChart';
import FlightVisualUpload from './FlightVisualUpload';
import FlightVisuals from './FlightVisuals';
import FlightAudioUpload from './FlightAudioUpload';
import VisualGalleryModal from './VisualGalleryModal';
import ProfileAvatarAndName from 'Profile/ProfileAvatarAndName';
import WjCard from 'Lib/WjCard';
import { useSnackbar } from "Lib/Snackbar";
import { useFlight } from './FlightProvider';
import FlightAudio from './FlightAudio';
import PostModal from 'Posts/PostModal';
import { AUDIENCE_LABELS, AUDIENCES_PRIVATE } from 'Models/Audiences';
import FlightSharingMenu from './FlightSharingMenu';
import CommentButton from 'Comments/CommentButton';
import { useComments } from 'Comments/CommentProvider';
import TailNumberChipHeader from 'FlightMap/Shared/TailNumberChipHeader';
import { useProfile } from 'Profile/MyProfileProvider';
import LikeButton from 'Likes/LikeButton';
import EditTitleModal from './EditTitleModal';
import EditNotesModal from './EditNotesModal';
import VisibilityTip from './VisibilityTip';
import { Route } from '@mui/icons-material';
import brushingBus, { dispatchBrushUpdate } from 'BrushingBus/BrushingBus';
import LogbookTable from './LogbookTable';
import Hsi from 'Hsi/Hsi';
import MapVisuals from 'BaseMap/MapVisuals';
import './FlightView.css';
/*
  This component should do a few things. It should show the details of the trace / trace.
  If the user is not logged in or the flight isn't logged, it should show the trace details.
  These details may be copied to the flight itself
  If the user is logged in - it should show the details of the flight if it is logged.
*/


export default function ViewFlight(props) {
  const params = useParams();
  const { showSnackbar } = useSnackbar();
  const { openCommentModal } = useComments();
  const { airport } = useProfile();

  const { setMapFlights, setMapTraces } = useFlightMap();
  const { authHeaders, user } = useAuth();
  const { flight, setFlight, visuals, audio, updateFlightMetadata } = useFlight();

  // Audience Menu
  const [audienceAnchorEl, setAudienceAnchorEl] = useState(null);
  const handleAudienceMenuOpen = (event) => {
    setAudienceAnchorEl(event.currentTarget);
  };
  const handleAudienceMenuClose = () => {
    setAudienceAnchorEl(null);
  };

  const [tracePath, setTracePath] = useState(null);
  const [isPostModalOpen, setIsPostModalOpen] = useState(false);



  // VisualGalleryModal
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [currentGalleryIndex, setCurrentGalleryIndex] = useState(0);

  // Edit Title Modal
  const [isEditTitleModalOpen, setIsEditTitleModalOpen] = useState(false);
  // Edit Notes Modal
  const [isEditNotesModalOpen, setIsEditNotesModalOpen] = useState(false);
  // Edit Debreif Modal
  const [isEditPrivateDebriefModalOpen, setIsEditPrivateDebriefModalOpen] = useState(false);


  useEffect(() => {
    setMapFlights([]);
    setMapTraces([]);

    if (!flight) {
      return
    }

    setMapFlights([flight]);
    setMapTraces(flight.traces);
    const tracePathRequests = flight.traces.map(t => getTracePath(t));
    axios.all(tracePathRequests)
      .then(responses => {
        const sortedPaths = responses.sort((a, b) => a[0].t - b[0].t);
        const tracePath = sortedPaths.reduce((a, b) => a.concat(b), []);
        logger('tracePath', tracePath);
        setTracePath(tracePath);

      });

  }, [flight]);



  const onGalleryOpen = (index) => {
    console.log('onGalleryOpen', index);
    setCurrentGalleryIndex(index);
    setIsGalleryOpen(true);
  };

  const updateCommentsCallback = (data) => {
    const { commentable } = data;

    const newFlight = new FlightModel({
      ...flight,
      commentsCount: commentable.commentsCount,
      hasUserComments: commentable.hasUserComments,
    });
    setFlight(newFlight);
  };


  if (!flight) {
    return <LinearProgress />
  }

  const isCurrentUser = user && user.id == flight.userId;

  const { logbookEntry } = flight;
  const takeoff = flight.getDepartureUTC();
  const landing = flight.getArrivalUTC();



  const onUpdateFlight = (key, value) => {

    const labelLookup = {
      privateDebrief: 'debrief',
      publicNotes: 'notes',
      sharing: 'sharing',
      title: 'name'
    }

    showSnackbar("Updating flight...");
    return updateFlightMetadata({ [key]: value })
      .then(() => {
        logger('onUpdateFlight', key, value);
        const label = labelLookup[key] || key;;
        showSnackbar("Updated flight " + label + ".");
      });

  };

  const SharingIcon = AUDIENCE_LABELS[flight.audience].icon;

  const socialToolbar = (
    <Box sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>
      <LikeButton
        isCurrentUser={isCurrentUser}
        likeable={flight}
        onToggleLike={({ is_liked, likes_count }) => {
          logger('onToggleLike', is_liked, likes_count);
          const newFlight = new FlightModel({
            ...flight.asJSON(),
            is_liked,
            likes_count
          });
          logger('onToggleLike newFlight', newFlight);
          setFlight(newFlight);
        }}
      />


      <CommentButton
        commentable={flight}
        updateCommentsCallback={updateCommentsCallback}
        openCommentModal={openCommentModal}
        rootSx={{ mr: 1 }}
      />

    </Box>
  )

  return (

    <Layout key={flight.updatedAt}>


      <Container className="FlightView" disableGutters maxWidth="md" sx={{ mx: 'auto', mb: 8 }}>
        <Grid container spacing={1} >

          <Grid item xs={12}>
            <Box sx={{ m: 1, mr: 0, display: 'flex', justifyContent: 'flex-end' }}>
              {isCurrentUser &&
                <>
                  <Button
                    size="small"
                    variant="text"
                    sx={{ mr: 1 }}
                    onClick={handleAudienceMenuOpen}
                    startIcon={<SharingIcon />}
                  >
                    Sharing
                  </Button>
                  <FlightSharingMenu
                    currentAudience={flight.audience}
                    anchorEl={audienceAnchorEl}
                    onClose={handleAudienceMenuClose}
                    onUpdateAudience={(audience) => onUpdateFlight('audience', audience)}
                  />
                </>
              }

            </Box>

            {/* Title and Date Row */}
            <WjCard sx={{ m: 1, pt: 2 }}>

              <Grid container>
                <Grid item xs={12} sx={{ pl: 1, pr: 1, display: 'flex', borderBottom: '1px solid whitesmoke' }}>
                  <Box flex={1}>
                    <CardHeader disableTypography title={
                      <>
                        <Typography
                          variant="h5"
                          onClick={isCurrentUser ? () => setIsEditTitleModalOpen(true) : undefined}
                          sx={{ cursor: isCurrentUser ? 'pointer' : 'default' }}
                        >
                          {flight.title}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">{formatDate(takeoff)}</Typography>
                      </>
                    } sx={{ p: 1, flex: 1 }} />
                  </Box>
                  <Box sx={{ flex: 0, display: { xs: 'none', sm: 'inherit' } }}>
                    {socialToolbar}
                  </Box>
                </Grid>


                {/* Profile Avatar / Toolbar Row */}
                <Grid item xs={12} sm={6} sx={{ display: 'flex', borderBottom: '1px solid whitesmoke' }}>
                  <div style={{ flex: 1 }}>
                    <ProfileAvatarAndName profile={flight.profile} sx={{ p: 2 }} />
                  </div>
                  <Box sx={{ flex: 0, display: { xs: 'inherit', sm: 'none' } }}>
                    {socialToolbar}
                  </Box>
                </Grid>



                <Grid
                  item xs={12} sm={6}
                  sx={{
                    display: 'flex',
                    alignContent: 'center',
                    pt: 1,
                    borderBottom: { xs: 'none', sm: '1px solid whitesmoke' }
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flex: 1,
                      flexDirection: { xs: 'row', sm: 'column' },
                      alignItems: { xs: 'center', sm: 'flex-end' },
                      alignContent: 'center',
                      justifyContent: { xs: 'flex-start', sm: 'center' },
                    }}>

                    <Box sx={{ flex: { xs: 1, sm: 0 } }}>
                      <TailNumberChipHeader
                        tailNumber={flight.registrationNumber}
                        sx={{ mb: 1, ml: 1 }}
                      />
                    </Box>
                    <Box
                      sx={{
                        flex: { xs: 1, sm: 0 },
                        textAlign: 'right',
                      }}
                    >
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{ mr: 1 }}
                      >
                        {flight.aircraft?.getDisplayName()}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

              </Grid>

              <AirportChips
                airports={flight.departureAndArrivalAirports}
                airportInfo={flight.airports}
                takeoffTime={formatTime(takeoff)}
                landingTime={formatTime(landing)}
              />



            </WjCard>


          </Grid >


          {/* Map */}
          <Grid item xs={12} >
            <WjCard sx={{ m: 1, p: 0 }}>



              <Box sx={{ position: 'relative', height: 400 }}>
                <BaseMap
                  useBrush
                  initialCenter={airport?.getCenter()}
                  disableSidebarPadding
                  drawCurves={!flight?.traces.length > 0}
                  maxZoom={flight.isSingleAirport() ? flight.airports[0].getZoom() : 15}
                >
                  <MapVisuals
                    onClick={(visual) => {
                      const i = visuals.findIndex(v => v === visual);
                      onGalleryOpen(i);
                    }}
                  />
                </BaseMap>
                {flight.traces.length > 0 && <FlightTimestamp />}
                {flight.traces.length > 0 && <FlightHsi />}
              </Box>

              {
                tracePath && tracePath.length > 0 ?
                  <Box sx={{ bgcolor: 'white', mt: 0.5, mr: '10px', ml: '10px' }}>
                    <TraceChart
                      tracePath={tracePath}
                      height={80}
                      color={FlightModel.color}
                      onBrushMove={(point) => {
                        dispatchBrushUpdate({
                          source: 'trace-chart',
                          timestamp: point.t,
                          point
                        })
                      }}
                    />
                  </Box>
                  :
                  <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                  }}
                  >



                    <Typography variant="caption" color="text.secondary" sx={{
                      p: 2,
                      fontSize: '0.625rem',
                      textAlign: 'center',
                      opacity: 0.75,
                      textAlign: 'left',
                      display: 'flex',
                      alignItems: 'center',
                      component: 'div'
                    }}>
                      <Route sx={{
                        display: 'inline-block', mr: 1, fontSize: 18, lineHeight: '1rem'
                      }} />
                      <div>
                        <span style={{ color: '#FC5200', fontWeight: 'bold' }}>ADSB flight track data</span> is available approximately 24 hours after your flight. Log your <strong>tail number</strong> and <strong>departure time</strong> for your flight track to be available.
                      </div>
                    </Typography>
                  </div>
              }


              {
                isCurrentUser &&
                <FlightAudio
                  tracePath={tracePath}
                  flightId={flight.id}
                  visibilityTip={
                    isCurrentUser && <VisibilityTip subject="Flight Audio" audience={AUDIENCES_PRIVATE} />
                  }
                />
              }

              {!audio && isCurrentUser &&
                <Box sx={{ pl: 1.5, pr: 1.5, pb: 2 }}>
                  <FlightAudioUpload
                    flightId={flight.id}
                  />
                </Box>
              }
            </WjCard >
          </Grid >




          {
            (isCurrentUser || flight.publicNotes) &&

            <Grid item xs={12} >
              <WjCard sx={{ m: 1, p: 1.5, mb: 0 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Typography variant="body2" sx={{ flex: 1, pb: 1, display: 'flex', alignItems: 'center' }}>
                    Flight Notes
                    {
                      isCurrentUser && <VisibilityTip subject="Flight Photos and Videos" audience={flight.audience} />
                    }
                  </Typography>

                  <Box sx={{ flex: 0 }}>
                    {
                      isCurrentUser && flight.publicNotes &&
                      <Button
                        variant="text"
                        size="small"
                        color="secondary"
                        onClick={() => setIsEditNotesModalOpen(true)}
                      >
                        Edit
                      </Button>

                    }
                  </Box>
                </Box>

                {
                  isCurrentUser && !flight.publicNotes &&
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                      variant="text"
                      size="small"
                      color="secondary"
                      fullWidth
                      sx={{
                        fontSize: '0.6rem',
                        alignSelf: 'center',
                        p: 2.5,
                        border: '1px dashed #ddd',
                        backgroundColor: 'rgba(60, 60, 60, 0.04)',
                        '&:hover': {
                          backgroundColor: 'rgba(60, 60, 60, 0.04)',
                        }
                      }}
                      startIcon={<NoteAdd sx={{ opacity: 0.5 }} />}
                      onClick={() => setIsEditNotesModalOpen(true)}
                    >Add Notes</Button>
                  </div>
                }

                {
                  <Typography variant="body2" sx={{ flex: 1, pb: 1, fontFamily: 'monospace' }}>
                    {flight.publicNotes}
                  </Typography>
                }



              </WjCard>
            </Grid>
          }




          <Grid item xs={12} >

            <WjCard sx={{ m: 1, p: 1, mt: 3 }}>

              <FlightVisuals
                flightId={flight.id}
                isCurrentUser={isCurrentUser}
                onClick={({ index }) => onGalleryOpen(index)}
                visibilityTip={
                  isCurrentUser && <VisibilityTip subject="Flight Photos and Videos" audience={flight.audience} />
                }
                toolbarButtons={
                  isCurrentUser && flight.visualsCount > 0 &&
                  <Button
                    variant="text"
                    onClick={() => setIsPostModalOpen(true)}
                    size="small"
                    color="secondary"
                  >
                    Post
                  </Button>


                }
              />

              <VisualGalleryModal
                open={isGalleryOpen}
                handleClose={() => setIsGalleryOpen(false)}
                visuals={visuals || []}
                initialIndex={currentGalleryIndex}
              />

              {isCurrentUser &&
                <FlightVisualUpload
                  flightId={flight.id}
                  buttonSx={{
                    p: visuals.length > 0 ? 1 : 2.5,
                    mt: visuals.length > 0 ? 1 : 0,
                    border: flight.visualsCount > 0 ? '1px solid rgba(60, 60, 60, 0.04)' : '1px dashed #ddd'
                  }}
                />}
            </WjCard>
          </Grid >


          {
            isCurrentUser &&
            <Grid item xs={12}>
              {/*<WjCard sx={{ p: 1, m: 1, mt: 2 }}>
                <FlightAudio
                  flightId={flight.id}
                  visibilityTip={
                    isCurrentUser && <VisibilityTip subject="Flight Audio" audience={AUDIENCES_PRIVATE} />
                  }
                />
                {!audio && isCurrentUser &&
                  <FlightAudioUpload
                    flightId={flight.id}
                  />
                }
              </WjCard>*/}



              {/* <Grid item xs={12}>
                <Box sx={{ mt: 3, p: 1 }}>
                  <Typography variant="caption">Private Flight Details</Typography>
                </Box>
              </Grid> */}

              <Grid item xs={12}>

                <WjCard sx={{ m: 1, mt: 2 }}>

                  <div style={{ display: 'flex' }}>
                    <Typography variant="body" sx={{  p: 1.5, pb: 1, fontWeight: 'bold',  flex: 1 }}>
                      Logbook+
                      <VisibilityTip subject="Logbook+" audience={AUDIENCES_PRIVATE} />
                    </Typography>
                    <Box sx={{  p: 1  }}>
                      <Button size="small" color="secondary" component={Link} to={`/flights/${params.flightId}/edit`}>
                        Edit
                      </Button>
                    </Box>
                  </div>

                  {/* {
                    LogbookEntryModel.hoursFields.map(f => {
                      if (!logbookEntry[f.field]) return null;
                      return (
                        <Typography key={f.field} component="div" variant="caption" sx={{ fontSize: '0.8rem' }}>
                          {f.displayName}: {logbookEntry[f.field]}
                        </Typography>
                      )
                    })
                  } */}
                  <LogbookTable logbookEntry={logbookEntry} />
                </WjCard>


              </Grid>

            </Grid>
          }

          {
            isCurrentUser &&

            <Grid item xs={12} >
              <WjCard sx={{ m: 1, p: 1.5, mb: 0 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Typography variant="body2" sx={{ flex: 1, pb: 1, display: 'flex', alignItems: 'center' }}>
                    Private Debrief
                    {
                      isCurrentUser && <VisibilityTip subject="Private Debrief" audience={AUDIENCES_PRIVATE} />
                    }
                  </Typography>

                  <Box sx={{ flex: 0 }}>
                    {
                      flight.privateDebrief &&
                      <Button
                        variant="text"
                        size="small"
                        color="secondary"
                        onClick={() => setIsEditPrivateDebriefModalOpen(true)}
                      >
                        Edit
                      </Button>

                    }
                  </Box>
                </Box>

                {
                  !flight.privateDebrief &&
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                      variant="text"
                      size="small"
                      color="secondary"
                      fullWidth
                      sx={{
                        fontSize: '0.6rem',
                        alignSelf: 'center',
                        p: 2.5,
                        border: '1px dashed #ddd',
                        backgroundColor: 'rgba(60, 60, 60, 0.04)',
                        '&:hover': {
                          backgroundColor: 'rgba(60, 60, 60, 0.04)',
                        }
                      }}
                      startIcon={<NoteAdd sx={{ opacity: 0.5 }} />}
                      onClick={() => setIsEditPrivateDebriefModalOpen(true)}
                    >Add Debrief</Button>
                  </div>
                }

                {
                  <Typography variant="body2" sx={{ flex: 1, pb: 1, fontFamily: 'monospace' }}>
                    {flight.privateDebrief}
                  </Typography>
                }



              </WjCard>
            </Grid>
          }


        </Grid >

        <EditTitleModal
          open={isEditTitleModalOpen}
          text={flight.title}
          onSave={title => onUpdateFlight('title', title)}
          onClose={() => setIsEditTitleModalOpen(false)}
        />

        <EditNotesModal
          label="Flight Notes"
          open={isEditNotesModalOpen}
          text={flight.publicNotes}
          audience={flight.audience}
          onSave={notes => onUpdateFlight('publicNotes', notes)}
          onClose={() => setIsEditNotesModalOpen(false)}
        />

        <EditNotesModal
          label="Private Debrief"
          open={isEditPrivateDebriefModalOpen}
          text={flight.privateDebrief}
          audience={AUDIENCES_PRIVATE}
          placeholder="Enter your debrief..."
          onSave={debrief => onUpdateFlight('privateDebrief', debrief)}
          onClose={() => setIsEditPrivateDebriefModalOpen(false)}
        />

        <PostModal
          open={isPostModalOpen}
          onClose={() => setIsPostModalOpen(false)}
          flight={flight}
          profile={flight.profile}
        />

      </Container>
    </Layout >
  )
}



const FlightHsi = function () {
  const [heading, setHeading] = useState(null);

  useEffect(() => {
    const onBrushUpdate = (e) => {
      if (e.detail && e.detail.point) {
        setHeading(e.detail.point.bearing);
      }
    };

    brushingBus.addEventListener('brush-update', onBrushUpdate);
    return () => brushingBus.removeEventListener('brush-update', onBrushUpdate);
  }, []);

  return (
    <div
      style={{
        position: 'absolute',
        bottom: 0,
        right: 5,
        zIndex: 1000,
        width: 60,
        opacity: heading === null ? 0 : 1,
      }}>

      <Hsi
        heading={heading || ''}
        style={{
          padding: '1px 4px',
          fontSize: 12,
        }}
      />
    </div>
  )
}




const FlightTimestamp = function () {
  const [time, setTime] = useState(null);

  useEffect(() => {
    const onBrushUpdate = (e) => {
      if (e.detail && e.detail.timestamp) {
        const timestamp = Math.floor(e.detail.timestamp);
        const newTime = new Date(timestamp * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        setTime(newTime);
      }
    };

    brushingBus.addEventListener('brush-update', onBrushUpdate);
    return () => brushingBus.removeEventListener('brush-update', onBrushUpdate);
  }, []);

  if (!time) return null;

  return (
    <div className="timestamp">
      {time}
    </div>
  )
}


