import React, { useEffect, useState } from 'react';
import { Grid, Box, Button } from '@mui/material';
import Layout from 'Layout/Layout';
import Post from 'Posts/Post';
// import { getFeedPosts } from 'Posts/PostsApi';
import { getOrSearchFeed } from './FeedApi';
import { useAuth } from 'Auth/AuthProvider';
import ProfileCard from './ProfileCard';
import SignUpCard from './SignUpCard';
import { Link } from 'react-router-dom';
import InfiniteScrollFeed from './InfiniteScrollFeed';
import LikeProvider from 'Likes/LikeProvider';
import CommentProvider from 'Comments/CommentProvider';
import PostSkeleton from 'Posts/PostSkeleton';
import { LinearProgress } from '@mui/material';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { CircularProgress } from '@mui/material';
import FeedItem from './FeedItem';


const Feed = () => {
  const { authHeaders, isSignedIn, user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);



  const fetchFeedData = async (page) => {
    const limit = 5;
    const feedAndCount = await getOrSearchFeed({ limit, offset: page * limit }, authHeaders)
    return feedAndCount.feed;
  }

  const onRefresh = async () => {
    setRefreshing(true);
    await fetchFeedData(0);
    setRefreshing(false);
  }


  if (loading) {
    return <LinearProgress />
  }


  return (


    <Layout>
      <Grid container spacing={2} sx={{ height: 'calc(100% + 16px)' }}>

        <Grid item md={4} sx={{ display: { xs: 'none', md: 'block' } }}>
          { isSignedIn ? <ProfileCard /> : <SignUpCard /> }
        </Grid>

        <Grid item xs={12} md={8}>
          <PullToRefresh
            onRefresh={onRefresh}
            refreshing={true}
            pullingContent={null}
            refreshingContent={
            <Box sx={{ pt: 2}}>
              <CircularProgress size={24} />
            </Box>}
          >
            <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
              <LikeProvider>
                <CommentProvider>
                <InfiniteScrollFeed
                  loadingComponent={PostSkeleton}
                  fetchFeedData={fetchFeedData}
                  itemComponent={FeedItem}
                  itemProps={{ currentUserId: user ? user.id : null }}
                  estimatedItemHeight={975} // Adjust to your average height
                />
              </CommentProvider>
            </LikeProvider>
          </Box>
          </PullToRefresh>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Feed;