import { useState, useEffect } from 'react';
import { useAuth } from 'Auth/AuthProvider';
import { CardHeader } from '@mui/material';
import { getAirportsByIds } from 'Airports/AirportsApi';
import { Box, Typography, Chip } from '@mui/material';
import { getAircraftByRegistrationNumbers } from 'Aircraft/AircraftApi';
import logger from 'Lib/logger';
import TailNumberChipHeader from 'FlightMap/Shared/TailNumberChipHeader';
import Cessna from 'Aircraft/Cessna';


function AircraftCardHeader(props) {
  const { registrationNumber } = props;
  const { authHeaders } = useAuth();


  const [aircraft, setAircraft] = useState(null);

  useEffect(() => {
    getAircraftByRegistrationNumbers(registrationNumber, authHeaders).then(aircrafts => {
      logger("aircrafts", aircrafts);
      setAircraft(aircrafts[0]);
    });
  }, [registrationNumber, authHeaders]);


  if (!aircraft) return null;


  return (
    <Box sx={{ pt: 1, pb: 2, pl: 1, pr: 1, borderBottom: '1px solid #e0e0e0' }}>
      <div>
        <TailNumberChipHeader tailNumber={aircraft.registrationNumber} sx={{ mb: 0.5 }} />
      </div>

      <Typography variant="caption" sx={{ flex: 1, fontWeight: 'bold', mb: 0.5 }}>
      {`${aircraft.year} ${aircraft.getManufacturer()} ${aircraft.model}`}
      </Typography>

      {/* <Box sx={{ display: 'flex', gap: 1, alignItems: 'flex-start', mb: 0.5 }}>
        <Chip label={airport.getId()} size="small" sx={{ flex: 0, borderRadius: 1, fontSize: '12px' }} />
        <Typography variant="caption" sx={{ display: 'block', flex: 1, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', lineHeight: '24px' }}>{airport.city}, {airport.stateName}</Typography>
      </Box>

      <Box sx={{ width: '100%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
        <span style={{ color: airport.getTowerColor() }}>●</span>&nbsp;
        <Typography variant="caption" sx={{ mr: 1 }}>{airport.elev}' MSL</Typography>
        <Typography variant="caption" sx={{}}>{airport.runways.map(r => r.rwy_id.startsWith('H') ? r.rwy_id : 'R' + r.rwy_id).sort().join(', ')}</Typography>
      </Box> */}
    </Box>
  )
}

export default AircraftCardHeader;