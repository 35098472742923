import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Layout from 'Layout/Layout';
import { getPost } from 'Posts/PostsApi';
import { useAuth } from 'Auth/AuthProvider';
import CommentProvider from 'Comments/CommentProvider';
import LikeProvider from 'Likes/LikeProvider';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Post from 'Posts/Post';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';



export default function PostView() {
  const { postId } = useParams();
  const { authHeaders, currentUser } = useAuth();

  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    setPost(null);


    getPost(postId, authHeaders)
      .then(post => {
        setPost(post);
        setLoading(false);
      });
  }, [postId]);



  return (
    <Layout>
      <LikeProvider>
        <CommentProvider>
          <Grid container spacing={0} >
            <Grid item xs={12}>
              <Container maxWidth="sm" disableGutters sx={{ ml: 'auto', mr: 'auto', mt: 2 }}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', ml: 2 }}>
                  Post
                </Typography>
              </Container>


              {
                loading &&
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                  <CircularProgress size={24} sx={{ alignSelf: 'center' }} />
                </Box>
              }

              {!loading && <Post currentUserId={currentUser.id} post={post} />}

            </Grid>
          </Grid>
        </CommentProvider>
      </LikeProvider>
    </Layout>
  );
}

