import React from "react";
import { Box, ListItem, ListItemButton, Paper, List, Typography, ListItemText, CircularProgress, Divider, Chip, ListItemIcon, IconButton, useTheme } from "@mui/material";
import * as d3 from "d3";
import { Book, Check, FlightTakeoff, MoreHoriz, MoreVert } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { formatDate, formatHours, formatTime } from "Lib/format";
import logger from "Lib/logger";

export default function TraceList(props) {
  const { traces } = props;

  const theme = useTheme();
  logger('TraceList', traces);
  if (props.loading) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', padding: 12, height: '100%', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress size={24} />
        <Typography variant="caption">Loading...</Typography>
      </div>
    )
  }



  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ flex: 1, width: '100%', overflowY: 'scroll' }}>
        <List sx={{ pt: 0 }}>
          <Divider />

          {
            traces.map((trace, i) => {

              const highlightFlight = () => {
                d3.selectAll(`.trace:not(.t-${trace.traceUid})`).style('opacity', 0.3);
                d3.selectAll(`.t-${trace.traceUid}`).style('opacity', 1);
              }

              const unhighlightFlight = () => {
                d3.selectAll(`.trace`).style('opacity', 1);
              }

              return (
                <React.Fragment key={i}>
                  <ListItemButton
                    key={trace.traceUid}
                    component={Link}
                    to={`/explore/traces/${trace.registrationNumber}/${trace.traceUid}`}
                    dense
                    onMouseEnter={highlightFlight}
                    onTouchStart={highlightFlight}
                    onMouseLeave={unhighlightFlight}
                    onTouchEnd={unhighlightFlight}
                    sx={{ p: 0 }}
                  >
                    <ListItemText
                      disableTypography
                    >
                      {/* Top Row */}
                      <Box sx={{ display: 'flex', pl: 1, pr: '3px', pt: 1 }}>
                        <div style={{ flex: 1 }}>
                          {props.showRegistrationNumber && <Chip
                            label={trace.registrationNumber} size="small"
                            color={trace.flightId ? 'primary' : 'default'}
                            sx={{
                              fontSize: '0.875rem',
                              borderBottomLeftRadius: 0,
                              borderTopRightRadius: 0,
                              border: '1px solid #ddd',
                              backgroundColor: 'rgba(60, 60, 60, 0.04)',
                              mb: 1,
                              '&:hover': {
                                backgroundColor: theme.palette.primary.main,
                                color: 'white',
                              },
                              '&:focus': {
                                backgroundColor: theme.palette.primary.main,
                                color: 'white',
                              }
                            }}
                            component={Link}
                            to={`/explore/traces/${trace.registrationNumber}`}

                          />}


                          {
                            trace.aircraft &&
                            <Typography color="text.secondary" variant="caption" sx={{ display: 'block', flex: 1, mb: 0.5, fontSize: '0.675rem' }}>
                              {`${trace.aircraft.getManufacturer() || ''} ${trace.aircraft.model || ''}`}
                            </Typography>
                          }
                        </div>
                        {/* <div style={{ textAlign: 'right', flex: 1 }}>
                          <IconButton onClick={(e) => { e.stopPropagation(); e.preventDefault(); }} size="small">
                            <MoreHoriz />
                          </IconButton>
                        </div> */}

                      </Box>

                      {/* Date and Hours */}
                      <Box sx={{ display: 'flex', mb: 1, pl: 1, pr: 1 }}>

                        <Typography variant="body2">
                          {formatDate(new Date(trace.takeoffT))}
                        </Typography>

                        <Typography variant="caption" style={{textAlign: 'right', flex: 1 }}>
                          {formatHours(trace.getDurationHours())}h
                        </Typography>
                      </Box>


                      <Box sx={{ display: 'flex', mb: 1, pl: 1, pr: 1 }}>

                        <div style={{ fontSize: '0.75rem', color: '#777', fontWeight: '500', marginRight: 10 }}>
                          {formatTime(new Date(trace.takeoffT))} - {formatTime(new Date(trace.landingT))}
                        </div>
                        <Typography variant="caption" component="div" style={{ display: 'flex' }}>
                          <div style={{ flex: 1 }}>

                            {[trace.departureArptId || 'Unknown', trace.destinationArptId || 'Unknown'].map((a, i) => <Chip key={i} label={a} size="small" sx={{ mr: '4px', fontSize: '0.55rem', p: 0, height: 18, borderRadius: '3px' }} />)}

                          </div>
                        </Typography>

                      </Box>



                    </ListItemText>
                  </ListItemButton>
                  <Divider />

                </React.Fragment>
              )
            })
          }
        </List>
      </div>
    </div>
  )

}



const highlightFlight = (flightId) => {
}

const unhighlightFlight = (flightId) => {

}