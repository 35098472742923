
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import BaseMap from 'BaseMap/BaseMap';
import { useFlightMap } from 'FlightMap/FlightMapProvider';
import { getFlightMapImageData } from 'Flights/FlightsApi';
import { getTracePath } from 'FlightMap/TraceDetail/TracesApi';
import logger from 'Lib/logger';

function FlightMapImage() {

  const { flightId } = useParams();
  const [flight, setFlight] = useState(null);
  const [loading, setLoading] = useState(true);
  const [maxZoom, setMaxZoom] = useState(15);
  const { setMapFlights, setMapTraces } = useFlightMap();


  useEffect(() => {
    setLoading(true);

    getFlightMapImageData(flightId)
      .then((flight) => {

        let z = 15;
        if(flight.isSingleAirport()){
          z = flight.airports[0].getZoom();
          console.log('mazZoom', z);
        }

        setMaxZoom(z);
        setFlight(flight);
        setLoading(false);
      });
  }, [flightId]);


  useEffect(() => {
    setMapFlights([]);
    setMapTraces([]);

    if (!flight) {
      return
    }


    setMapFlights([flight]);
    setMapTraces(flight.traces);
    console.log('flight.traces', flight.traces);
    const tracePathRequests = flight.traces.map(t => getTracePath(t));
    axios.all(tracePathRequests)
      .then(responses => {
        const sortedPaths = responses.sort((a, b) => a[0].t - b[0].t);
        const tracePath = sortedPaths.reduce((a, b) => a.concat(b), []);
        logger('tracePath', tracePath);
      });

  }, [flight]);


  return (
      <BaseMap drawCurves={!flight?.traces.length} disableSidebarPadding maxZoom={maxZoom} showLayersButton={false} />
  );
}

export default FlightMapImage;