import React, {useState} from 'react';
import {useNavigate, Link} from 'react-router-dom';
import {Button, Container, Typography, Box} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import {useSnackbar} from 'Lib/Snackbar';
import {register} from './AuthApi';
import {useAuth} from './AuthProvider';
import StyledInput from "./AuthComponents/StyledInput";
import StyledPassword from "./AuthComponents/StyledPassword";
import AuthScreen from "./AuthComponents/AuthScreen";
import StepsSidebar from "./AuthComponents/StepsSidebar";
import ProfileModel from "../Models/ProfileModel";

const SignUp = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [homeAirport, setHomeAirport] = useState('');
    const [pilotType, setPilotType] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');

    const navigate = useNavigate();
    const {signIn} = useAuth();
    const {showSnackbar} = useSnackbar();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== passwordConfirmation) {
            showSnackbar('Passwords do not match', {severity: 'error'});
            return;
        }
        try {
            setIsLoading(true);
            register(email, password, passwordConfirmation)
                .then(resp => {
                    if (resp.data.id) {
                        signIn(email, password)
                            .then(user => {
                                if (user) {
                                    navigate('/profile/edit', {replace: true});
                                } else {
                                    setIsLoading(false);
                                    showSnackbar('Account created. Please verify your email to log in.', {severity: 'info'});
                                    navigate('/confirm-account', {state: {email, id: resp.data.id}});
                                }
                            });
                    } else {
                        setIsLoading(false);
                        showSnackbar('Could not sign up.', {severity: 'error'});
                    }
                });
        } catch (error) {
            console.error('Error signing up:', error);
        }
    };

    const signUpStyles = {
        color: "#212121",
        labelColor: "#212121",
        borderColor: "#212121",
        backgroundColor: "#F1F6FF",
    };

    return (
        <AuthScreen backgroundType="auth2" inputStyles={signUpStyles}>
            <StepsSidebar activeStep={1} />

            <Container component="main" maxWidth="sm">
                <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography component="h1" variant="h4" sx={{ mb: 3, color: '#424242' }}>
                        Enter your Account Details
                    </Typography>

                    <Typography variant='subtitle1' sx={{ mb: 3, color: '#757575', fontSize: '1.4rem' }}>
                        Ident.
                    </Typography>

                    <Box component="form" onSubmit={handleSubmit} sx={{mt: 1}}>
                        <StyledInput required fullWidth
                            label="Full Name"
                            id="fullName"
                            name="fullName"
                            type="text"
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                        />
                        <StyledInput required fullWidth
                            label="Email Address"
                            id="email"
                            name="email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <StyledInput required fullWidth
                            label="Home Airport"
                            id="homeAirport"
                            name="homeAirport"
                            type="text"
                            value={homeAirport}
                            onChange={(e) => setHomeAirport(e.target.value)}
                        />
                        <StyledInput required fullWidth
                            label="Who are you?"
                            id="pilotType"
                            name="pilotType"
                            value={pilotType}
                            onChange={(e) => setPilotType(e.target.value)}
                            select
                            options={ProfileModel.PILOT_TYPES}
                        />
                        <StyledPassword required fullWidth
                            id="password"
                            name="password"
                            label="Enter your Password"
                            value={password}
                            autoComplete="new-password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <StyledPassword required fullWidth
                            id="passwordConfirmation"
                            name="passwordConfirmation"
                            label="Re-enter your Password"
                            value={passwordConfirmation}
                            autoComplete="new-password"
                            onChange={(e) => setPasswordConfirmation(e.target.value)}
                        />

                        <LoadingButton fullWidth type="submit" variant="contained" sx={{mt: 3, mb: 2}}
                            loading={isLoading}
                            loadingIndicator="Signing Up…"
                        >
                            Next Step
                        </LoadingButton>

                        <Typography variant="body2" sx={{mt: 3, textAlign: "center", color: "#212121"}}>
                            Already have an account?{" "}
                            <Link
                                component={Link}
                                to="/sign-in"
                                underline="none"
                                sx={{cursor: "pointer", fontWeight: "bold", color: "#0061E6"}}
                            >
                                Login
                            </Link>
                        </Typography>
                    </Box>
                </Box>
            </Container>
        </AuthScreen>
    );
};

export default SignUp;
