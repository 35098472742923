import React, { createContext, useState, useContext, useEffect } from 'react';
import { useAuth } from 'Auth/AuthProvider';
import { getNotifications, markAsRead } from './NotificationsApi';

export const NotificationsContext = createContext();

export default function Notifications({children}){
  const { isSignedIn, authHeaders } = useAuth();

  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [count, setCount] = useState(0);

  const [page, setPage] = useState(0);
  const limit = 10;


  const loadNotifications = () => {
    if(!isSignedIn){
      return;
    }

    setLoading(true);
    getNotifications({ limit, offset: page * limit }, authHeaders)
      .then(data => {
        setNotifications(data.notifications);
        setCount(data.count);
        setUnreadCount(data.unreadCount);
        setLoading(false);
      });
  }


  useEffect(() => {
    loadNotifications();
  }, [isSignedIn, page]);


  const refreshNotifications = () => {
    loadNotifications();
  }


  const onMarkAsRead = (notificationIds) => {
    console.log("onMarkAsRead", notificationIds);

    markAsRead(notificationIds, authHeaders)
      .then(() => {
        if(notificationIds === 'all'){
          setUnreadCount(0);
        }
      });
  }

  return (
    <NotificationsContext.Provider value={{
      notifications,
      isLoadingNotifications: loading,
      unreadCount,
      count,
      onMarkAsRead,
      refreshNotifications,
      setPage
    }}>
      { children }
    </NotificationsContext.Provider>
  );
};

export const useNotifications = () => useContext(NotificationsContext);