import { useState } from 'react';
import { Box, Typography, IconButton, Menu, MenuItem, Toolbar, Button } from '@mui/material';
import logger from 'Lib/logger';
import VisualGallery from 'Lib/VisualGallery';
import { AirplanemodeActive, MoreHoriz } from '@mui/icons-material';
import PostModel from 'Models/PostModel';
import { useAuth } from 'Auth/AuthProvider';
import { useComments } from 'Comments/CommentProvider';
import { deletePost } from 'Posts/PostsApi';
import { useSnackbar } from 'Lib/Snackbar';
import { Link } from 'react-router-dom';
import TaggedText from 'TaggedText/TaggedText';
import { LinearProgress } from '@mui/material';
import LikeButton from 'Likes/LikeButton';
import ProfileAvatarAndName from 'Profile/ProfileAvatarAndName';
import CommentButton from 'Comments/CommentButton';
import { AUDIENCES_PRIVATE } from 'Models/Audiences';
import './Post.css';

export default function Post(props) {

  const [post, setPost] = useState(props.post);
  const { showSnackbar } = useSnackbar();

  const { currentUserId } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const { openCommentModal } = useComments();

  const { authHeaders } = useAuth();
  const isCurrentUser = currentUserId === post.profile.userId;

  // Layers Menus
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };



  const updateCommentsCallback = (data) => {
    const { commentable } = data;

    const newPost = new PostModel({
      ...post,
      commentsCount: commentable.commentsCount,
      hasUserComments: commentable.hasUserComments,
    });
    setPost(newPost);
  };

  const { profile } = post;

  const onDeletePost = () => {
    if (window.confirm('Are you sure you want to delete this post? All comments and props will also be deleted.')) {
      logger('deletePost', post);
      deletePost(post.id, authHeaders)
        .then(() => {
          showSnackbar('Post deleted');
          if (props.onDeletePost) {
            props.onDeletePost();
          }
        });
    }
  };

  return (

    <Box sx={style}>
      <Box sx={headerStyle}>
        <ProfileAvatarAndName profile={profile} />

        <Box sx={{ flex: 0 }}>
          <IconButton onClick={handleOpen}>
            <MoreHoriz />
          </IconButton>

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {/* Menu doesn't accept a fragment as a child */}
            {isCurrentUser && <MenuItem component={Link} to={`/posts/${post.id}/edit`}>Edit</MenuItem>}
            {isCurrentUser && props.showDelete && <MenuItem onClick={onDeletePost}>Delete</MenuItem>}

            {!isCurrentUser && <MenuItem>Report</MenuItem>}
          </Menu>
        </Box>
      </Box>


      {
        post.isProcessing() &&
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <Typography variant="caption" color="text.secondary">
            Processing {post.visualsDoneCount + 1} of {post.visualsCount}...
          </Typography>
          <LinearProgress sx={{ width: '100%', borderTopRightRadius: 2, borderTopLeftRadius: 2 }} />
        </Box>
      }

      <VisualGallery
        visuals={post.visualUrls}
        initialIndex={0}
        sx={{ flex: 1 }}
      />

      <Toolbar disableGutters sx={{ mt: 1, ml: 1, mr: 1 }}>
        <div style={{ flex: 1 }}>

          <LikeButton
            isCurrentUser={isCurrentUser}
            likeable={post}
            onToggleLike={({ is_liked, likes_count }) => {
              logger('onToggleLike', is_liked, likes_count);
              const newPost = new PostModel({
                ...post.asJSON(),
                is_liked,
                likes_count
              });
              logger('onToggleLike newPost', newPost);
              setPost(newPost);
            }}
          />

          <CommentButton
            commentable={post}
            updateCommentsCallback={updateCommentsCallback}
            openCommentModal={openCommentModal}
            rootSx={{ mr: 1 }}
          />
        </div>
        <div style={{ flex: 1, textAlign: 'right' }}>
          {
            post.flightId && post.flightAudience !== AUDIENCES_PRIVATE &&

            <Button
              size="small"
              color="primary"
              component={Link}
              variant="outlined"
              to={`/flights/${post.flightId}`}
              endIcon={<AirplanemodeActive sx={{ transform: 'rotate(90deg)' }} />}
            >
              View Flight
            </Button>
          }
        </div>
      </Toolbar>
      <Box sx={{ pl: 1, pr: 1 }}>
        <Typography variant="body2" sx={{ fontFamily: 'monospace' }}>
          <span style={{ fontWeight: 'bold', fontFamily: 'sans-serif' }}>{profile?.getFullName()}</span>
          &nbsp;
          <TaggedText value={post.caption} mentions={post.mentions} />
        </Typography>
        <Typography variant="caption" color="text.secondary">
          {post.relativeCreatedAt()}
        </Typography>

      </Box>


    </Box>
  );
}


const style = {
  maxWidth: 470,
  mt: 2,
  mb: 4,
  ml: 'auto',
  mr: 'auto',
  position: 'relative'
};


const headerStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: 2,
  mb: 1,
  flex: 0,
  pl: 1, pr: 1
};
