import { VisibilityOff, People, Public, AirplaneTicket, TrackChanges } from '@mui/icons-material';

export const AUDIENCES_PRIVATE = 'private';
export const AUDIENCES_FRIENDS = 'friends';
export const AUDIENCES_WINGJOCKEY = 'wingjockey';
// export const AUDIENCES_PUBLIC = 'public';

export const AUDIENCES = [
  AUDIENCES_PRIVATE,
  AUDIENCES_FRIENDS,
  AUDIENCES_WINGJOCKEY,
  // AUDIENCES_PUBLIC,
];

export const AUDIENCE_LABELS = {
  [AUDIENCES_PRIVATE]: {
    detail: 'Only me',
    label: 'Private',
    visibleTo: 'only you',
    icon: VisibilityOff,
  },
  [AUDIENCES_FRIENDS]: {
    detail: 'Only pilots I fly with',
    label: 'Pilots',
    visibleTo: 'pilots I fly with',
    icon: TrackChanges,
  },
  [AUDIENCES_WINGJOCKEY]: {
    detail: 'Signed-in users',
    label: 'WingJockey',
    visibleTo: 'signed-in WingJockey members',
    icon: AirplaneTicket,
  },
  // [AUDIENCES_PUBLIC]: {
  //   detail: 'Anyone on the internet',
  //   label: 'Public',
  //   visibleTo: 'anyone on the internet',
  //   icon: Public,
  // }
};