import React, { createContext, useState, useContext, useEffect } from 'react';
import { useAuth } from 'Auth/AuthProvider';
import { getMyProfile } from './ProfileApi';
import { Navigate } from 'react-router-dom';

export const ProfileContext = createContext();

export default function MyProfileProvider({children}){
  const { isSignedIn, authHeaders } = useAuth();
  const [isLoadingProfile, setIsLoadingProfile] = useState(true);

  const [profile, setProfile] = useState(null);
  const [airport, setAirport] = useState(null);
  const [flightStats, setFlightStats] = useState(null);


  useEffect(() => {
    if(!isSignedIn) {
      setIsLoadingProfile(false);
      return;
    }

    getMyProfile(authHeaders)
      .then(data => {
        setProfile(data.profile);
        setFlightStats(data.flightStats);
        setAirport(data.airport);
        setIsLoadingProfile(false);
      });

  }, [isSignedIn]);





  return (
    <ProfileContext.Provider value={{ profile, isLoadingProfile, setProfile, flightStats, airport }}>
      { children }

      {
        profile && !profile.isProfileComplete() &&
          <Navigate to="/profile/edit" />
      }

    </ProfileContext.Provider>
  );
};

export const useProfile = () => useContext(ProfileContext);