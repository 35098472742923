import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import AddAPhoto from '@mui/icons-material/AddAPhoto';
import { filterFilesForSize } from 'Lib/uploadHelpers';


const PostVisualUpload = ({ onFilesSelected }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [previews, setPreviews] = useState([]);

  const handleFileSelection = (event) => {
    const files = Array.from(event.target.files);
    const validFiles = filterFilesForSize(files);

    // Create preview URLs
    const newPreviews = validFiles.map(file => ({
      url: URL.createObjectURL(file),
      type: file.type
    }));

    setSelectedFiles(validFiles);
    setPreviews(newPreviews);
    onFilesSelected(validFiles);
  };


  // Cleanup previews when component unmounts
  React.useEffect(() => {
    return () => {
      previews.forEach(preview => URL.revokeObjectURL(preview.url));
    };
  }, []);

  return (
    <Box display="flex" flexDirection="column" gap={2} sx={{ mt: 2 }}>
      <input
        accept="image/*,video/*"
        style={{ display: 'none' }}
        id="post-visual-upload"
        type="file"
        onChange={handleFileSelection}
        multiple
      />

      {selectedFiles.length >= 0 && (
        <Button
          component="label"
          htmlFor="post-visual-upload"
          color="primary"
          variant="text"
          size="small"
          startIcon={<AddAPhoto />}
          sx={{
            backgroundColor: 'rgba(252, 82, 0, 0.08)',
          }}
        >
          Select Photos
        </Button>
      )}

    </Box>
  );
};

export default PostVisualUpload;