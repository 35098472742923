import axios from 'axios';
import logger from 'Lib/logger';
import calculateFileChecksum from 'Lib/calculateFileChecksum';

export const filterFilesForSize = files => (
  // 500 MB limit
  files.filter(file => file.size <= 500 * 1024 * 1024)
)


export const uploadFileToStorage = async (file, authHeaders, index, setProgressArray, onError) => {
  try {
    const blob = {
      filename: file.name,
      byte_size: file.size,
      checksum: await calculateFileChecksum(file),
      content_type: file.type,
    };

    const directUploadResponse = await axios.post(
      '/rails/active_storage/direct_uploads',
      { blob },
      {
        withCredentials: true,
        headers: {
          ...authHeaders.headers,
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest'
        },
      }
    );

    const { data } = directUploadResponse;
    const { url } = data.direct_upload;

    await axios.put(url, file, {
      headers: { 'Content-Type': file.type },
      onUploadProgress: (progressEvent) => {
        const uploadProgress = (progressEvent.loaded / progressEvent.total) * 100;
        setProgressArray(prev => {
          const updatedProgress = [...prev];
          updatedProgress[index] = uploadProgress;
          return updatedProgress;
        });
      },
    });

    return data.signed_id;
  } catch (error) {
    console.error('Error uploading file:', error);
    onError();
    return null;
  }
};


export const notifyServer = async (url, signedIds, authHeaders, onFinish, onError) => {
  if (signedIds.length > 0) {
    try {
      const response = await axios.post(
        url,
        { visuals: signedIds },
        { headers: authHeaders.headers }
      );

      logger(response.data);

      onFinish();

      // setSelectedFiles([]);
      // setProgressArray([]);


    } catch (error) {
      console.error('Error notifying server:', error);
      onError();
    }
  }
};
