import BaseMap from "BaseMap/BaseMap";
import Layout from "Layout/Layout";
import { Box, Button, Toolbar, useMediaQuery, useTheme, IconButton } from "@mui/material";
import { Filter, FlightTakeoff, Layers, LocationOn, People } from "@mui/icons-material";
import { Routes, Route, Link } from 'react-router-dom';
import MapCard from "FlightMap/MapCard/MapCard";
import { useProfile } from 'Profile/MyProfileProvider';
import { useAuth } from 'Auth/AuthProvider';
import { useNavigate, useLocation } from "react-router-dom";
import LayersButtonAndMenu from "BaseMap/LayersButtonAndMenu";
import ExploreTraces from "Explore/ExploreTraces";
import ExploreFlights from "Explore/ExploreFlights";
import TraceDetail from "FlightMap/TraceDetail/TraceDetail";






export default function Explore() {
  const { isSignedIn } = useAuth();
  const { airport } = useProfile();
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();

  return (

    <Layout>
      <BaseMap
        mapTheme="light"
        hideAirportLabels={['/explore/my-flights', '/explore/pilots'].includes(location.pathname)}
        initialCenter={airport?.getCenter()}
      />
      <Routes>
        <Route path="/traces/:registrationNumber/:traceUid" element={<MapCard> <TraceDetail /> </MapCard>} />
        <Route path="/traces/:query" element={<ExploreTraces />} />
        <Route path="/airports/:query" element={<ExploreTraces />} />
        <Route path="/my-flights" element={<MapCard> <ExploreFlights /> </MapCard>} />
        <Route path="/pilots" element={<MapCard> <ExploreFlights /> </MapCard>} />
        <Route path="/users/:userId" element={<MapCard> <ExploreFlights /> </MapCard>} />
      </Routes>


    </Layout>
  );
}


export const ToolbarButton = (props) => {
  const { children, icon, color, sx, ...other } = props;

  return (
    props.color === 'primary' ?

      <Button
        size="small"
        variant="contained"
        color="primary" sx={{ textWrap: 'nowrap', ml: 1, boxShadow: 0, ...sx }}
        startIcon={icon}
        {...other}
      >
        {children}
      </Button>

      :

      <Button
        size="small"
        variant="outlined"
        sx={{ textWrap: 'nowrap', ml: 1, boxShadow: 0, backgroundColor: 'white', '&:hover': { backgroundColor: 'white' }, ...sx }}
        startIcon={icon}
        {...other}
      >
        {children}
      </Button>
  );
}
