import { Chip } from '@mui/material';


export default function TailNumberChip(props) {
  const { tailNumber, sx, ...otherProps } = props;
  return (
    <Chip
      label={tailNumber}
      size="large"
      sx={{
        borderBottomLeftRadius: 0,
        borderTopRightRadius: 0,
        fontWeight: 'bold',
        fontSize: 22,
        margin: '4px 8px',
        backgroundColor: 'rgba(60, 60, 60, 0.04)',
        border: '1px solid #ddd',
        mt: 1,
        mb: 2,
        ml: 0,
        ...sx
      }}
    />
  )
}