import Tooltip from '@mui/material/Tooltip';
import { AUDIENCE_LABELS, AUDIENCES_PRIVATE } from 'Models/Audiences';


export default function VisibilityTip({ subject, audience }) {
  // const Icon = audience === AUDIENCES_PRIVATE ? VisibilityOffOutlined : VisibilityOutlined;
  const Icon = AUDIENCE_LABELS[audience].icon;
  const tip = `${subject} ${subject.endsWith('s') ? 'are' : 'is'} visible to ${AUDIENCE_LABELS[audience].visibleTo}.`;

  return (
    <Tooltip title={tip}>
      <Icon
        sx={{ fontSize: '0.9rem', verticalAlign: 'middle', color: "GrayText", ml: 1 }}
      />
    </Tooltip>
  )
}

