import React, { useState } from "react";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import StyledInput from "./StyledInput";

const StyledPassword = ({
    id = "password",
    label = "Password",
    value,
    onChange,
    color,
    labelColor,
    borderColor,
    backgroundColor,
    ...props
  }) => {
    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () => setShowPassword((prev) => !prev);

    return (
        <StyledInput
            id={id}
            label={label}
            value={value}
            onChange={onChange}
            type={showPassword ? "text" : "password"}
            color={color}
            labelColor={labelColor}
            borderColor={borderColor}
            backgroundColor={backgroundColor}
            extInputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton onClick={toggleShowPassword} edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            {...props}
        />
    );
};

export default StyledPassword;
