import { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { getPosts } from "Posts/PostsApi";
import Post from "Posts/Post";
import LikeProvider from "Likes/LikeProvider";
import CommentProvider from "Comments/CommentProvider";
import { useAuth } from "Auth/AuthProvider";
import PostSkeleton from "Posts/PostSkeleton";
import Pagination from "@mui/material/Pagination";


function ProfilePosts({ profile }) {
  const { user, authHeaders } = useAuth();


  const perPage = 5;
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [posts, setPosts] = useState([]);
  const [count, setCount] = useState(0);
  const [ didDeletePostId, setDidDeletePostId ] = useState(null);
  const isCurrentUser = profile.userId === user?.id;


  useEffect(() => {
    const params = {
      userId: isCurrentUser ? null : profile.userId,
      limit: perPage,
      offset: page * perPage
    };

    getPosts(params, authHeaders)
        .then(({posts, count}) => {
          setPosts(posts);
          setCount(count);
        })
        .finally(() => setIsLoading(false));

  }, [page, didDeletePostId]);

  if (isLoading) {
    return <PostSkeleton />
  }

  return (<>


    <LikeProvider>
      <CommentProvider>
        {
          posts.map(post => (
            <Post
              key={post.id}
              post={post}
              currentUserId={user.id}
              showDelete={isCurrentUser}
              onDeletePost={() => setDidDeletePostId(post.id)}
            />
          ))
        }

        {
          count > perPage &&
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Pagination
              size="small"
              count={Math.ceil(count / perPage)}
              color="primary"
              page={page + 1}
              onChange={(e, page) => {
                setPage(page - 1);
                document.body.parentElement.scrollTo({ top: 0, behavior: 'smooth' });
              }}
              sx={{ justifyContent: 'center' }}
            />
          </Box>
        }

      </CommentProvider>
    </LikeProvider>



    {
      posts.length === 0 && (
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <Typography variant="caption">No posts yet.</Typography>
        </Box>
      )
    }

  </>
  );
}

export default ProfilePosts;
