import axios from 'axios';
import AircraftModel from 'Models/AircraftModel';




export const getAircraftByRegistrationNumbers = (registrationNumbers, authHeaders) => {
  if(Array.isArray(registrationNumbers)) {
    registrationNumbers = registrationNumbers.join(',');
  }

  return axios.get(
    `/aircrafts/find_by_registration_numbers.json?registration_numbers=${registrationNumbers}`,
    authHeaders
  )
    .then(response => {
      const { aircrafts } = response.data;
      return aircrafts.map(r => new AircraftModel(r));
    });
}