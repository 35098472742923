import { ListItemButton, ListItemText, ListItemAvatar, Avatar } from '@mui/material';
import { Link } from 'react-router-dom';
import Piper from 'Aircraft/Piper';
import Cessna from 'Aircraft/Cessna';

export default function AircraftOption({ aircraft, onClick = () => {} }) {
  return (
    <ListItemButton
      key={aircraft.id}
      component={Link}
      to={`/explore/traces/${aircraft.registrationNumber}`}
      onClick={onClick}
    >
      <ListItemAvatar>
        <Avatar sx={{ width: 32, height: 32, bgcolor: 'transparent', borderRadius: 0 }}
        >
          {aircraft.manufacturer.toLowerCase().includes('piper') ? <Piper /> : <Cessna />}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={aircraft.registrationNumber}
        secondary={aircraft.manufacturer + ' ' + aircraft.model}
      />
    </ListItemButton>
  );
}