import NavMenu from 'NavMenu/NavMenu';
import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

const Layout = ({ children, maxWidth, disableGutters, backgroundColor }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <NavMenu />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 0,
          overflow: 'hidden',
          backgroundColor: backgroundColor || "#F9F8F5",
          position: "relative",
          display: "flex",
        }}
      >
        <Container
          maxWidth={maxWidth}
          disableGutters={disableGutters}
          className="Layout-container"
          sx={{
            p: 0,
            display: "flex",
            flexDirection: "column", flex: 1, minHeight: '100%'
          }}>{children}</Container>
      </Box>
    </Box>
  );
};

export default Layout;