// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import AuthProvider from './Auth/AuthProvider';
import SignIn from './Auth/SignIn';
import SignUp from './Auth/SignUp';
import SignOut from './Auth/SignOut';
import MyProfile from './Profile/MyProfile';
import MyProfileEdit from './Profile/MyProfileEdit';
import Feed from './Feed/Feed';
// import FlightMap from './FlightMap/FlightMap';
import AirportsAutocomplete from './FlightMap/Flights/AirportsAutocomplete';
import Snackbar from 'Lib/Snackbar';
import Logbook from 'Logbook/Logbook';
import LogbookCreatePage from 'Logbook/LogbookCreatePage';
import CssBaseline from "@mui/material/CssBaseline";
import MyProfileProvider from 'Profile/MyProfileProvider';
import ErrorBoundary from 'Lib/ErrorBoundary';
import FlightView from 'Flights/FlightView';
import FlightCreate from 'FlightMap/Flights/FlightCreate';
import FlightProvider from 'Flights/FlightProvider';
import FlightMapProvider from 'FlightMap/FlightMapProvider';
import EditFlightDetail from 'FlightMap/Flights/FlightEdit';
import Explore from 'Explore/Explore';
import ResetPassword from 'Auth/Reset';
import NotMyProfile from 'Profile/NotMyProfile';
import ConfirmAccount from "./Auth/ConfirmAccount";
import SearchProvider from 'Search/SearchProvider';
import Search from 'Search/Search';
import CommentProvider from 'Comments/CommentProvider';
import PostNew from 'Posts/PostNew';
import PostEdit from 'Posts/PostEdit';
import FlightMapImage from 'FlightMap/Flights/FlightMapImage';
import LikeProvider from 'Likes/LikeProvider';
import Settings from 'Settings/Settings';
import './App.css'
import Notifications from 'Notifications/Notifications';
import NotificationsProvider from 'Notifications/NotificationsProvider';
import PostView from 'Posts/PostView';
import './monkeyPatch';

function App() {

  if (/iPhone|iPad|iPod/.test(navigator.userAgent) && !window.MSStream) {
    document.body.style.paddingBottom = 'calc(env(safe-area-inset-bottom) + 40px)';
  }

  return (
    <ErrorBoundary>
      <Snackbar>
        <CssBaseline />
        <Router>
          <AuthProvider>
            <MyProfileProvider>
              <NotificationsProvider>
                <SearchProvider>
                  <Routes>

                    <Route path="/edit-airports" element={<AirportsAutocomplete />} />
                    <Route path="/sign-in" element={<SignIn />} />
                    <Route path="/sign-up" element={<SignUp />} />
                    <Route path="/sign-out" element={<SignOut />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                    <Route path="/confirm-account" element={<ConfirmAccount />} />

                    <Route path="/" element={<Feed />} />
                    <Route path="/posts/new" element={<PostNew />} />
                    <Route path="/posts/:postId" element={<PostView />} />
                    <Route path="/posts/:postId/edit" element={<PostEdit />} />
                    <Route path="/explore/*" element={
                      <FlightMapProvider><Explore /></FlightMapProvider>} />

                    <Route path="flights/:flightId/map-image" element={
                      <FlightMapProvider>
                        <FlightMapImage />
                      </FlightMapProvider>
                    } />

                    <Route path="/flights/new" element={<FlightCreate />} />

                    <Route path="/flights/:flightId" element={
                      <CommentProvider>
                        <LikeProvider>
                          <FlightProvider>
                            <FlightMapProvider>
                              <FlightView />
                            </FlightMapProvider>
                          </FlightProvider>
                        </LikeProvider>
                      </CommentProvider>
                    } />

                    <Route path="/flights/:flightId/edit" element={<EditFlightDetail />} />

                    <Route path="/profile/edit" element={<MyProfileEdit />} />
                    <Route path="/profile/*" element={<MyProfile />} />
                    <Route path="/profiles/:userId/*" element={<NotMyProfile />} />
                    /
                    <Route path="/logbook/page/new" element={<LogbookCreatePage />} />
                    <Route path="/logbook/page/:pageBaseOne" element={<Logbook />} />

                    <Route path="/logbook" element={<Navigate to="/logbook/page/1" />} />

                    <Route path="/search" element={<Search />} />

                    <Route path="/notifications" element={<Notifications />} />

                    <Route path="/settings" element={<Settings />} />
                  </Routes>
                </SearchProvider>
              </NotificationsProvider>
            </MyProfileProvider>
          </AuthProvider>
        </Router>
      </Snackbar>
    </ErrorBoundary>
  );
}

export default App;
