import React from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import { useFlightMap } from "FlightMap/FlightMapProvider";
import { useState, useEffect } from "react";
import logger from "Lib/logger";
import './BaseMap.css';
import { drawFlightSegmentsAndAirports, removeFlightCurves, removeAirportMarkers } from './flightSegmentsAndAirports';
import { drawTraces, removeTraceLines } from './traceLines';
import { getFlightSegmentsAndAirports } from './BaseMapApi';
import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material';
import * as protomapsL from 'protomaps-leaflet';
import LayersButtonAndMenu from "BaseMap/LayersButtonAndMenu";
import themes from './mapThemes';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import AirplaneBrush from './AirplaneBrush';
import GpsFixed from '@mui/icons-material/GpsFixed';
import IconButton from '@mui/material/IconButton';
import MapVisuals from './MapVisuals';


const BaseMap = function ({
  mapTheme = 'light',
  disableSidebarPadding = false,
  children,
  drawCurves = true,
  hideAirportLabels = false,
  maxZoom = 15,
  showLayersButton = true,
  initialCenter = [37.94, -95.967],
  useBrush = false,
  // useVisuals = false,
}) {

  const theme = useTheme();

  const {
    mapRef,
    mapTraces,
    mapFlights,
    isVfrLayerVisible,
    isIfrLayerVisible,
  } = useFlightMap();


  logger('mapTraces', mapTraces);
  logger('mapFlights', mapFlights);

  const isPhone = useMediaQuery(theme.breakpoints.down('sm'));

  // Cached references to map objects used for cleanup
  const [traceLines, setTraceLines] = useState([]);
  const [flightCurves, setFlightCurves] = useState([]);
  const [airportMarkers, setAirportMarkers] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [bounds, setBounds] = useState(null);
  const [currentZoom, setCurrentZoom] = useState(10);

  // Add BaseLayer from Protomaps
  useEffect(() => {

    const baseLayer = protomapsL.leafletLayer({
      url: 'https://storage.googleapis.com/deadstick-public/proto/20240729.pmtiles',
      paintRules: protomapsL.paintRules(mapTheme === 'light' ? themes.light : themes.dark),
      opacity: 0.8,
    });



    waitForMap(mapRef, () => {
      baseLayer.addTo(mapRef.current);
      setIsLoaded(true);

      mapRef.current.on('zoomend', (e) => {
        logger('zoomend', e.target.getZoom());
        setCurrentZoom(e.target.getZoom());
      });
    });
  }, [mapRef.current]);

  const fitBounds = (newBounds) => {
    if (!mapRef.current) {
      return;
    }

    setBounds(newBounds);
    mapRef.current.fitBounds(newBounds, getPaddingAndZoom(disableSidebarPadding, isPhone, maxZoom));
  }


  const draw = () => {
    const map = mapRef.current;

    if (!map) return;
    console.log('Drawing BaseLayer');

    // Traces
    removeTraceLines(traceLines);

    drawTraces(mapTraces, map, (data) => {
      // Cache references to map objects for cleanup
      setTraceLines(data.traceLines);
      if (data.bounds && data.bounds.isValid()) {
        fitBounds(data.bounds);
      }
    });

    // Flights
    removeFlightCurves(flightCurves);
    removeAirportMarkers(airportMarkers);

    getFlightSegmentsAndAirports(mapFlights)
      .then(({ flightSegments, airports }) => {
        drawFlightSegmentsAndAirports({ flightSegments, airports, drawCurves }, map, (data) => {
          // Cache references to map objects for cleanup
          setFlightCurves(data.flightCurves);
          setAirportMarkers(data.airportMarkers);
          if (data.bounds && data.bounds.isValid()) {
            fitBounds(data.bounds);
          }
        });
      });

  }

  // Draw
  useEffect(() => {
    waitForMap(mapRef, draw);
  }, [mapRef, mapTraces, mapFlights]);


  const classNames = [mapTheme]
  if (hideAirportLabels) {
    classNames.push('hide-airport-labels');
  }



  return (
    <Box>
      {
        showLayersButton &&isPhone && <>
          <Box sx={{ display: 'flex', position: 'absolute', zIndex: 1000, top: 10,  right: 10 }} >
              <LayersButtonAndMenu sx={{ ml: 0, mt: 1 }} />
              <IconButton
                size="small"
                sx={{ ml: 1 }}
                onClick={() => fitBounds(bounds)}
              >
                <GpsFixed />
              </IconButton>
          </Box>
        </>
      }

      {
        showLayersButton && !isPhone && <>
          <Box sx={{ display: 'flex', position: 'absolute', zIndex: 1000, top: 10, right: 10 }} >
            {/* <Toolbar variant="dense" sx={{ flex: 1, justifyContent: 'flex-end' }}> */}
              <LayersButtonAndMenu />
              <IconButton
                size="small"
                sx={{ ml: 1 }}
                onClick={() => fitBounds(bounds)}
              >
                <GpsFixed />
              </IconButton>
            {/* </Toolbar> */}
          </Box>
        </>
      }

      <MapContainer
        className={[`zoom-${currentZoom}`].concat(classNames).join(' ')}
        ref={mapRef}
        center={initialCenter}
        zoom={10}
        style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
        zoomControl={false}
      >

        {isVfrLayerVisible &&
          <TileLayer
            url="https://storage.googleapis.com/deadstick-public/us_tiles/{z}/{x}/{y}.webp"
            tms={true}
            updateWhenZooming={false}
            updateWhenIdle={true}
            detectRetina={true}
          />
        }

        {isIfrLayerVisible &&
          <TileLayer
            url="https://storage.googleapis.com/deadstick-public/US_IFR_Tiles/{z}/{x}/{y}.webp"
            tms={true}
            updateWhenZooming={false}
            updateWhenIdle={true}
            detectRetina={true}
          />
        }

        {children}

        { useBrush && <AirplaneBrush mapRef={mapRef} isLoaded={isLoaded} /> }


        {/* { useVisuals && <MapVisuals mapRef={mapRef} isLoaded={isLoaded} /> } */}

        <svg className='base-map' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" />
        <DropShadowFilter />
      </MapContainer>
    </Box>
  );
}


export default BaseMap;



const waitForMap = (mapRef, callback) => {
  // Wait for map to be ready
  let tries = 0;
  const checkMap = () => {
    if (!mapRef.current) {
      console.warn('Map not ready');

      if (tries < 10) {
        setTimeout(checkMap, 100);
      }
      else {
        console.error('Map not ready after 10 tries!!!');
      }

      tries += 1;
      return;
    }

    callback();
  }

  checkMap();
};






const DropShadowFilter = () => (
  /* Dropshadow Filter */
  <svg height="0" xmlns="http://www.w3.org/2000/svg">
    <filter id="dropshadow" height="130%">
      <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
      <feOffset dx="2" dy="2" result="offsetblur" />
      <feComponentTransfer>
        <feFuncA type="linear" slope="0.5" />
      </feComponentTransfer>
      <feMerge>
        <feMergeNode />
        <feMergeNode in="SourceGraphic" />
      </feMerge>
    </filter>
  </svg>
)




const getPaddingAndZoom = (disableSidebarPadding, isPhone, maxZoom = 15) => {
  if (disableSidebarPadding) {
    return {
      paddingTopLeft: [0, 0],
      paddingBottomRight: [0, 0],
      maxZoom
    }
  }

  return isPhone ? {
    paddingTopLeft: [0, 0],
    paddingBottomRight: [0, 320],
    maxZoom
  } : {
    paddingTopLeft: [300, 25],
    paddingBottomRight: [25, 25],
    maxZoom
  }

}



const labelRules = {
  light: [
    {
      dataLayer: "places",
      symbolizer: new protomapsL.CenteredTextSymbolizer({
        label_props: ["name:en"],
        fill: "transparent",
        stroke: "transparent",
        width: 0,
      })
    }
  ],
  dark: [
    {
      dataLayer: "places",
      symbolizer: new protomapsL.CenteredTextSymbolizer({
        label_props: ["name:en"],
        fill: "transparent",
        stroke: "transparent",
        width: 0,
      })
    }
  ]
};



const usBounds = [
  [24.396308, -125.0],  // Southwest corner
  [49.384358, -66.93457] // Northeast corner
];