import BaseModel from "Lib/BaseModel";
import ProfileModel from "Models/ProfileModel";
export default class NotificationModel extends BaseModel {

  constructor(props) {
    super(props);

    this.profile = new ProfileModel(this.profile || {});

  }

  getLink() {
    let notifiableType = this.notifiableType;
    let notifiableId = this.notifiableId;

    if (this.notifiableType === 'Comment') {
      notifiableType =  this.data.commentable_type;
      notifiableId = this.data.commentable_id;
    }

    return `/${notifiableType?.toLowerCase()}s/${notifiableId}`;
    // switch (this.notifiableType) {
    //   case 'Post':
    //     return `/posts/${this.notifiableId}`;
    //   case 'Comment':
    //     return `/posts/${this.notifiableId}`;
    //   default:
    //     return ``;
    // }
  }


  getMessage(){
    let message = '';

    const { actors_count } = this.data;

    if(actors_count > 1){
      const others = actors_count - 1;
      if (others === 1){
        message = ` and ${others} other pilot `;
      } else {
        message = ` and ${others} others `;
      }
    }


    if(this.action === 'commented'){
      message += ` commented on your ${ this.notifiableType.toLowerCase() }.`;
    }
    else if(this.action === 'liked'){
      message += ` liked your ${ this.notifiableType.toLowerCase() }.`;
    }
    else if(this.action === 'paired'){
      message += ` is now flying with you.`;
    }
    else if(this.action === 'mentioned'){
      message += ` mentioned you in their ${ this.notifiableType.toLowerCase() }.`;
    }

    return message;
  }

  markAsRead(){
    this.isRead = true;
  }

}

