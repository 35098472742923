import { Skeleton, Box } from '@mui/material';

function PostSkeleton({style}) {
  return (
    <Box sx={rootStyles} style={style}>
      <Box sx={headerStyle}>
        <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', gap: 2 }}>
          <Skeleton variant="circular" height={40} width={40} />
          <Box sx={{ flex: 1 }} >
            <Skeleton variant="text" height={20} width="100%" />
            <Skeleton variant="text" height={20} width="100%" />
          </Box>
        </Box>
      </Box>

      <Skeleton variant="rectangular" height={627} />

    </Box>
  );

}


const rootStyles = {
  maxWidth: 470,
  mt: 2,
  mb: 4,
  ml: 'auto',
  mr: 'auto',
  position: 'relative',
};


const headerStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: 2,
  mb: 1,
  flex: 0,
  pl: 1, pr: 1
};


export default PostSkeleton;