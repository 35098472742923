import { useState } from "react";
import { Grid, LinearProgress, Typography, Container, Box, Button, CardContent, Tabs, Tab, Badge } from "@mui/material";
import Layout from "Layout/Layout";
import { Link } from "react-router-dom";
import { useAuth } from "Auth/AuthProvider";
import { formatCount, formatDate, formatHours, shortDateFormat } from "Lib/format";
import logger from "Lib/logger";
import FlightPost from "Feed/FlightPost";
import MedicalTracker from "./MedicalTracker";
import ProfileBanner from "./ProfileBanner";
import './Profile.css';
import { AirplanemodeActive, ViewDay, Contacts, Groups, AirplaneTicket, Radar, TrackChanges } from "@mui/icons-material";
import { Routes, Route, useLocation } from "react-router-dom";
import ProfilePosts from "./ProfilePosts";
import ProfileFlights from "./ProfileFlights";
import ProfileFlyingWith from "./ProfileFlyingWith";

export default function Profile({ profile, userPair, isLoadingProfile, flightStats, onCreateUserPair }) {
  document.title = "Profile | WingJockey";

  const { user } = useAuth();

  const isCurrentUser = profile.userId === user?.id;

  const flightStatsFlat = flattenObject(flightStats);
  logger('flightStatsFlat', flightStatsFlat);


  return (
    <Layout disableGutters>
          <>
            <ProfileBanner
              currentUserId={user?.id}
              profile={profile}
              flightStats={flightStats}
              isCurrentUser={isCurrentUser}
              userPair={userPair}
              onCreateUserPair={onCreateUserPair}
            />
            <Container disableGutters maxWidth="lg" sx={{ mb: 6 }} style={{ backgroundColor: "#F9F8F5", borderTop: '1px solid #f1f1f1' }} >
              <Grid container spacing={0} >

                {/* {
                   isCurrentUser && (


                    <Grid item xs={12} md={4}>
                      <BackgroundHeading sx={{ mt: 1 }}>Stats</BackgroundHeading>

                      <WjCard sx={{ mt: 1 }}>
                        <CardContent>
                          <Typography component="div" variant="caption" sx={{ mb: 1, fontSize: '0.65rem' }}>Totals</Typography>
                          {
                            totalStats.map(stat => (
                              <Stat
                                key={stat.label}
                                label={stat.label}
                                metric={flightStatsFlat[stat.key]}
                                format={stat.format}
                              />
                            ))
                          }

                          <Typography component="div" variant="caption" sx={{ mb: 1, mt: 3, fontSize: '0.65rem' }}>Currency</Typography>
                          {
                            currencyStats.map(stat => (
                              <Stat
                                key={stat.label}
                                label={stat.label}
                                metric={flightStatsFlat[stat.key]}
                                format={stat.format}
                              />
                            ))
                          }

                          <Typography component="div" variant="caption" sx={{ mb: 1, mt: 3, fontSize: '0.65rem' }}>Progress</Typography>
                          {
                            progressStats.map(stat => (
                              <Stat
                                key={stat.label}
                                label={stat.label}
                                metric={flightStatsFlat[stat.key]}
                                format={stat.format}
                              />
                            ))
                          }


                          <Typography component="div" variant="caption" sx={{ mb: 1, mt: 3, fontSize: '0.65rem' }}>Instrument Progress</Typography>
                          {
                            instrumentProgress.map(stat => (
                              <Requirement
                                key={stat.label}
                                label={stat.label}
                                required={stat.required}
                                metric={flightStatsFlat[stat.key]}
                                format={stat.format}
                              />
                            ))
                          }



                        </CardContent>
                      </WjCard>


                      <BackgroundHeading sx={{ mt: 3 }}>Flight Review</BackgroundHeading>

                      <WjCard sx={{ mt: 1, p: 2 }}>

                        <Stat label="Last Checkride or Review" metric={profile.flightReviewDate || profile.privatePilotDate} format={shortDateFormat} />

                        <Stat label="Flight Review Due" metric={profile.flightReviewExpirationDate} format={shortDateFormat} />

                        <Stat label="Flight Review Days" metric={profile.getFlightReviewRemainingDays()} format={formatCount} />

                      </WjCard>

                      <BackgroundHeading sx={{ mt: 3 }}>Medical</BackgroundHeading>
                      <MedicalTracker profile={profile} />

                    </Grid>

                  )
                } */}

                <Grid item xs={12}>
                  <ProfileNavTabs isCurrentUser={isCurrentUser} userId={profile.userId} />
                </Grid>

                <Grid item xs={12}>
                  <Routes>
                    <Route path="" element={
                      <ProfilePosts profile={profile} />}
                    />

                    <Route path="/flights" element={
                      <ProfileFlights profile={profile} />
                    } />

                    <Route path="/flying-with" element={
                      <ProfileFlyingWith isCurrentUser={isCurrentUser} profile={profile} />
                    } />


                  </Routes>



                </Grid>

              </Grid>


            </Container>
          </>
    </Layout>
  )

}



function ProfileNavTabs({ isCurrentUser, userId }) {
  const basePath = isCurrentUser ? '/profile' : `/profiles/${userId}`;

  const tabs = [
    { to: `${basePath}`, icon: <ViewDay />, label: 'Posts' },
    { to: `${basePath}/flights`, icon: <AirplanemodeActive sx={{ transform: 'rotate(45deg)' }} />, label: 'Flights', },
    { to: `${basePath}/flying-with`, icon: <TrackChanges />, label: 'Pilots' },
  ];

  const sx = { fontSize: '0.7rem', fontWeight: 'bold', pt: 0, pb: 0 };

  const { pathname } = useLocation();

  const value = tabs.findIndex(t => t.to.replace(basePath, '') === pathname.replace(basePath, ''));

  return (
    <>
      {/* Small screen */}
      <Tabs value={value} centered sx={{ display: { xs: 'block', md: 'none' } }}>
        {tabs.map(t => (
          <Tab key={t.to} component={Link} sx={sx} {...t} />
        ))}
      </Tabs>

      {/* Medium screen and up */}
      <Tabs value={value} centered sx={{ display: { xs: 'none', md: 'block' } }}>
        {tabs.map(t => (
          <Tab key={t.to} component={Link} iconPosition="start" sx={sx} {...t} />
        ))}
      </Tabs>
    </>
  )
}


function BackgroundHeading(props) {
  return (
    <Typography
      variant="body1"
      sx={{ color: '#999', fontSize: '0.75rem', fontWeight: 'bold', ...props.sx }}
    >
      {props.children}
    </Typography>
  )
}

export function Stat({ label, metric, format }) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', mb: 0.5 }}>
      <Typography component="div" sx={{ flex: 2 }} variant="body2">
        {label}
      </Typography>
      <Typography component="div" sx={{ flex: 1, textAlign: 'right' }} variant="body2">
        {format ? format(metric) : metric}
      </Typography>
    </Box>
  );
}



export function Requirement({ label, metric, format, required }) {
  const remaining = Math.max(required - metric, 0);
  return (
    <Box sx={{ mb: 0.5 }}>
      <Typography component="div" sx={{ flex: 2 }} variant="body2">
        {label}
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row', mb: 0.5 }}>
        <Typography component="div" sx={{ flex: 1, textAlign: 'left' }} variant="body2">
          {format(remaining)}
        </Typography>
        <Typography component="div" sx={{ flex: 1, textAlign: 'right' }} variant="body2">
          {format ? format(metric) : metric} / {formatCount(required)}
        </Typography>
      </Box>
      <div>
        <LinearProgress variant="determinate" value={100 - (remaining / required * 100)} />
      </div>
    </Box>
  );
}


const totalStats = [
  { label: 'Flights', key: 'flightCount', format: formatCount },
  { label: 'Hours', key: 'totals.totalHours', format: formatHours },
  { label: 'Landings', key: 'totals.totalLandingsCount', format: formatCount },
  { label: 'Approaches', key: 'totals.instrumentApproachesCount', format: formatCount },
];

const currencyStats = [
  { label: 'Landings (90d)', key: 'totals.currentSelLandingsCount', format: formatCount },
  { label: 'Night Landings (90d)', key: 'totals.currentSelNightLandingsCount', format: formatCount },
  { label: 'Approaches (6m)', key: 'totals.currentInstrumentApproachesCount', format: formatCount },
];

const progressStats = [
  { label: 'Solo', key: 'totals.soloHours', format: formatHours },
  { label: 'Cross Country', key: 'totals.crossCountryHours', format: formatHours },
  { label: 'Pilot-in-Command', key: 'totals.pilotInCommandHours', format: formatHours },
  { label: 'Cross Country PIC', key: 'totals.pilotInCommandCrossCountryHours', format: formatHours },
  { label: 'Instrument', key: 'totals.totalInstrumentHours', format: formatHours },
  { label: 'Dual Instrument', key: 'totals.dualInstrumentHours', format: formatHours },
];


const instrumentProgress = [
  { label: 'Cross Country PIC', key: 'totals.pilotInCommandCrossCountryHours', format: formatHours, required: 50 },
  { label: 'Instrument', key: 'totals.totalInstrumentHours', format: formatHours, required: 40 },
  { label: 'Dual Instrument', key: 'totals.dualInstrumentHours', format: formatHours, required: 15 },
];




function flattenObject(ob) {
  var toReturn = {};

  for (var i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if ((typeof ob[i]) == 'object' && ob[i] !== null) {
      var flatObject = flattenObject(ob[i]);
      for (var x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        toReturn[i + '.' + x] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
}