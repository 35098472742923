import React, { useState } from 'react';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';
import { TextField, Button, Container, Typography, Box, IconButton, InputAdornment } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'Lib/Snackbar';
import { useAuth } from './AuthProvider';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const ResetPassword = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [searchParams] = useSearchParams();
    const resetToken = searchParams.get('token'); // Extract the token from the query params
    const navigate = useNavigate();
    const { showSnackbar } = useSnackbar();
    const { requestReset, resetPassword } = useAuth();

    const handleResetRequest = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            const response = await requestReset(email);
            if (response.data.success) {
                showSnackbar('Password reset email sent', { severity: 'success' });
                navigate('/sign-in', { replace: true });
            } else {
                showSnackbar('Failed to send reset email', { severity: 'error' });
            }
        } catch (error) {
            console.error('Error sending reset email:', error);
            showSnackbar('An error occurred. Please try again.', { severity: 'error' });
        } finally {
            setIsLoading(false);
        }
    };

    const handlePasswordReset = async (e) => {
        e.preventDefault();

        if (password !== passwordConfirmation) {
            showSnackbar('Passwords do not match', { severity: 'error' });
            return;
        }

        try {
            setIsLoading(true);
            const response = await resetPassword(resetToken, password, passwordConfirmation);
            if (response.data.success) {
                showSnackbar('Password successfully reset', { severity: 'success' });
                navigate('/sign-in', { replace: true });
            } else {
                showSnackbar('Failed to reset password', { severity: 'error' });
            }
        } catch (error) {
            console.error('Error resetting password:', error);
            showSnackbar(error.response.data.errors[0], { severity: 'error' });
        } finally {
            setIsLoading(false);
        }
    };

    const toggleShowPassword = () => setShowPassword((prev) => !prev);
    const toggleShowConfirmPassword = () => setShowConfirmPassword((prev) => !prev);

    return (
        <Box
            sx={{
                backgroundImage: `linear-gradient(254.82deg, rgba(0, 0, 0, 0.8) -0.81%, rgba(18, 10, 10, 0.664) 69.22%, rgba(117, 81, 23, 0.8) 102.79%), url(/auth/auth2.png)`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        color: 'white',
                        textAlign: 'center',
                    }}
                >
                    {!resetToken ? (
                        <>
                            <Typography component='h1' variant='h4' sx={{ fontWeight: 'bold', mb: 2 }}>
                                Forgot Password?
                            </Typography>
                            <Typography variant='subtitle1' sx={{ mb: 3, color: '#D2D2D2' }}>
                                No worries, we'll send you reset instructions
                            </Typography>

                            <Box component="form" onSubmit={handleResetRequest} sx={{ mt: 1 }}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    autoFocus
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    InputLabelProps={{
                                        sx: {
                                            "&.MuiInputLabel-shrink": {
                                                color: "#ff6b03",
                                                padding: "0 10px",
                                                borderRadius: "4px",
                                                transform: "translate(5px, 2px) scale(0.75)"
                                            },
                                            color: "#ff6b03",
                                        },
                                    }}
                                    InputProps={{
                                        style: {
                                            color: '#212121',
                                            backgroundColor: '#F1F6FF',
                                        },
                                        sx: {
                                            "& .MuiOutlinedInput-input": {
                                                padding: "21px 14px 12px",
                                            },
                                        },
                                    }}
                                    sx={{
                                        mb: 2,
                                        "& .MuiOutlinedInput-root": {
                                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "transparent",
                                            },
                                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "#ff6b03",
                                                borderWidth: "0 0 3px 0",
                                            },
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "transparent",
                                            },
                                        },
                                        "& .MuiInputLabel-root": {
                                            color: "#ff6b03",
                                        },
                                        "& .MuiInputLabel-root.Mui-focused": {
                                            color: "#ff6b03",
                                        },
                                    }}
                                />

                                <LoadingButton
                                    loading={isLoading}
                                    loadingIndicator="Sending..."
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Continue
                                </LoadingButton>
                                <Button
                                    component={Link}
                                    to="/sign-in"
                                    fullWidth
                                    variant="text"
                                    sx={{ mt: 1, mb: 1 }}
                                >
                                    Back to Sign In
                                </Button>
                            </Box>
                        </>
                    ) : (
                        <>
                            <Typography component="h1" variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
                                Set new Password
                            </Typography>
                            <Typography variant='subtitle1' sx={{ mb: 3, color: '#D2D2D2' }}>
                                Must be at least 8 characters
                            </Typography>
                            <Box component="form" onSubmit={handlePasswordReset} sx={{ mt: 1 }}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="New Password"
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    InputLabelProps={{
                                        sx: {
                                            "&.MuiInputLabel-shrink": {
                                                color: "#ff6b03",
                                                padding: "0 10px",
                                                borderRadius: "4px",
                                                transform: "translate(5px, 2px) scale(0.75)"
                                            },
                                            color: "#ff6b03",
                                        },
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={toggleShowPassword} edge="end">
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        style: {
                                            color: '#212121',
                                            backgroundColor: '#F1F6FF',
                                        },
                                        sx: {
                                            "& .MuiOutlinedInput-input": {
                                                padding: "21px 14px 12px",
                                            },
                                        },
                                    }}
                                    sx={{
                                        mb: 2,
                                        "& .MuiOutlinedInput-root": {
                                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "transparent",
                                            },
                                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "#ff6b03",
                                                borderWidth: "0 0 3px 0",
                                            },
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "transparent",
                                            },
                                        },
                                        "& .MuiInputLabel-root": {
                                            color: "#ff6b03",
                                        },
                                        "& .MuiInputLabel-root.Mui-focused": {
                                            color: "#ff6b03",
                                        },
                                    }}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="passwordConfirmation"
                                    label="Confirm New Password"
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    id="passwordConfirmation"
                                    value={passwordConfirmation}
                                    onChange={(e) => setPasswordConfirmation(e.target.value)}
                                    InputLabelProps={{
                                        sx: {
                                            "&.MuiInputLabel-shrink": {
                                                color: "#ff6b03",
                                                padding: "0 10px",
                                                borderRadius: "4px",
                                                transform: "translate(5px, 2px) scale(0.75)"
                                            },
                                            color: "#ff6b03",
                                        },
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={toggleShowConfirmPassword} edge="end">
                                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        style: {
                                            color: '#212121',
                                            backgroundColor: '#F1F6FF',
                                        },
                                        sx: {
                                            "& .MuiOutlinedInput-input": {
                                                padding: "21px 14px 12px",
                                            },
                                        },
                                    }}
                                    sx={{
                                        mb: 2,
                                        "& .MuiOutlinedInput-root": {
                                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "transparent",
                                            },
                                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "#ff6b03",
                                                borderWidth: "0 0 3px 0",
                                            },
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "transparent",
                                            },
                                        },
                                        "& .MuiInputLabel-root": {
                                            color: "#ff6b03",
                                        },
                                        "& .MuiInputLabel-root.Mui-focused": {
                                            color: "#ff6b03",
                                        },
                                    }}
                                />

                                <LoadingButton
                                    loading={isLoading}
                                    loadingIndicator="Resetting..."
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Reset Password
                                </LoadingButton>
                                <Button
                                    component={Link}
                                    to="/sign-in"
                                    fullWidth
                                    variant="text"
                                    sx={{ mt: 1, mb: 1 }}
                                >
                                    Back to Sign In
                                </Button>
                            </Box>
                        </>
                    )}
                </Box>
            </Container>
        </Box>
    );
};

export default ResetPassword;
