import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  useMediaQuery,
  Drawer,
  List,
  ListItemText,
  ListItemButton,
  Box
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { themeData } from 'theme';
import { useAuth } from 'Auth/AuthProvider';
import { useProfile } from 'Profile/MyProfileProvider';
import SignedInNavToolbar from 'NavMenu/SignedInNavToolbar';
import './NavMenu.css';


const ResponsiveAppBar = () => {
  const { user } = useAuth();
  const { profile } = useProfile();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [mobileOpen, setMobileOpen] = React.useState(false);


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    if (!isMobile && mobileOpen) {
      setMobileOpen(false);
    }
  }, [isMobile, mobileOpen]);


  const drawer = (
    <Drawer
      anchor="top"
      open={mobileOpen}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      PaperProps={{
        style: { top: '56px' }, // Adjust the top offset based on AppBar height if necessary
      }}
    >
      <List>
        <NavListItemButton isActive={to => window.location.pathname === '/'} to="/" onClick={handleDrawerToggle}>Home</NavListItemButton>
        {/* <NavListItemButton to="/explore/my-flights" onClick={handleDrawerToggle}>Explore</NavListItemButton> */}
        {/* <NavListItemButton to="/logbook" onClick={handleDrawerToggle}>Logbook+</NavListItemButton> */}

      </List>
    </Drawer>
  );

  return (
    <Box>
      <AppBar elevation={0} position="fixed" style={{ zIndex: theme.zIndex.drawer + 1, flex: 0, backgroundColor: 'white', color: 'inherit', borderBottom: '1px solid #f1f1f1' }}>
        <Toolbar disableGutters variant="dense">
          <Link to="/" style={{ display: 'flex', color: 'inherit' }}>
            <img src="/wj_orange.png" alt="WingJockey Logo" style={{ height: 40, margin: '8px 4px 8px 8px' }} />


            {/* {isMobile ?
              <IconButton
                edge="end"
                aria-label="open drawer"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleDrawerToggle}
                color="primary"
              >
                <img src="/wj_orange.png" alt="WingJockey Logo" style={{ height: 40, marginRight: 4 }} />
              </IconButton>
              :
              <img src="/wj_orange.png" alt="WingJockey Logo" style={{ height: 40, margin: '8px 4px 8px 8px' }} />
            } */}

            <div className="Nav-brand" style={{ fontWeight: '500', fontSize: '1.25rem', flexGrow: 1, textDecoration: 'none', lineHeight: '56px' }}>
              WingJockey
            </div>
          </Link>

          {!isMobile && (
            <div style={{ marginLeft: '20px', flexGrow: 1, display: 'flex' }}>
              {/* <NavButton to="/" isActive={to => window.location.pathname === '/'} >Home</NavButton> */}
              {/* <NavButton to="/explore/my-flights">Explore</NavButton> */}
              {/* <NavButton to="/logbook">Logbook+</NavButton> */}
            </div>
          )}

          <Box sx={{ ml: 'auto', mr: 1 }}>
            {
              user ?
                <SignedInNavToolbar profile={profile} />
                : <>
                  <NavButton to="/sign-in">Login</NavButton>
                  <NavButton to="/sign-up">Sign Up</NavButton>
                </>
            }
          </Box>

        </Toolbar>
      </AppBar>
      {drawer}
      <Toolbar sx={{ minHeight: '56px !important' }} /> {/* Spacer to prevent content from being hidden under the AppBar */}
    </Box>
  );
};

export default ResponsiveAppBar;



const NavButton = (props) => {
  const pathname = window.location.pathname;
  const isActive = props.isActive ? props.isActive(pathname) : pathname.startsWith(props.to);

  return (
    <Button component={Link} to={props.to} color={isActive ? 'primary' : 'inherit'}>
      {props.children}
    </Button>
  );

};


const NavListItemButton = (props) => {
  const pathname = window.location.pathname;
  const isActive = props.isActive ? props.isActive(pathname) : pathname.startsWith(props.to);

  return (
    <ListItemButton style={{ color: isActive ? themeData.palette.primary.main : 'inherit' }} onClick={props.onClick} component={Link} to={props.to}>
      <ListItemText primary={props.children} />
    </ListItemButton>
  );

};



