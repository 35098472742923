import { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import Layout from 'Layout/Layout';
import { useAuth } from 'Auth/AuthProvider';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AirplanemodeInactive from '@mui/icons-material/AirplanemodeInactive';
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import NotificationListItem from './NotificationListItem';
import { useNotifications } from './NotificationsProvider';
import WjCard from 'Lib/WjCard';
import { formatCount } from 'Lib/format';
import Tooltip from '@mui/material/Tooltip';

import './Notifications.css';
import { Drafts } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';


export default function Notifications() {
  const { notifications, isLoadingNotifications, count, unreadCount, onMarkAsRead, refreshNotifications } = useNotifications();

  useEffect(() => {
    refreshNotifications();
  }, []);


  const onClickMarkAllAsRead = () => {
    onMarkAsRead('all');
  }

  const onNotificationClick = (notification) => {
    console.log("onNotificationClick", notification);
    onMarkAsRead([notification.id]);
    refreshNotifications();
  }

  return (
    <Layout>
      <Container sx={{ mb: 6, maxWidth: 470 }} style={{ backgroundColor: "#F9F8F5", borderTop: '1px solid #f1f1f1' }} >

        <Grid container spacing={0} >
          <Grid item xs={12}>
            <Container maxWidth="sm" disableGutters sx={{ ml: 'auto', mr: 'auto', mt: 2 }}>

              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, pl: 1 }}>
                <Box sx={{ flex: 1 }}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    Notifications
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    <span className={unreadCount > 0 ? 'primary-pulse' : 'secondary'}>●</span>
                    &nbsp;
                    {
                      unreadCount > 0 ? `${formatCount(unreadCount)} unread` : `You're all caught up`
                    }
                  </Typography>

                </Box>



                <div style={{ flex: 0 }}>
                  <Tooltip title="Mark all as read">
                    <IconButton size="small" onClick={onClickMarkAllAsRead}>
                      <Drafts />
                    </IconButton>
                  </Tooltip>
                </div>


              </Box>
            </Container>

            <WjCard>
              <List disablePadding>
                {
                  notifications.map(n =>
                  <NotificationListItem
                    notification={n}
                    key={n.id}
                    onClick={() => onNotificationClick(n)}
                    />
                  )
                }
              </List>
            </WjCard>



            {notifications.length === 0 &&
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>

                {
                  isLoadingNotifications && <>
                    <CircularProgress size={24} />
                    <Typography variant="caption" sx={{ mt: 2 }}>
                      Loading...
                    </Typography>
                  </>
                }

              </Box>

            }

          </Grid>
        </Grid>
      </Container>

    </Layout>
  );
}

