import axios from 'axios';
import PostModel from 'Models/PostModel';
import FlightModel from 'Models/FlightModel';

export const getOrSearchFeed = ({q, tag, limit = 5, offset = 0}, authHeaders) => {

  let url = `/feed.json?limit=${limit}&offset=${offset}`;

  if (q) url += `&q=${q}`;
  if (tag) url += `&tag=${tag}`;

  return axios.get(url, authHeaders)
    .then(response => {
      const { data } = response;


      const feed = data.feed.map(f => {
        if(f.feedable_type === 'Post') {
          return new PostModel(f)
        }
        else if (f.feedable_type === 'Flight') {
          return new FlightModel(f)
        }
        else {
          console.log("Unknown feedable type", f);
          throw new Error('Unknown feedable type');
        }
      });
      return { feed, count: data.count };
    });
}