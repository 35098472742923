
import BaseModel from "Lib/BaseModel";
import VisualModel from "Models/VisualModel";
import ProfileModel from "Models/ProfileModel";


export default class PostModel extends BaseModel {
  constructor(data) {
    super(data || {});

    this.profile = new ProfileModel(this.profile || {});
    this.visualUrls = (this.visualUrls || this.visualUrlsWithoutMetadata || []).map(t => new VisualModel(t));

    this.likeableType = 'post';
    this.commentableType = 'post';
  }

  isProcessing(){
    return this.visualsDoneCount < this.visualsCount;
  }

  getCommentableCaption(){
    return this.caption;
  }

}


