import Layout from "Layout/Layout";
import LogbookTable from './LogbookTable';
import { Grid, Box, Typography, Pagination, Button } from '@mui/material';
import { getFlightsWithLogbookEntries, getLogbookTotals, LOGBOOK_ENTRIES_PER_PAGE } from './LogbookApi';
import { useEffect, useState } from "react";
import { useAuth } from "Auth/AuthProvider";
import { Link, useParams } from 'react-router-dom';
import { NoteAdd } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import withAuth from "Auth/withAuth";

function Logbook() {
  const params = useParams();
  const { authHeaders } = useAuth();
  const navigate = useNavigate();
  const [flights, setFlights] = useState([]);
  const [total, setTotal] = useState(null);



  useEffect(() => {
    console.log('Logbook useEffect', params.pageBaseOne);
    const page = parseInt(params.pageBaseOne) - 1;
    getFlightsWithLogbookEntries(page, authHeaders)
      .then(data => {
        console.log('getFlightsWithLogbookEntries', data);
        setFlights(data.flights);
        setTotal(data.total);
      });
  }, [params.pageBaseOne]);



  return (
    <Layout>
      <Box sx={{ p: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6">Logbook+</Typography>
          </Grid>
          <Grid item xs={12}>

            <LogbookTable
              showAddRow={true}
              flights={flights}
              toolbar={
                <Button
                  size="small"
                  component={Link}
                  to="/logbook/page/new"
                  variant="outlined"
                  startIcon={<NoteAdd />}
                  sx={{ ml: 1 }}
                >
                  New Page
                </Button>
              }
            />
            <Box sx={{ m: 1 }}>
              <Pagination
                size="small"
                shape="rounded"
                color="primary"
                count={Math.ceil(total / LOGBOOK_ENTRIES_PER_PAGE)}
                page={+params.pageBaseOne}
                onChange={(e, page) => {
                  navigate(`/logbook/page/${page}`);
                }}
                showFirstButton
                showLastButton
              />

            </Box>

            <Box sx={{ p: 1 }}>
              <Typography variant="caption" color="GrayText" component="div">
                <strong>NOTE:</strong> This logbook is for personal use only. It is not intended to be used for official record keeping.
              </Typography>
            </Box>


          </Grid>
        </Grid>
      </Box>
    </Layout>
  )
}

export default withAuth(Logbook);