import React, { useState, useRef, useEffect } from 'react';
import * as d3 from 'd3';
import clamp from './clamp';
import pinchPolyfill from './pinchPolyfill';



export default class StretchySvg extends React.Component {

  constructor(props){
    super(props);
    pinchPolyfill();
    this.svgRef = React.createRef();
  }

  componentDidMount(){
    this.draw();
  }


  draw = () => {
    const { minScale, maxScale } = this.props;

    const svgNode = this.svgRef.current;
    const svg = d3.select(svgNode);

    const parentElement = svg.node().parentElement;

    let initialWidth;
    let currentScale = 1;  // This tracks the current cumulative scale of the element
    let startScale = 1;    // This tracks the scale at the start of each gesture


    const onZoomStart = (e) => {
      e.preventDefault();
      initialWidth = svgNode.clientWidth;
      startScale = currentScale;

      this.props.onZoomStart(e);
    }


    const onZoomChange = (e) => {
      let scale = e.scale || e.detail.scale;

      // Calculate the relative scale change from the start of the gesture
      let relativeScale = scale / startScale;

      // Calculate the new cumulative scale
      let newScale = currentScale * relativeScale;

      // Calculate the new width based on the clamped scale
      let newWidth = clamp(
        newScale * initialWidth,
        [minScale * parentElement.clientWidth, maxScale * parentElement.clientWidth]
      );

      if (Number.isNaN(newWidth)) {
        return;
      }

      // Capture the center position in the parent view before scaling
      const centerX = parentElement.scrollLeft + (parentElement.clientWidth / 2);
      // Calculate the center’s proportional position in the SVG
      const centerXRatio = centerX / svgNode.clientWidth;

      // Apply the new scale to the SVG element
      svg.attr('width', newWidth + 'px');

      if(this.props.centerOnStretch){
        // Set new scroll positions to maintain the same viewport
        parentElement.scrollLeft = centerXRatio * newWidth - parentElement.clientWidth / 2;
      }

      // Update current scale and notify via onZoomChange callback
      this.props.onZoomChange({ k: newScale, width: newWidth });
    };


    const onZoomEnd = (e) => {
      this.props.onZoomEnd(e);
    }

    // Attach event listeners for gesture handling
    parentElement.addEventListener('gesturestart', onZoomStart);
    parentElement.addEventListener('gesturechange', onZoomChange);
    parentElement.addEventListener('gestureend', onZoomEnd);

  }


  render(){
    return (
      <div style={{ overflowX: 'scroll', height: this.props.height, overflowY: 'hidden' }}>
          <svg ref={this.svgRef} width={this.props.initialWidth} height={this.props.height}>
            {
              this.props.debug  &&
              <>
                <rect x={0} y={0} width="100%" height={10} style={{ fill: 'green' }} />
                <rect x={0} y={300-10} width="100%" height={10} style={{ fill: 'red' }} />
              </>
            }

           {this.props.children}
          </svg>
      </div>
    );
  }
}


StretchySvg.defaultProps = {
  height: 300,
  minScale: 0.1,
  maxScale: 20,
  debug: false,
  initialWidth: '100%',
  onZoomStart: () => {},
  onZoomChange: () => {},
  onZoomEnd: () => {},
  centerOnStretch: false,
};