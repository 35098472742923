import { ListItemButton, ListItemText, ListItemAvatar, Avatar } from '@mui/material';
import { Link } from 'react-router-dom';
import ProfileAvatar from 'Profile/ProfileAvatar';


export default function ProfileOption({ profile, onClick = () => { } }) {
  return (
    <ListItemButton
      key={profile.id}
      component={Link}
      to={`/explore/users/${profile.userId}`}
      onClick={onClick}
    >
      <ListItemAvatar>
        <ProfileAvatar profile={profile} sx={{ mr: 5 }} />
      </ListItemAvatar>
      <ListItemText
        primary={profile.getFullName()}
        secondary={`Airport: ${profile.homeAirportId || 'N/A'}`}
      />
    </ListItemButton>
  );
}



