import axios from 'axios';
import PostModel from 'Models/PostModel';
import logger from 'Lib/logger';


export const getPosts = ({ userId=null, limit=5, offset=0 }, authHeaders) => {
  let url = userId ? `/posts/users/${userId}.json` : '/posts.json';
  url += `?limit=${limit}&offset=${offset}`;

  logger('getPosts', url);

  return axios.get(url, authHeaders)
    .then(response => {
      const { data } = response;
      const posts = data.posts.map(post => new PostModel(post));
      logger('posts', posts);
      return { posts, count: data.count };
    });
}


export const getPost = (id, authHeaders) => {
  logger('getPost', id);
  return axios.get(`/posts/${id}.json`, authHeaders)
    .then(response => {
      const { data } = response;
      const post = new PostModel(data.post);
      logger('post', post);
      return post;
    });
}



export const createPost = (post, authHeaders) => {
  logger("createPost", post);

  return axios.post(
    `/posts/new.json`, {
      post: new PostModel(post).asJSON(['visualUrls', 'profile', 'likeableType', 'commentableType']),
    },
    authHeaders
  )
    .then(response => response.data)
    .then(data => {
      const post = new PostModel(data.post);
      logger('post', post);
      return post;
    });
}

export const updatePost = (post, authHeaders) => {
  logger("updatePost", post);

  return axios.post(
    `/posts/${post.id}.json`, {
      post: new PostModel(post).asJSON(['visualUrls', 'profile']),
    },
    authHeaders
  )
    .then(response => response.data)
    .then(data => {
      const post = new PostModel(data.post);
      logger('post', post);
      return post;
    });
}



export const deletePost = (postId, authHeaders) => {
  return axios.delete(`/posts/${postId}.json`, authHeaders)
    .then(response => {
      return response
    });
}
