import { useState, createContext, useContext } from 'react';

import logger from 'Lib/logger';
import { useAuth } from 'Auth/AuthProvider';
import { getLikesPage } from '../Likes/LikesApi';
import LikeModal from '../Likes/LikeModal';


export const LikeContext = createContext();


export default function LikeProvider({ children }) {

  const { user, authHeaders } = useAuth();

  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);

  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [likeableTypeAndId, setLikeableTypeAndId] = useState(null);
  const [likeable, setLikeable] = useState(null);
  const [likes, setLikes] = useState([]);



  const getLikes = (likeableTypeAndId) => {

    return getLikesPage(likeableTypeAndId, page, authHeaders)
      .then(data => {
        setCount(data.count);
        setLikes([...likes, ...data.likes]);
        setLikeable(data.likeable);
        setPage(page + 1);
      })
  }

  const openLikeModal = (likeableTypeAndId) => {
    logger('openLikeModal', likeableTypeAndId);

    setLikeableTypeAndId(likeableTypeAndId);

    setLoading(true);
    setIsOpen(true);

    getLikes(likeableTypeAndId)
      .finally(() => {
        setLoading(false);
      });

  };

  const closeLikeModal = () => {
    setIsOpen(false);
    setLikeable(null);
    setLikeableTypeAndId(null);
    setCount(0);
    setPage(0);
    setLikes([]);
  }


  return (
    <LikeContext.Provider
      value={{
        likeableTypeAndId,
        openLikeModal,
        closeLikeModal,
      }}>

      {children}
      <LikeModal
        loading={loading}
        open={isOpen}
        onClose={closeLikeModal}
        likeable={likeable}
        likes={likes}
        count={count}
        onLoadMore={() => getLikes(likeableTypeAndId)}
      />
    </LikeContext.Provider>
  );


}


export const useLikes = () => useContext(LikeContext);

