import { Box } from '@mui/material';
import ProfileAvatar from 'Profile/ProfileAvatar';
import ProfileName from 'Profile/ProfileName';

export default function ProfileAvatarAndName({ profile, sx = {}, link = true }) {

  return (
    <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', gap: 1, ...sx }}>
      <ProfileAvatar profile={profile} />
      <ProfileName profile={profile} />
    </Box>

  )

}