import React, { useState } from 'react';
import { Box, Button, Typography, LinearProgress } from '@mui/material';
import AddAPhoto from '@mui/icons-material/AddAPhoto';
import { useAuth } from 'Auth/AuthProvider';
import { useSnackbar } from 'Lib/Snackbar';
import { useFlight } from 'Flights/FlightProvider';
import { uploadFileToStorage, notifyServer, filterFilesForSize } from 'Lib/uploadHelpers';


const FlightVisualUpload = (props) => {
  console.log("buttonSx", props.buttonSx)

  const { authHeaders } = useAuth();
  const { showSnackbar } = useSnackbar();
  const { fetchVisuals } = useFlight();

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [progressArray, setProgressArray] = useState([]);

  const handleFileSelection = async (event) => {
    const files = Array.from(event.target.files);
    const validFiles = filterFilesForSize(files);

    setSelectedFiles(validFiles);
    setProgressArray(new Array(validFiles.length).fill(0));

    // Start uploading immediately
    let newSignedIds = await Promise.all(
      validFiles.map((file, index) => uploadFileToStorage(
        file,
        authHeaders,
        index,
        setProgressArray,
        () => showSnackbar('Failed to upload media')
      ))
    )
    newSignedIds = newSignedIds.filter(id => id !== null);


    // Notify server of successful uploads

    if(newSignedIds.length === 0) {
      showSnackbar('No media uploaded');
    }

    notifyServer(
      // `/flights/${props.flightId}/upload_visuals`,
      `/media/visuals/flight/${props.flightId}/upload`,
      newSignedIds,
      authHeaders,
      // onComplete
      () => {
        setSelectedFiles([]);
        setProgressArray([]);

        showSnackbar('Photos and videos updated');
        fetchVisuals();
      },
      // onError
      () => {
        showSnackbar('Error completing upload process');
      });
  };

  return (
    <Box display="flex" flexDirection="column" gap={2} sx={{p: 0.5}}>
      <input
        accept="image/*,video/*"
        style={{ display: 'none' }}
        id="file-upload"
        type="file"
        onChange={handleFileSelection}
        multiple
      />

      {
        progressArray.length === 0 &&
        <Button
          component="label"
          htmlFor="file-upload"
          variant="text"
          startIcon={<AddAPhoto sx={{ opacity: 0.5 }} />}
          size="small"
          color="secondary"
          fullWidth
          sx={{
            fontSize: '0.6rem',
            alignSelf: 'center',
            p: 2.5,
            backgroundColor: 'rgba(60, 60, 60, 0.04)',
            '&:hover': {
              backgroundColor: 'rgba(60, 60, 60, 0.04)',
            },
            ...props.buttonSx
          }}

        >
          Upload
        </Button>
      }


      {selectedFiles.length > 0 && (
        <Box>
          {selectedFiles.map((file, index) => (
            <Box key={index} mb={1}>
              <Typography variant="caption">{file.name}</Typography>
              <LinearProgress variant="determinate" value={progressArray[index]} />
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default FlightVisualUpload;
