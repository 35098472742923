import { useState } from 'react';
import { Modal, Box, Typography, Avatar, TextField, Button, IconButton, MenuItem, MenuList, ListItemIcon, ListItemText } from '@mui/material';
import { Close } from '@mui/icons-material';
import FlightVisualThumbnails from 'Flights/FlightVisualThumbnails';
import { useFlight } from 'Flights/FlightProvider';
import logger from 'Lib/logger';
import VisualGallery from 'Lib/VisualGallery';
import LoadingButton from '@mui/lab/LoadingButton';
import { createPost } from './PostsApi';
import { useAuth } from 'Auth/AuthProvider';
import TaggedTextField from 'TaggedText/TaggedTextField';
import { AUDIENCES, AUDIENCE_LABELS, AUDIENCES_PRIVATE } from 'Models/Audiences';


export default function PostModal({ open, onClose, flight, profile }) {

  const { visuals } = useFlight();
  const { authHeaders } = useAuth();

  const [caption, setCaption] = useState('');
  const [tags, setTags] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [selectedVisuals, setSelectedVisuals] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [audience, setAudience] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleVisualSelect = ({ visual }) => {
    logger('handleVisualSelect', { visual });
    if (selectedVisuals.includes(visual)) {
      setSelectedVisuals(selectedVisuals.filter(v => v !== visual));
    } else {
      setSelectedVisuals([...selectedVisuals, visual]);
    }
  };

  const handlePost = () => {
    setLoading(true);
    createPost({
      caption: caption.trim(),
      audience,
      flightId: flight.id,
      mentions: {
        tags: tags,
        profiles: profiles
      },
      data: {
        visuals: selectedVisuals.map(v => v.id),
      }
    }, authHeaders).then(() => {
      onClose();
    })
      .finally(() => {
        setLoading(false);
        setSelectedVisuals([]);
        setCaption('');
        setAudience(null);
        setCurrentIndex(0);
        setTags([]);
        setProfiles([]);
      });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="post-modal-title"
      slotProps={{ backdrop: { style: { backgroundColor: 'rgba(0, 0, 0, 0.8)' } } }}
    >
      <Box sx={style}>
        <Box sx={headerStyle}>
          <IconButton
            onClick={onClose}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <Close />
          </IconButton>

          <Avatar
            src={profile?.profilePhotoUrl}
            sx={{ width: 32, height: 32 }}
          >
            {profile?.getInitials()}
          </Avatar>
          <Typography variant="subtitle1">
            {profile?.getFullName()}
          </Typography>
        </Box>

        <TaggedTextField onChange={data => {
          setCaption(data.value);
          setTags(data.tags);
          setProfiles(data.profiles);
        }} />


        {
          selectedVisuals.length === 0 ? (
            <Box variant="rectangular" sx={{ width: '100%', flex: 1, bgcolor: 'whitesmoke', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
              <Typography variant="caption">Select photos or videos</Typography>
            </Box>
          )
            :
            <VisualGallery
              visuals={selectedVisuals}
              initialIndex={currentIndex}
              sx={{ flex: 1 }}
              goToIndex={true}
            />

        }
        <FlightVisualThumbnails
          visuals={visuals}
          onClick={handleVisualSelect}
          selections={selectedVisuals}
          showIndex={true}
        />


        <Box sx={buttonContainerStyle}>
          <TextField
            variant="standard"
            size="small"
            select
            fullWidth
            label="Audience"
            sx={{ flex: 2 }}
            value={audience}
            onChange={(e) => setAudience(e.target.value)}
          >
            {
              AUDIENCES.filter(a => a !== AUDIENCES_PRIVATE).map(a => {
                return (
                  <MenuItem key={a} value={a} selected={audience === a}>
                    {AUDIENCE_LABELS[a].label} ({AUDIENCE_LABELS[a].detail})
                  </MenuItem>
                )
              })
            }
          </TextField>

          <Button
            size="small"
            variant="outlined"
            onClick={onClose}
            sx={{ flex: 1, height: 30, mt: 2 }}
          >
            Cancel
          </Button>
          <LoadingButton
            size="small"
            variant="contained"
            onClick={handlePost}
            loading={loading}
            disabled={selectedVisuals.length === 0 || !audience}
            sx={{ flex: 1, height: 30, mt: 2 }}
          >
            Post
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
}


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height: '100vh',
  maxWidth: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
  display: 'flex',
  flexDirection: 'column',
};


const headerStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: 2,
  mb: 1,
  flex: 0
};

const buttonContainerStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 1,
  mt: 1,
  flex: 0
};
