import BaseModel from "Lib/BaseModel";

export default class AirportModel extends BaseModel {

  constructor(props) {
    super(props);
    this.optionType = 'Airports';
  }

  getOptionLabel() {
    return this.getDisplayName();
  }

  getId(){
    return this.icaoId || this.arptId;
  }

  getDisplayName(){
    // return this.name;
    const cleanName = this.name.replace('/', ' / ').replace(/\([^)]*\)/g, "")
    return cleanName.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
  }

  getTowerColor(){
    if(this.twrTypeCode === 'NON-ATCT'){
      return '#E231F7';
    }
    return '#377BF6';
  }

  getZoom(){
    const lengths = this.runways?.map(r => r.rwy_len);
    const maxLength = lengths.max();

    let z = 15;

    if(maxLength > 10000){
      z = 13;
    } else if(maxLength > 5000){
      z = 14;
    }

    return z;
  }


  getCenter(){
    if(!this.lat || !this.lng){
      return undefined;
    }

    return [+this.lat, +this.lng];
  }

}
