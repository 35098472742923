import { Fragment, useRef, useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CirlcularProgress from '@mui/material/CircularProgress';
import { formatDuration } from './format';
import logger from 'Lib/logger';




const VideoRenditions = ({ visual, thumbnail, thumbnailSize = 80, hideDuration, style = {}, ...other }) => {
  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true);

  other.onContextMenu = e => {
    if (!window.location.origin.includes('localhost')) {
      e.preventDefault();
    }
  }

  let playPromise;

  useEffect(() => {
    if (isSafari()) {
      return;
    }


    const handlePlayPause = (entries) => {
      if (!videoRef.current) return;

      entries.forEach((entry) => {
        try {
          if (entry.isIntersecting) {
            playPromise = videoRef.current.play()
              .catch(e => {
                logger('Playback failed:', e);
              });
          } else {
            if (playPromise !== undefined) {
              playPromise.then(() => {
                videoRef.current.pause();
              })
                .catch(e => {
                  logger('Pause failed:', e);
                  // Auto-play was prevented
                  // Show paused UI.
                });
            }
          }
        } catch (e) {
          logger('error playing/pausing video', e);
        }
      });
    };


    const observer = new IntersectionObserver(handlePlayPause, {
      threshold: 0.05, // Adjust this value to control how much of the video needs to be visible to play
    });

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);


  const handleMute = () => {
    if (!videoRef.current) return;
    setIsMuted(!isMuted);
    videoRef.current.muted = !isMuted;
  }

  const handleFullScreen = () => {
    requestFullscreen(videoRef);
  }


  if (thumbnail && visual.poster) {
    return (
      <div style={{ position: 'relative', overflow: 'hidden', width: thumbnailSize, height: thumbnailSize, ...style }}>
        <img
          alt={visual.alt || "Video Thumbnail"}
          src={visual.poster}
          style={{ width: "100%", height: "100%", objectFit: 'cover' }}
          onContextMenu={e => e.preventDefault()}
        />
        {visual.duration && !hideDuration && (
          <span
            style={{
              position: 'absolute',
              right: 4,
              bottom: 9,
              color: 'black',
              fontWeight: 'bolder',
              padding: '0px 2px',
              backgroundColor: 'rgba(250, 250, 250, 0.8)',
              margin: 4,
              fontSize: 12,
              lineHeight: '16px',
              borderRadius: '4px'
            }}
          >
            {formatDuration(visual.duration)}
          </span>
        )}
      </div>
    );
  }

  if (visual.constructor.name === 'VisualModel' && visual.isQuicktime()) {
    return (
      <div
        style={{ textAlign: 'center', fontSize: 10, paddingTop: 25, color: 'gray' }}
      >
        <CirlcularProgress color="secondary" size={10} />
        <br />
        <span style={{ height: 20 }}>Preparing...</span>
      </div>
    );
  }


  if (visual.constructor.name === 'File') {
    return <video ref={videoRef} src={URL.createObjectURL(visual)} autoPlay playsInline muted loop style={{ ...style }} {...other} />;
  }

  if (!visual.renditions?.length) {
    return <video ref={videoRef} src={visual.url} poster={visual.poster} autoPlay playsInline muted loop style={{ ...style }} {...other} />;
  }


  return (
    <>
      <video ref={videoRef} poster={visual.poster} autoPlay playsInline muted loop style={{ ...style }} {...other}>
        {visual.renditions.map((rendition) => (
          <Fragment key={rendition.size}>
            {/* Standard resolution */}
            <source src={rendition.url} type="video/mp4" media={generateMediaAttr(rendition.size, 1)} />
            {/* Retina display (2x) resolution */}
            {rendition.retinaUrl && (
              <source src={rendition.retinaUrl} type="video/mp4" media={generateMediaAttr(rendition.size, 2)} />
            )}
          </Fragment>
        ))}
        <source
          src={visual.renditions[0].url}
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>

      <IconButton
        onClick={handleFullScreen}
        sx={{ position: 'absolute', top: 10, right: 10, color: 'rgba(255, 255, 255, 0.75)' }}
      >
        <OpenInFullIcon />
      </IconButton>

      <IconButton
        onClick={handleMute}
        sx={{ position: 'absolute', bottom: 10, right: 10,  color: 'rgba(255, 255, 255, 0.75)' }}
      >
        {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
      </IconButton>
    </>
  );
};

export default VideoRenditions;

// const generateMediaAttr = (size, pixelRatio = 1) => {
//   // Set media conditions based on rendition size and pixel ratio
//   if (size >= 1920) return `(min-width: 1920px) and (min-device-pixel-ratio: ${pixelRatio})`;
//   if (size >= 1280) return `(min-width: 1280px) and (min-device-pixel-ratio: ${pixelRatio})`;
//   if (size >= 768) return `(min-width: 768px) and (min-device-pixel-ratio: ${pixelRatio})`;
//   if (size >= 480) return `(min-width: 480px) and (min-device-pixel-ratio: ${pixelRatio})`;

//   // For smallest rendition, assume it covers all smaller widths
//   return `all and (min-device-pixel-ratio: ${pixelRatio})`;
// };

// Revisit this to make sure it's correct
const generateMediaAttr = (size, pixelRatio = 1) => {
  // Map rendition size to media queries
  if (size >= 1920) return `(min-width: 1920px) and (max-width: 2559px) and (min-device-pixel-ratio: ${pixelRatio})`;
  if (size >= 1280) return `(min-width: 1280px) and (max-width: 1919px) and (min-device-pixel-ratio: ${pixelRatio})`;
  if (size >= 768) return `(min-width: 768px) and (max-width: 1279px) and (min-device-pixel-ratio: ${pixelRatio})`;
  if (size >= 480) return `(min-width: 480px) and (max-width: 767px) and (min-device-pixel-ratio: ${pixelRatio})`;

  // Fallback for smallest rendition
  return `(max-width: 479px) and (min-device-pixel-ratio: ${pixelRatio})`;
};


const isSafari = () => {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
};


const requestFullscreen = (videoRef) => {
  if (!videoRef.current) return;

  var v = videoRef.current;

  console.log('requestFullscreen', v);

  if (v.requestFullscreen) {
    v.requestFullscreen();
  } else if (v.webkitRequestFullscreen) {
    v.webkitRequestFullscreen();
  } else if (v.webkitEnterFullScreen) {
    v.webkitEnterFullScreen();
  } else if (v.mozRequestFullScreen) {
    v.mozRequestFullScreen();
  } else if (v.msRequestFullscreen) {
    v.msRequestFullscreen();
  }
}