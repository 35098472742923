import { useState, createContext, useContext } from 'react';

import logger from 'Lib/logger';
import { useAuth } from 'Auth/AuthProvider';
import { getCommentsPage, deleteComment } from './CommentsApi';
import CommentModal from './CommentModal';


export const CommentContext = createContext();


export default function CommentProvider({ children  }) {

  const { user, authHeaders } = useAuth();

  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);

  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [commentableTypeAndId, setCommentableTypeAndId] = useState(null);
  const [commentable, setCommentable] = useState(null);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState(null);
  const [updateCommentsCallback, setUpdateCommentsCallback] = useState(null);



  const getComments = (commentableTypeAndId) => {
    return getCommentsPage(commentableTypeAndId, page, authHeaders)
      .then(data => {
        setCount(data.count);

        const newComments = data.comments.filter(c => c.id !== newComment?.id);
        setComments([...comments, ...newComments]);
        setCommentable(data.commentable);
        setPage(page + 1);
    })
  }

  const openCommentModal = (commentableTypeAndId, updateCommentsCallback) => {
    logger('openCommentModal', commentableTypeAndId, updateCommentsCallback);

    setCommentableTypeAndId(commentableTypeAndId);
    setSafeCallback(updateCommentsCallback);


    setLoading(true);
    setIsOpen(true);

    getComments(commentableTypeAndId)
      .finally(() => {
        setLoading(false);
      });

  };

  const closeCommentModal = () => {
    setIsOpen(false);
    setCommentable(null);
    setCommentableTypeAndId(null);
    setCount(0);
    setPage(0);
    setComments([]);
    setNewComment(null);
    setUpdateCommentsCallback(null);
  }

  const setSafeCallback = (cb) => {
    // Avoid interpreting the callback as an updater function
    // This explicitly stores the function reference
    setUpdateCommentsCallback(() => cb);
  };

  const executeUpdateCommentsCallback = (data) => {
    console.log('executeUpdateCommentsCallback', data);
    setNewComment(data.comment);
    setCount(count + 1);

    if (updateCommentsCallback) {
      updateCommentsCallback(data);
    }
  };


  const onDeleteComment = (commentId) => {
    console.log('delete comment');
    if (window.confirm('Are you sure you want to delete this comment?')) {

      deleteComment(commentableTypeAndId, commentId, authHeaders)
        .then(data => {
          setComments(comments.filter(c => c.id !== commentId));
          setCount(count - 1);

          if (updateCommentsCallback) {
            updateCommentsCallback(data);
          }
        });
    }

  };




  return (
    <CommentContext.Provider
      value={{
        commentableTypeAndId,
        openCommentModal,
        closeCommentModal,
        executeUpdateCommentsCallback,
        newComment,
        onDeleteComment,
      }}>

      {  children }
      <CommentModal
          loading={loading}
          open={isOpen}
          onClose={closeCommentModal}
          commentable={commentable}
          comments={comments}
          count={count}
          currentUserId={user?.id}
          onLoadMore={() => getComments(commentableTypeAndId)}
          newComment={newComment}
        />
    </CommentContext.Provider>
  );


}


export const useComments = () => useContext(CommentContext);

