import { Info, Mic } from "@mui/icons-material";
import { Divider, IconButton, LinearProgress, Box, Card } from "@mui/material";
import CardHeader from "../Shared/CardHeader";
import FlightForm from "./FlightForm";
import { createOrUpdateFlightAndLogbook } from "Flights/FlightsApi";
import { useSnackbar } from "Lib/Snackbar";
import { useAuth } from "Auth/AuthProvider";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getTrace } from "FlightMap/TraceDetail/TracesApi";
import { useEffect, useState } from "react";
import FlightModel from "Models/FlightModel";
import { formatInputDate, formatHours } from "Lib/format";
// import { useFlightMap } from "FlightMap/FlightMap";
import StickyHeader from "FlightMap/Shared/Sticky";
import Layout from "Layout/Layout";
import withAuth from "../../Auth/withAuth";

const FlightCreate = (props) => {
  const { showSnackbar } = useSnackbar();
  const { authHeaders } = useAuth();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [flightFromTrace, setFlightFromTrace] = useState(null);
  // const { setMapTraces } = useFlightMap();


  useEffect(() => {
    const traceUid = searchParams.get('trace-uid');
    if (!traceUid) {
      setIsLoading(false);
      return;
    }

    getTrace(traceUid, authHeaders)
      .then(trace => {

        const t = trace.takeoffT
        const hh = ('0' + t.getHours()).slice(-2);
        const mm = ('0' + t.getMinutes()).slice(-2);

        const newFlight = new FlightModel({
          departureDate: formatInputDate(t),
          departureTime: `${hh}:${mm}`,
          registrationNumber: trace.registrationNumber,
        });

        newFlight.logbookEntry.aircraftMakeAndModel = trace.type;
        newFlight.logbookEntry.totalHours = formatHours(trace.getDurationHours());

        setFlightFromTrace(newFlight);
        setIsLoading(false);
        // setMapTraces([trace]);
      })

  }, [searchParams['trace-uid']]);



  const onSubmit = (data) => {
    showSnackbar("Creating flight...");
    createOrUpdateFlightAndLogbook(data, authHeaders)
      .then(flight => navigate(`/flights/${flight.id}`))
      .catch(error => {
        console.error(error);
        showSnackbar("Error creating flight", { severity: 'error' });
      })
  }


  return (
    <Layout maxWidth="md">
      <Card sx={{ m: 2, mt: 3 }}>
        <StickyHeader>
          <CardHeader
            title="Log Flight"
            toolbar={
              null
              // <IconButton color="primary">
              //   <Mic />
              // </IconButton>
            }
          />
          <Divider />
          {
            !isLoading && flightFromTrace && (
              <>
                <Box sx={{ backgroundColor: 'whitesmoke', p: 1, fontSize: '0.75rem', color: '#333' }}>
                  <Info sx={{ fontSize: 14, mr: 1 }} color="primary" />
                  Verify the flight data below before logging the flight.
                </Box>
                <Divider />
              </>
            )
          }
          {isLoading && <LinearProgress />}

        </StickyHeader>




        {
          !isLoading && (
            <FlightForm
              onSubmit={onSubmit}
              flight={flightFromTrace}
              onCancel={() => navigate('/flights')}
            />
          )
        }
      </Card>
    </Layout>
  )

}

export default withAuth(FlightCreate);
