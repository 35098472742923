import { Avatar } from '@mui/material';
import { Link } from 'react-router-dom';

export default function ProfileAvatar({ profile, sx = {}, link = true }) {
  const avatar = (
    <Avatar
      sx={{ height: 48, width: 48, ...sx }}
      src={profile.profilePhotoUrl}
      style={{
        cursor: link ? 'pointer' : 'default',
        border: '1px solid whitesmoke',
      }}
    >
      {profile.getInitials()}
    </Avatar>
  );

  if (!link) {
    return avatar;
  }

  return (
    <Link to={`/profiles/${profile.userId}`}>
      {avatar}
    </Link>
  );

}