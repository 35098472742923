import { useEffect, useState  } from 'react';
import { getNotMyProfile } from './ProfileApi';
import Profile from './Profile';
import { LinearProgress } from '@mui/material';
import { useAuth } from 'Auth/AuthProvider';
import { useParams, Navigate } from 'react-router-dom';
import ProfileModel from 'Models/ProfileModel';
import { createUserPair } from "UserPairs/UserPairsApi";
import { useSnackbar } from "Lib/Snackbar";
import { getUserPair } from 'UserPairs/UserPairsApi';


export default function NotMyProfile(){
  const { currentUser, authHeaders } = useAuth();
  const { showSnackbar } = useSnackbar();
  const params = useParams();

  const [ isLoadingProfile, setIsLoadingProfile ] = useState(true);
  const [ isLoadingUserPair, setIsLoadingUserPair ] = useState(true);

  const [profile, setProfile] = useState(null);
  const [flightStats, setFlightStats] = useState(null);
  const [userPair, setUserPair] = useState(null);

  useEffect(() => {
    const profileUserId = params.userId;

    getNotMyProfile(profileUserId, authHeaders).then(({profile, flightStats}) => {
      setFlightStats(flightStats);
      setProfile(new ProfileModel(profile));
      setIsLoadingProfile(false);
    });

    getUserPair({profileUserId, currentUserId: currentUser?.id}, authHeaders).then((userPair) => {
      setUserPair(userPair);
      setIsLoadingUserPair(false);
    });
  }, [params.userId]);


  const handleCreateUserPair = () => {
    const profileUserId = params.userId;

    createUserPair({ profileUserId, currentUserId: currentUser?.id }, authHeaders)
    .then(newUserPair => {
      setUserPair(newUserPair);
      showSnackbar('Request sent.');
    })
    .catch(error => {
      showSnackbar('Something went wrong', 'error');
      console.error(error);
    });
  }

  if (currentUser?.id === params.userId) {
    return <Navigate to={`/profile`} />;
  }

  if(isLoadingProfile || isLoadingUserPair) {
    return <LinearProgress />;
  }

  return (
    <Profile
      profile={profile}
      userPair={userPair}
      flightStats={flightStats}
      onCreateUserPair={handleCreateUserPair}
    />
  )

}