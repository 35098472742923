import React from 'react';
import Cell from './Cell';
import axios from 'axios';
import { formatHours, formatTime, formatCount, formatLogbookDate, formatInputDate } from 'Lib/format';
import { Chip, IconButton } from '@mui/material';
import { createOrUpdateFlightAndLogbook } from 'Flights/FlightsApi';
import withContext from 'Lib/withContext';
import { SnackbarContext } from 'Lib/Snackbar';
import { AuthContext } from 'Auth/AuthProvider';
import { requiredFields } from 'FlightMap/Flights/FlightForm';
import { Flight } from '@mui/icons-material';
import { Link } from 'react-router-dom';

class LogbookRow extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isUpdating: false,
      flight: this.props.flight,
      undoStack: [],
      redoStack: [],
      hasError: false,
    }
  }


  componentDidUpdate = () => {
  }


  handleChange = (column, value) => {
    const { flight } = this.state;
    const newFlight = { ...flight };

    const isFlightColumn = ['registrationNumber','departureDate', 'departureTime'].includes(column);
    if (isFlightColumn) {
      newFlight[column] = value;
    } else {
      newFlight.logbookEntry[column] = value;
    }

    // This doesn't work because as you type in the hours, the total hours are updated
    // Pre fill total hours as SEL or MEL hours are entered
    // const isSelOrMel = ['airplaneSingleEngineLandHours','airplaneMultiEngineLandHours'].includes(column);
    // if (isSelOrMel && !newFlight.logbookEntry.totalHours) {
    //   newFlight.logbookEntry.totalHours = value;
    // }

    this.setState({ flight: newFlight }, this.maybeUpdateFlight);
  };


  validateFlight = () => {

    const { flight } = this.state;
    for (var i = 0; i < requiredFields.length; i++) {
      const combined = { ...flight, ...flight.logbookEntry };
      const r = requiredFields[i];
      if (!combined[r.field]) {
        return false;
      }
    }
    return true
  }


  maybeUpdateFlight = () => {
    this.props.onChange(this.props.rowIndex, this.state.flight)

    if(!this.validateFlight() || this.state.isUpdating) {
      return;
    }

    clearTimeout(this.updateTimeout);
    this.updateTimeout = setTimeout(() => {

      this.setState({ isUpdating: true });

      this.props.onSaving();
      this.props.showSnackbar('Updating flight...');
      const { flight } = this.state;

      createOrUpdateFlightAndLogbook({ flight, logbookEntry: flight.logbookEntry}, this.props.authHeaders)
        .then(flight => {
          console.log('flight.id', flight.id);
          this.setState({ flight, isUpdating: false });
          this.props.showSnackbar('Flight updated...');
          this.props.onComplete();
        })
        .catch(error => {
          this.props.showSnackbar('Error updating flight');
          console.error('Error updating flight:', error);
        });
    }, 3000);
  }



  render() {
    const { flight } = this.state;
    const { logbookEntry } = flight;

    return (
      <tr className={!flight.id ? 'changed' : ''}>
          <th style={{ textAlign: 'center' }}>
            {
              flight.id &&
              <IconButton sx={{ height: 12, width: 12 }} color="primary" size="small" component={Link} to={`/flights/${flight.id}`}>
                <Flight sx={{ fontSize: 12 }}/>
              </IconButton>
            }


          </th>
          <td>
            <Cell
              value={flight.departureDate || ''}
              format={v => {
                if (!v) return '';
                const s = v.split('-')
                return s[1] + '/' + s[2] + '/' + s[0]
              }}
              onChange={value => this.handleChange('departureDate', value)}
              style={{ width: 100 }}
              inputProps={{ type: 'date', max: "9999-12-31" }}
            />
          </td>

          <td>
            <Cell
              value={flight.departureTime || null}
              onChange={value => {
                console.log('value', value);
                this.handleChange('departureTime', value)
              }}
              format={v => {
                const s = v.split(':');
                let hh = +s[0];
                const amPm = hh < 12 ? 'AM' : 'PM';
                hh = hh % 12;
                if (hh === 0) {
                  hh = 12;
                }
                return `${hh < 10 ? '0' : ''}${hh}:${s[1]} ${amPm}`;
              }}
              style={{ width: 100 }}
              inputProps={{ type: 'time' }}
            />
          </td>

          <td>
            <Cell
              value={logbookEntry.aircraftMakeAndModel}
              onChange={value => this.handleChange('aircraftMakeAndModel', value)}
            />
          </td>

          <td>
            <Cell
              value={flight.registrationNumber}
              onChange={value => this.handleChange('registrationNumber', value.toUpperCase().trim())}
              format={value => <TailNumberChip tailNumber={value} />}
            />
          </td>

          <td>
            <Cell
              value={flight.departureAndArrivalAirports.join(' ') || ''}
              onChange={value => {
                const airports = value.toUpperCase().split(' ');
                this.handleChange('departureAndArrivalAirports', airports)
              }}
              format={value => {
                const nonNullAirports = value.split(' ').filter(a => a.trim());
                return (
                  nonNullAirports.map((a, i) => <AirportChip key={i} airport={a} />)
                )
              }}
              style={{ width: 200, whiteSpace: 'nowrap' }}
            />
          </td>

          <td>
            <HoursCell
              value={logbookEntry.airplaneSingleEngineLandHours}
              onChange={value => this.handleChange('airplaneSingleEngineLandHours', value)}
            />
          </td>

          <td>
            <HoursCell
              value={logbookEntry.airplaneMultiEngineLandHours}
              onChange={value => this.handleChange('airplaneMultiEngineLandHours', value)}
            />
          </td>

          <td>
            <HoursCell
              value={logbookEntry.dualReceivedHours}
              onChange={value => this.handleChange('dualReceivedHours', value)}
            />
          </td>

          <td>
            <HoursCell
              value={logbookEntry.pilotInCommandHours}
              onChange={value => this.handleChange('pilotInCommandHours', value)}
            />
          </td>

          <td>
            <HoursCell
              value={logbookEntry.secondInCommandHours}
              onChange={value => this.handleChange('secondInCommandHours', value)}
            />
          </td>

          <td>
            <HoursCell
              value={logbookEntry.flightInstructorHours}
              onChange={value => this.handleChange('flightInstructorHours', value)}
            />
          </td>

          <td>
            <HoursCell
              value={logbookEntry.groundTrainerHours}
              onChange={value => this.handleChange('groundTrainerHours', value)}
            />
          </td>

          <td>
            <HoursCell
              value={logbookEntry.dayHours}
              onChange={value => this.handleChange('dayHours', value)}
            />
          </td>

          <td>
            <HoursCell
              value={logbookEntry.nightHours}
              onChange={value => this.handleChange('nightHours', value)}
            />
          </td>


          <td>
            <HoursCell
              value={logbookEntry.crossCountryHours}
              onChange={value => this.handleChange('crossCountryHours', value)}
            />
          </td>

          <td>
            <HoursCell
              value={logbookEntry.actualInstrumentHours}
              onChange={value => this.handleChange('actualInstrumentHours', value)}
            />
          </td>

          <td>
            <HoursCell
              value={logbookEntry.simulatedInstrumentHours}
              onChange={value => this.handleChange('simulatedInstrumentHours', value)}
            />
          </td>

          <td>
            <CountCell
              value={logbookEntry.instrumentApproachesCount}
              onChange={value => this.handleChange('instrumentApproachesCount', value)}
            />
          </td>

          <td>
            <CountCell
              value={logbookEntry.dayLandingsCount}
              onChange={value => this.handleChange('dayLandingsCount', value)}
            />
          </td>

          <td>
            <CountCell
              value={logbookEntry.nightLandingsCount}
              onChange={value => this.handleChange('nightLandingsCount', value)}
            />
          </td>

          <td>
            <HoursCell
              value={logbookEntry.totalHours}
              onChange={value => this.handleChange('totalHours', value)}
            />
          </td>

          <td>
            <Cell
              value={logbookEntry.remarks}
              onChange={value => this.handleChange('remarks', value)}
              style={{ width: 200 }}
            />
          </td>
      </tr>
    )
  }

}


LogbookRow.defaultProps = {
  onChange: () => { }
}


export function TailNumberChip(props) {
  const { tailNumber, sx, ...otherProps } = props;
  return (
    <Chip
      label={tailNumber}
      size="small"
      sx={{
        mr: '5px',
        position: 'relative',
        top: '-3px',
        fontSize: '11px',
        borderTopRightRadius: 0,
        borderBottomLeftRadius: 0,
        fontWeight: 'bold',
        ...sx
      }}
      {...otherProps}
    />
  )
}


function AirportChip(props) {
  const { airport, sx, ...otherProps } = props;
  return (
    <Chip
      key={airport}
      label={airport}
      size="small"
      sx={{
        mr: '5px',
        position: 'relative',
        top: '-3px',
        fontSize: '11px',
        fontWeight: 'bold',
        ...sx
      }}
      {...otherProps}
    />
  )
}


export function HoursCell(props) {
  return (
    <Cell
      format={v => formatHours(+v || null)}
      inputType="numeric"
      inputProps={{
        style: { minWidth: '35px' },
        inputMode: 'decimal'
      }}
      {...props}
    />
  );
}


export function CountCell(props) {
  return (
    <Cell
      format={v => formatCount(+v || null)}
      inputType="numeric"
      inputProps={{
        style: { minWidth: '35px' },
        inputMode: 'numeric'
      }}
      {...props}
    />
  );

}

export function DateCell(props) {
  return (
    <Cell
      value={formatInputDate(props.date)}
      format={v => {
        const d = typeof v === 'date' ? v : new Date(v);
        return formatLogbookDate(d)
      }}
      onChange={props.onChange}
      style={{ width: 100 }}
      inputProps={{ type: 'date' }}
    />
  );

}




function TimeCell(props) {
  const value = props.time ? new Date(props.time).toTimeString().slice(0, 5) : null;
  return (
    <Cell
      value={value}
      onChange={props.onChange}
      style={{ width: 100 }}
      inputProps={{ type: 'time' }}
      format={v => {
        const d = getYyyyMmDd(new Date(props.time)) + 'T' + v;
        return getHhMmAmPm(new Date(d));
      }}
    />
  )
}


const getHhMmAmPm = (date) => {
  return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
}


const getYyyyMmDd = (date) => {
  return date.toISOString().split('T')[0];
}



export default withContext(SnackbarContext, AuthContext, LogbookRow);