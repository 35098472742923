

import React, { useRef, useState, createContext, useContext, useMemo, useCallback } from "react";

export const FlightMapContext = createContext();


export default function FlightMapProvider(props) {
  const mapRef = useRef();
  // Map Objects
  const [mapTraces, setMapTraces] = useState([]);
  const [mapFlights, setMapFlights] = useState([]);
  const [mapAirports, setMapAirports] = useState([]);
  // const [brushMarker, setBrushMarker] = useState(null);

  // Layers
  const [isVfrLayerVisible, setIsVfrLayerVisible] = useState(false);
  const [isIfrLayerVisible, setIsIfrLayerVisible] = useState(false);

  const clearMap = () => {
    setMapTraces([]);
    setMapFlights([]);
    setMapAirports([]);
  }


  return (
    <FlightMapContext.Provider value={{
      mapRef,
      mapTraces,
      setMapTraces,
      mapFlights,
      setMapFlights,
      // brushMarker,
      // setBrushMarker,
      clearMap,

      isVfrLayerVisible,
      setIsVfrLayerVisible,
      isIfrLayerVisible,
      setIsIfrLayerVisible,
    }}>
    {props.children}
    </FlightMapContext.Provider>
  );

}

export const useFlightMap = () => useContext(FlightMapContext);
