import { Link } from 'react-router-dom';
import "./TaggedText.css";

export default function MentionWithLinks({ value, mentions }){

  const delimiter = 'xYx';
  let textToSplit = value;
  const mergedMentions = [...(mentions.profiles || []), ...(mentions.tags || [])];

  mergedMentions.forEach((mention) => {
    const r = `@[${mention.display}](${mention.type}:${mention.id})`

    textToSplit = textToSplit.replaceAll(r, delimiter + r + delimiter);
  });

  const split = textToSplit.split(delimiter);

  return split.map((part, index) => {
      const match = part.match(/^@\[(.+?)\]\((tag|profile):(.+?)\)$/);

      if(!match) return part;

      const [, display, tagType, id] = match;

      if (tagType === 'tag') {
        return (
          <Link
            key={index}
            to={`/search?tag=${display.replace('#', '')}`}
            className="TaggedText__tag"
          >
            {display}
          </Link>
        );
      }
      else if (tagType === 'profile') {
        return (
          <Link
            key={index}
            to={`/profiles/${id}`}
            className="TaggedText__profile"
          >
            {display}
          </Link>
        );
      }
  });

};
