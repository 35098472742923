
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import LogbookEntryModel from 'Models/LogbookEntryModel';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';


let fields = LogbookEntryModel.hoursFields.filter(f => f.field !== 'totalHours').concat(
  LogbookEntryModel.countFields
).concat(
  LogbookEntryModel.hoursFields.filter(f => f.field === 'totalHours')
);



export default function LogbookTable({ logbookEntry }) {
  return (
    <>
    <TableContainer>
      <Table size="small">
        <TableBody>
          {
            fields.map(f => {
              if (!logbookEntry[f.field]) return null;

              const isTotal = f.displayName.includes('Total');
              const totalSx = isTotal ? { fontWeight: 'bold' } : {};


              return (
                <TableRow
                  key={f.field}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row" sx={totalSx}>
                    {f.displayName}
                  </TableCell>
                  <TableCell align="right" sx={totalSx}>{logbookEntry[f.field]}</TableCell>
                </TableRow>
              )
            })
          }

        </TableBody>
      </Table>
    </TableContainer>
    <Divider />
    <Box sx={{ p: 1, pl: 2, pr: 2 }}>
    <Typography variant="caption" color="text.secondary">
      Remarks
    </Typography>
    <Typography variant="body2">
      { logbookEntry.remarks }
    </Typography>
    </Box>


    </>
  );
}