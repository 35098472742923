import { Box, Badge, Tooltip, IconButton } from '@mui/material';
import { ChatBubble, ChatBubbleOutline } from '@mui/icons-material';

export default function CommentButton({ commentable, updateCommentsCallback, openCommentModal, sx = {}, rootSx = {} }) {
  return (
    <Box sx={{ display: 'inline-block', ...rootSx}}>
    <Badge
      badgeContent={commentable.commentsCount}
      color={commentable.hasUserComments ? 'primary' : 'secondary'}
      slotProps={{
        badge: {
          style: {
            fontSize: '0.6rem',
            fontWeight: 'bold',
            fontFamily: 'monospace',
            lineHeight: '20px',
          },
        }
      }}
    >
      <Tooltip title="Add a comment">
        <IconButton
          size="small"
          sx={{ ml: 2, border: '2px solid transparent', ...sx }}
          onClick={() => openCommentModal({ type: commentable.commentableType, id: commentable.id }, updateCommentsCallback)}
        >
          {commentable.hasUserComments ? <ChatBubble /> : <ChatBubbleOutline />}
        </IconButton>
      </Tooltip>
    </Badge>
    </Box>
  )
}