import axios from 'axios';

// export const signIn = (email, password) => {
//   return axios.post(`/users/sign_in.json`, {
//     user: {
//       email,
//       password
//     }
//   }, { withCredentials: true });
// };

export const register = (email, password, passwordConfirmation) => {
  return axios.post(`/users.json`, {
    user: {
      email,
      password,
      password_confirmation: passwordConfirmation
    }
  }, { withCredentials: true });
};
