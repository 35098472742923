import { useState, useEffect } from 'react';
import { useAuth } from 'Auth/AuthProvider';
import { CardHeader } from '@mui/material';
import { getAirportsByIds } from 'Airports/AirportsApi';
import { Box, Typography, Chip } from '@mui/material';

function AirportCardHeader(props) {
  const { airportId } = props;
  const { authHeaders } = useAuth();


  const [airport, setAirport] = useState(null);
  // const airports = getAirportsByIds(airportId, authHeaders)[0];

  useEffect(() => {
    getAirportsByIds(airportId, authHeaders).then(airports => {
      console.log('airports', airports);
      setAirport(airports[0]);
    });
  }, [airportId, authHeaders]);


  console.log('airport', airport);
  if (!airport) return null;



  return (
    <Box sx={{ pt: 1, pb: 2, pl: 1, pr: 1, borderBottom: '1px solid #e0e0e0' }}>
      <Typography variant="body1" sx={{ flex: 1, fontWeight: 'bold', mb: 0.5 }}>
        {airport.getDisplayName()}
      </Typography>

      <Box sx={{ display: 'flex', gap: 1, alignItems: 'flex-start', mb: 0.5 }}>
        <Chip label={airport.getId()} size="small" sx={{ flex: 0, borderRadius: 1, fontSize: '12px' }} />
        <Typography className="NoWrap" variant="caption" sx={{ lineHeight: '24px' }}>{airport.city}, {airport.stateName}</Typography>
      </Box>

      <Box className="NoWrap">
        <span style={{ color: airport.getTowerColor() }}>●</span>&nbsp;
        <Typography variant="caption" sx={{ mr: 1 }}>{airport.elev}' MSL</Typography>
        <Typography variant="caption" sx={{}}>{airport.runways.map(r => r.rwy_id.startsWith('H') ? r.rwy_id : 'R' + r.rwy_id).sort().join(', ')}</Typography>
      </Box>
    </Box>
  )
}

export default AirportCardHeader;