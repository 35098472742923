import { useState, useEffect } from 'react';
import { DateTime } from "luxon";
import { Box, Button, IconButton, LinearProgress, Typography } from '@mui/material';
import { AccessTime, Delete } from '@mui/icons-material';
import { useAuth } from "Auth/AuthProvider";
import logger from 'Lib/logger';
import { useSnackbar } from 'Lib/Snackbar';
import { useFlight } from 'Flights/FlightProvider';
import { deleteAudio } from 'Flights/FlightsApi';
import AudioTrackBuilder from 'AudioTrackBuilder/AudioTrackBuilder';
import brushingBus, { dispatchBrushUpdate } from 'BrushingBus/BrushingBus';
import EditAudioStartTimeModal from 'Flights/EditAudioStartTimeModal';


export default function FlightAudio(props) {
  const { authHeaders } = useAuth();
  const { flight, audio, fetchAudio, updateAudioMetadata} = useFlight();

  const { showSnackbar } = useSnackbar();

  const [isEditing, setIsEditing] = useState(false);
  const [isEditingStartTime, setIsEditingStartTime] = useState(false);
  const [currentTimeSeconds, setCurrentTimeSeconds] = useState(0);
  const [audioOffsetSeconds, setAudioOffsetSeconds] = useState(0);



  useEffect(() => {
    // If there are no traces, there's nothing to brush
    if (!flight.traces.length || !audio) return;



    // const audioOffsetSeconds = 771;
    const audioStartTimestampSeconds = getAudioStartTimestampSeconds(flight, audio);
    const takeoffTimestampSeconds = flight.traces[0].takeoffT.valueOf() / 1000;
    const newAudioOffsetSeconds = takeoffTimestampSeconds - audioStartTimestampSeconds;
    setAudioOffsetSeconds(newAudioOffsetSeconds);

    const onBrushUpdate = (event) => {
      const { detail } = event;
      if (detail.source === 'audio') {
        return;
      }
      const timeOffset = detail.timestamp - flight.traces[0].getTakeoffSeconds() + newAudioOffsetSeconds;
      setCurrentTimeSeconds(timeOffset);
    };

    brushingBus.addEventListener('brush-update', onBrushUpdate);

    return () => {
      brushingBus.removeEventListener('brush-update', onBrushUpdate);
    };
  }, [flight, audio]);



  const handleDelete = () => {
    if (!window.confirm(`Are you sure you want to delete this audio file?`)) return;

    deleteAudio(props.flightId, audio.id, authHeaders)
      .then(response => {
        logger('deleteAudio response', response);
        if (response.status === 200) {
          showSnackbar('Audio deleted');
          fetchAudio();
        } else {
          showSnackbar('Could not delete media.', { severity: 'error' });
        }
      })
      .catch(error => {
        logger('Error deleting audio:', error);
        console.error('Error deleting audio:', error);
        showSnackbar('Could not delete audio.', { severity: 'error' });
      });
  }
  return (
    <Box className="FlightAudio" sx={{ p: 1}}>
      <div style={{ display: 'flex', minHeight: 32 }}>
        <div style={{ flex: 1, fontWeight: 'bold', lineHeight: '32px' }}>
          <Typography variant="caption" sx={{ display: 'block' }} >
            Flight Audio
            { props.visibilityTip }
          </Typography>
        </div>
        <Box  style={{ flex: 1, textAlign: 'right', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>

          {
            audio &&
            <>
              {
                isEditing &&
                <>
                 <Button color="secondary" size="small" onClick={() => setIsEditingStartTime(true)}>
                    <AccessTime />
                  </Button>
                  <Button color="secondary" size="small" onClick={handleDelete}>
                    <Delete />
                  </Button>
                </>
              }

              <Button variant="text" size="small" color="secondary" onClick={() => {
                setIsEditing(!isEditing);
              }} >
                {isEditing ? 'Done' : 'Edit'}
              </Button>
            </>
          }
        </Box>
      </div>

      {
        audio && !audio.lufsUrl &&
        <audio
          // controls
          controlsList="nodownload"
          src={audio.url}
          style={{ width: '100%' }}
        />
      }

      {
        audio && audio.lufsUrl &&
        <AudioTrackBuilder
          height={100}
          margin={{ top: 0, right: 10, bottom: 10, left: 10 }}
          hideAxis={true}
          audioUrl={audio.url}
          lufsUrl={audio.lufsUrl}
          currentTimeSeconds={currentTimeSeconds}
          onChange={(d) => {
            if (!props.tracePath || !props.tracePath.length) return;

            const t = d.timeSeconds + flight.traces[0].getTakeoffSeconds() - audioOffsetSeconds;
            const point = props.tracePath.find(p => p.t >= t);

            dispatchBrushUpdate({
              source: 'audio',
              timestamp: t,
              point
            });

          }}
        />
      }

    {
      audio &&
      <EditAudioStartTimeModal
        open={isEditingStartTime}
        time={audio.metadata.userStartTime || flight.departureTime}
        onSave={d => {
          console.log('EditAudioStartTimeModal onSave', d);
          updateAudioMetadata({ userStartTime: d })
            .then(() => {
              setIsEditingStartTime(false);
              showSnackbar('Audio start time updated');
            });
        }}
        onClose={() => setIsEditingStartTime(false)}
      />
    }



    </Box>
  );
}





const getAudioStartTimestampSeconds = (flight, audio) => {
  const time = audio.metadata.userStartTime || flight.departureTime;
  const localDateTime = `${flight.departureDate}T${time}`;
  const dateTimeInTimezone = DateTime.fromISO(localDateTime, { zone: flight.departureTzid });
  const jsDate = dateTimeInTimezone.toJSDate();
  const timestampSeconds = jsDate.valueOf() / 1000;
  return timestampSeconds;
}