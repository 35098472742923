import React, {useEffect, useState} from "react";
import {Container, Box, Typography, TextField, Button, Link} from "@mui/material";
import {useAuth} from "./AuthProvider";
import {useSnackbar} from "Lib/Snackbar";
import {useSearchParams, useNavigate, useLocation} from "react-router-dom";
import AuthScreen from "./AuthComponents/AuthScreen";
import StepsSidebar from "./AuthComponents/StepsSidebar";

const CODE_LENGTH = 6;

const ConfirmAccount = () => {
    const [code, setCode] = useState(Array(CODE_LENGTH).fill(""));
    const [isSubmitting, setIsSubmitting] = useState(false);
    const {confirmAccount, requestConfirmationCode} = useAuth();
    const {showSnackbar} = useSnackbar();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();

    const email = location.state?.email;
    const user_id = location.state?.id;

    useEffect(() => {
        const urlCode = searchParams.get("code");
        if (urlCode && urlCode.length === CODE_LENGTH) {
            setCode(urlCode.split(""));
        }
    }, [searchParams]);

    const handleInputChange = (e, index) => {
        const value = e.target.value.toUpperCase();
        const inputField = e.target;
        const cursorPosition = inputField.selectionStart;

        const newCode = [...code];

        if (cursorPosition === 0) {
            newCode[index] = value
        } else if (cursorPosition === 1 && value) {
            newCode[index] = value.slice(0, 1);
        } else if (cursorPosition === 2 && value) {
            newCode[index] = value.slice(-1);
        }

        setCode(newCode);

        if (value && index < CODE_LENGTH - 1) {
            const nextInput = document.getElementById(`code-${index + 1}`);
            if (nextInput) nextInput.focus();
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace" && !code[index]) {
            if (index > 0) {
                const prevInput = document.getElementById(`code-${index - 1}`);
                if (prevInput) {
                    prevInput.focus();
                    const newCode = [...code];
                    newCode[index - 1] = "";
                    setCode(newCode);
                }
            }
        }

        if (e.key === "Enter" && index === CODE_LENGTH - 1) {
            handleConfirm();
        }
    };

    const handlePaste = (e) => {
        const pasteData = e.clipboardData.getData("Text").toUpperCase();
        const validCode = pasteData.slice(0, CODE_LENGTH).split("");
        setCode([...validCode, ...Array(CODE_LENGTH - validCode.length).fill("")]);
    };

    const handleConfirm = async () => {
        const confirmationCode = code.join("");
        if (confirmationCode.length < CODE_LENGTH) {
            showSnackbar("Please enter a valid 6-character code.", {severity: "error"});
            return;
        }

        setIsSubmitting(true);

        try {
            const response = await confirmAccount(confirmationCode);
            showSnackbar(response.message, {severity: response.success ? "success" : "error"});

            if (response.success) {
                if (response.signed_in) {
                    navigate("/profile/edit", {replace: true});
                } else {
                    navigate("/sign-in");
                }
            }
        } catch (error) {
            if (error.response && error.response.message) {
                showSnackbar(error.response.message, {severity: "error"});
            } else {
                showSnackbar("An error occurred. Please try again.", {severity: "error"});
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleResend = async () => {
        if (!email) return;

        try {
            setIsSubmitting(true);
            const response = await requestConfirmationCode(user_id, email);
            showSnackbar(response.message, {severity: response.success ? "success" : "error"});
        } catch (error) {
            showSnackbar("Failed to resend the confirmation code. Please try again.", {severity: "error"});
        } finally {
            setIsSubmitting(false);
        }
    };

    const isButtonDisabled = code.some((char) => char === "");

    const signUpStyles = {
        color: "#212121",
        labelColor: "#212121",
        borderColor: "#212121",
        backgroundColor: "#F1F6FF",
    };

    return (
        <AuthScreen backgroundType="auth2" inputStyles={signUpStyles}>
            <StepsSidebar activeStep={1}/>
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: 'center',
                    }}
                >
                    <Typography component='h1' variant='h4' sx={{fontWeight: 'bold', mb: 2}}>
                        Ident
                    </Typography>

                    {!searchParams.get("code") ? (
                        <Typography variant='subtitle1' sx={{mb: 3, color: '#757575'}}>
                            We've sent a confirmation code to your email.
                            Please check your inbox and enter the code below to activate your account.
                        </Typography>
                    ) : (
                        <Typography variant='subtitle1' sx={{mb: 3, color: '#757575'}}>
                            Please enter the 6-character code sent to your email.
                        </Typography>
                    )}

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            gap: 1,
                            mt: 3,
                            width: "100%",
                        }}
                        onPaste={handlePaste}
                    >
                        {code.map((char, index) => (
                            <TextField
                                key={index}
                                id={`code-${index}`}
                                value={char}
                                onChange={(e) => handleInputChange(e, index)}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                InputProps={{
                                    maxLength: 1,
                                    style: {
                                        color: '#212121',
                                        backgroundColor: '#F1F6FF',
                                        fontSize: "1.5rem",
                                    }
                                }}
                                sx={{
                                    mb: 2,
                                    width: "4rem",
                                    height: "3rem",
                                    "& .MuiOutlinedInput-root": {
                                        "&:hover .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#212121 !important",
                                            borderWidth: "0 0 1px 0 !important",
                                        },
                                        "& .MuiOutlinedInput-input": {
                                            textAlign: "center",
                                            textTransform: "uppercase"
                                        },
                                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#212121 !important",
                                            borderWidth: "0 0 3px 0 !important",
                                        },
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#212121 !important",
                                            borderWidth: "0 0 1px 0 !important",
                                        },
                                    },
                                    "& .MuiInputLabel-root": {
                                        color: "#212121",
                                    },
                                    "& .MuiInputLabel-root.Mui-focused": {
                                        color: "#212121",
                                    },
                                }}
                            />
                        ))}
                    </Box>

                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{
                            mt: 4,
                            backgroundColor: "#FF6B03",
                            color: "#FFFFFF",
                            "&:disabled": {
                                backgroundColor: "#a6592a",
                                color: "#FFF8E1",
                                opacity: 1,
                            },
                        }}
                        onClick={handleConfirm}
                        disabled={isSubmitting || isButtonDisabled}
                    >
                        {isSubmitting ? "Submitting..." : "Verify Code"}
                    </Button>

                    {email && (
                        <Typography variant="body2" sx={{mt: 3, textAlign: "center", color: "#212121"}}>
                            Didn't receive the code yet?{" "}
                            <Link
                                component="button"
                                onClick={handleResend}
                                underline="none"
                                sx={{cursor: "pointer", fontWeight: "bold", color: "#0061E6"}}
                            >
                                Resend Code
                            </Link>
                        </Typography>
                    )}
                </Box>
            </Container>
        </AuthScreen>
    );
};

export default ConfirmAccount;
