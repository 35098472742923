import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useAuth } from 'Auth/AuthProvider';
import { searchRegistrations, searchAirports, searchProfiles, searchTags } from 'Search/SearchApi';
import { Search } from '@mui/icons-material';
import AirportOption from 'Search/AirportOption';
import AircraftOption from 'Search/AircraftOption';
import ProfileOption from 'Search/ProfileOption';
import TagOption from 'Search/TagOption';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import './SearchAutocomplete.css';

const POSTS_AND_FLIGHTS = 'Posts and Flights';

const SearchAutocomplete = (props) => {
  // const { onClickResult } = props;

  const { authHeaders } = useAuth();

  const [open, setOpen] = React.useState(false);
  const [query, setQuery] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const onClickResult = () => {
    setOpen(false);
    setQuery("");
    if(props.onClickResult) {
      props.onClickResult();
    }
  }


  const timeoutRef = React.useRef();

  useEffect(() => {
    if (!query) {
      console.log("query is empty");
      setOptions([]);
      return;
    }

    setLoading(true);

    // Clear previous timeout
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }


    timeoutRef.current = setTimeout(async () => {

      const profiles = await searchProfiles(query, authHeaders);
      const registrations = await searchRegistrations(query, authHeaders);
      const airports = await searchAirports(query, authHeaders);
      const tags = await searchTags({ q: query, has_count: true }, authHeaders);


      const newOptions = [...profiles, ...registrations, ...airports, ...tags];

      newOptions.push({
        optionType: POSTS_AND_FLIGHTS,
        getOptionLabel: () => `Search for '${query}'`,
      })

      setOptions(newOptions);
      setLoading(false);
    }, 300);


    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [query, authHeaders])


  return (
    <Box
      style={{ ...(props.style || {}) }}
      className={props.isModal ? 'search-autocomplete-modal' : 'search-autocomplete-desktop'}>
      <Autocomplete
        freeSolo
        autoHighlight
        options={options}
        PaperComponent={props.isModal ? SearchPaperModal : Paper}
        PopperComponent={props.isModal ? SearchPopperModal : SearchPopperDesktop}

        value={query}
        onChange={(event, newValue) => {
          setQuery(newValue);
        }}

        filterOptions={(options, params) => {
          if (loading) {
            return options;
          }

          const filtered = [...options];

          if (params.inputValue !== '' && options.length === 0) {
            filtered.push({
              optionType: null,
              getOptionLabel: () => 'No results found',
            });
          }
          return filtered;
        }}

        selectOnFocus
        handleHomeEndKeys

        open={props.stayOpen || open}

        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        loading={loading}

        onInputChange={(event, value) => {
          setQuery(value.trim());
        }}

        isOptionEqualToValue={(option, value) => {
          // console.log("isOptionEqualToValue", option, value);
          return (option.id === value.id);
        }}

        style={{ display: 'inline-block' }}
        size="small"
        sx={{ mr: 0.5, minWidth: 175, width: '100%' }}

        groupBy={option => option.optionType}

        getOptionLabel={option => {
          return typeof option === 'string' ? option : option.getOptionLabel();
        }}

        renderOption={(props, option) => {
          const { key, ...optionProps } = props;
          if (option.optionType === 'Airports') {
            return <AirportOption
              airport={option}
              key={option.id}
              onClick={onClickResult}
            />;
          }

          else if (option.optionType === 'Aircraft') {
            return <AircraftOption
              aircraft={option}
              key={option.id}
              onClick={onClickResult}
            />;
          }

          else if (option.optionType === 'Pilots') {
            return <ProfileOption
              profile={option}
              key={option.id}
              onClick={onClickResult}
            />;
          }
          else if (option.optionType === 'Tags') {
            return <TagOption
              tag={option}
              key={option.id}
              onClick={onClickResult}
            />;
          }
          else if ( option.optionType === POSTS_AND_FLIGHTS ) {
            return (
              <ListItemButton component={Link} to={`/search?q=${query}`} onClick={onClickResult}>
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: 'rgba(0, 0, 0, 0.08)' }}>
                    <Search color="secondary" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={query}
                  secondary={`Search  Posts and Flights`}
                />
              </ListItemButton>
            );
          }
          else if (option.optionType === null) {
            return (
              <ListItem sx={{ bgcolor: '#F9F8F5' }}>
                <Typography variant="caption" color="text.secondary">
                  No results found
                </Typography>
              </ListItem>
            );
          }
          return null;
        }}

        renderInput={(params) => (
          <TextField
            autoFocus={props.autoFocus}
            placeholder="Search"
            size="small"
            sx={{ p: 1 }}
            {...params}
            InputProps={{
              ...params.InputProps,
              sx: {
                bgcolor: 'white',
              },
              startAdornment: <Search color="secondary" />
            }}
          />
        )}
      />
    </Box>
  );
};


const SearchPopperDesktop = (props) => {
  return <Popper {...props} style={{ ...(props.style || {}), width: '275px' }} />;
}

const SearchPaperModal = (props) => {
  return <Box {...props} style={{ ...(props.style || {}), height: '100vh' }} />;
}

const SearchPopperModal = (props) => {
  return <Box {...props} style={{ ...(props.style || {}), height: '100vh', width: '100%', marginRight: 0 }} />;
}


export default SearchAutocomplete;