import { Send, ConnectWithoutContact, TrackChanges } from "@mui/icons-material";
import { Box, Avatar, CardContent, Grid, Typography, Container, Button, Chip } from "@mui/material";
import { Link } from "react-router-dom";

export default function ProfileBanner({ currentUserId, profile, flightStats, isCurrentUser, userPair, onCreateUserPair }) {


  return (
    <Box sx={{ position: 'relative', bgcolor: 'white' }}>
      <div style={{
        position: 'absolute',
        width: '100%',
        height: 200,
        backgroundColor: 'whitesmoke',
        backgroundImage: `radial-gradient(transparent, black), url(${profile.profileBannerUrl || '/sky.jpg'})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }}>
        <Container maxWidth="md" sx={{
            display: { xs: 'none', sm: 'block' }, }}>
              <Box sx={{
                position: 'absolute',
                top: 12,
                right: 12,
              }}>

          {

            isCurrentUser ? (
              <EditButton
                sx={{
                  color: 'white',
                  borderColor: 'white'
                }}
              />
            ) : (
              <FlyWithButton
                currentUserId={currentUserId}
                userPair={userPair}
                onCreateUserPair={onCreateUserPair}
                profile={profile}
                sx={{
                  color: 'white',
                  borderColor: 'white',
                  textTransform: 'none'
                }}
                endIcon={<Send />}
                size="small"
                variant="contained"
                color="primary"
              />

            )
            }
          </Box>

        </Container>


      </div>

      <Container maxWidth="md">
        <Box sx={{ display: 'flex', pl: 3, pr: 3, mt: 15, mb: 0, justifyContent: { xs: 'center', sm: 'flex-start' } }}>
          {
            profile.profilePhotoUrl ?
              <Avatar
                src={profile.profilePhotoUrl}
                sx={{ width: 128, height: 128, border: '3px solid white', }} />
              :
              <Avatar sx={{ width: 128, height: 128, fontSize: 48 }}>
                {profile.getInitials()}
              </Avatar>
          }
        </Box>

        <CardContent>

          <Grid container>

            <Grid item xs={12} sm={6} sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
              <Typography variant="h4" sx={{ mt: 0 }}>
                {profile.getFullName()}
              </Typography>
              <Typography variant="body2">
                {profile.pilotType} / {profile.homeAirportId}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box sx={{ display: 'flex', textAlign: 'center', mt: 1 }}>

                {
                  flightStats && (
                    <>
                      <div style={{ flex: 1 }}>
                        <Typography variant="body1" sx={{ fontSize: '1.25rem', fontWeight: 'bold' }}>{flightStats.flightCount}</Typography>
                        <Typography variant="caption" sx={{ fontSize: '0.70rem' }}>Logged {flightStats.flightCount === 1 ? 'Flight' : 'Flights'}</Typography>
                      </div>

                      <div style={{ flex: 1 }}>
                        <Typography variant="body1" sx={{ fontSize: '1.25rem', fontWeight: 'bold' }}>{flightStats.totals.totalHours}</Typography>

                        <Typography variant="caption" sx={{ fontSize: '0.70rem' }}>Total Hours</Typography>
                      </div>

                    </>
                  )
                }

                {/* <div style={{ flex: 1 }}>
                  <Typography variant="body1" sx={{ fontSize: '1.25rem', fontWeight: 'bold' }}>{profile.followerCount || 0}</Typography>
                  <Typography variant="caption" sx={{ fontSize: '0.70rem' }}>{profile.followerCount === 1 ? 'Follower' : 'Followers'}</Typography>
                </div> */}

                {/* <div style={{ flex: 1 }}>
                  <Typography variant="body1" sx={{ fontSize: '1.25rem', fontWeight: 'bold' }}>{profile.followingCount || 0}</Typography>
                  <Typography variant="caption" sx={{ fontSize: '0.70rem' }}>Following</Typography>
                </div> */}

              </Box>
            </Grid>


            <Box sx={{ display: { sm: 'none' }, textAlign: 'center', mt: 3, flex: 1 }}>
              {
                isCurrentUser ? (
                  <Grid item xs={12} >
                    <EditButton color="primary" fullWidth />
                  </Grid>
                )
                  : (
                    <FlyWithButton
                      currentUserId={currentUserId}
                      userPair={userPair}
                      onCreateUserPair={onCreateUserPair}
                      profile={profile}
                      endIcon={<Send />}
                      size="small"
                    />

                  )}
            </Box>


          </Grid>

        </CardContent>
      </Container>
    </Box>
  )
}


function EditButton(props) {
  return (
    <Button component={Link} to="/profile/edit" variant="outlined" {...props}>
      Edit Profile
    </Button>
  );
}






function FlyWithButton({ currentUserId, profile, userPair, onCreateUserPair, ...props }) {
  console.log("FlyWithButton", userPair);

  if (!userPair) {
    return (
      <Button variant="contained" sx={{ boxShadow: 0 }} onClick={onCreateUserPair} {...props} >
        Fly with {profile?.getFullName()}
      </Button>
    );
  }
  const isAcceptor = userPair.acceptor.userId === currentUserId;

  if (userPair.isMuted() && isAcceptor) {
    return (
      <Chip label="Muted" {...props} icon={<ConnectWithoutContact />} />
    );
  }

  if (userPair.isPaired()) {
    return (
      <Chip label={
        <>You fly with <strong>{profile?.firstName}</strong></>
      } color="primary" />
    );
  }

  return (
    <Chip label="Pending" {...props} icon={<TrackChanges />} />
  );
}
