import { useState, useEffect } from "react";
import { Box, LinearProgress, Typography } from "@mui/material";
import { getUserFlights } from "Flights/FlightsApi";
import { useAuth } from "Auth/AuthProvider";


function ProfileFlights({ profile }) {
  const { user, authHeaders } = useAuth();


  const perPage = 5;
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [flights, setFlights] = useState([]);
  const [count, setCount] = useState(0);
  const isCurrentUser = profile.userId === user?.id;


  useEffect(() => {
    const params = {
      userId: isCurrentUser ? null : profile.userId,
      limit: perPage,
      offset: page * perPage
    };

    getUserFlights(params, authHeaders)
        .then(({ flights, count }) => {
          setFlights(flights);
          setCount(count);
        })
        .finally(() => setIsLoading(false));

  }, [page]);

  if (isLoading) {
    return <LinearProgress />
  }

  return (<>


    <Box>
      {
        flights.map(flight => (
          flight.id
          // <FlightCard key={flight.id} flight={flight} />
        ))
      }
    </Box>

    {/* <LikeProvider>
      <CommentProvider>
        {
          flights.map(post => (
            <Post key={post.id} post={post} currentUserId={user.id} />
          ))
        }
      </CommentProvider>
    </LikeProvider> */}



    {
      flights.length === 0 && (
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <Typography variant="caption">No flights yet.</Typography>
        </Box>
      )
    }

  </>
  );
}

export default ProfileFlights;


