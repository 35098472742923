import BaseModel from "Lib/BaseModel";

export default class TraceModel extends BaseModel {
  constructor(data) {
    super(data || {});

    this.takeoffT = new Date(this.takeoffT);
    this.landingT = new Date(this.landingT);
  }

  static color = '#4796F6';

  getUrl(){
    const date = this.date.split('T')[0];
    return `https://storage.googleapis.com/deadstick-public/trace_paths/${date}/${this.traceUid}.json`;
  }

  getDurationHours(){
    const durationSeconds = (this.landingT - this.takeoffT) / 1000;

    const durationHours = durationSeconds / 3600;
    return durationHours;
  }

  getNauticalMiles(){
    return this.totalDistanceM / 1852;
  }

  getAirport(depatureOrDestination){
    const baseKey = depatureOrDestination;
    const assignedAirport = this[baseKey + 'ArptId'];

    if (assignedAirport) {
      return assignedAirport;
    }

    const candidates = this[baseKey + 'Details'][baseKey + '_candidates'];
    if (candidates.length && candidates[0].arpt_id) {
      return 'Near ' + candidates[0].arpt_id;
    }

    return 'Unknown';

  }

  getTakeoffSeconds(){
    return this.takeoffT.valueOf() / 1000;
  }

}
